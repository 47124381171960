<template>
  <div
    v-if="showBottomBanner"
    class="bottom-banner"
  >
    <div class="bottom-banner__inner">
      <div class="bottom-banner__inner-container">
        <div class="bottom-banner__title">
          {{ model.title }}
        </div>
        <div class="bottom-banner__content">
          {{ model.text }}
        </div>
        <a
          v-if="model.linkUrl && model.linkText"
          :href="model.linkUrl"
          :target="model.linkOpenInNewWindow ? '_blank' : '_self'"
          class="bottom-banner__cta"
          @click="onButtonClick"
          v-text="model.linkText"
        />
      </div>
      <a
        class="bottom-banner__close-btn"
        aria-label="Close bottom-banner"
        role="button"
        tabindex="0"
        @click="onCloseClick"
      />
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import { computed, ref } from 'vue'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup({ model }) {
    const isVisible = ref(!Cookie.get('bottomBanner'))

    const interactWithBottomBanner = () => {
      Cookie.set('bottomBanner', 'interacted', { expires: 365 })
      isVisible.value = false
    }

    const onCloseClick = () => {
      interactWithBottomBanner()
      GtmHelper.pushDataLayer(model.trackingData.closeButton)
    }

    const onButtonClick = () => {
      interactWithBottomBanner()
      GtmHelper.pushDataLayer(model.trackingData.cta)
    }

    return {
      showBottomBanner: computed(() => isVisible.value && model.initialized),

      onCloseClick,
      onButtonClick
    }
  }
}
</script>
