<template>
  <div class="mini-cart">
    <strong
      class="mini-cart__title u-flex u-flex-justify-center"
      v-text="state.translations.title"
    />

    <ul
      v-if="state.products.length"
      class="mini-cart__products u-bare-list"
    >
      <li
        v-for="product in state.products"
        :key="product.code"
        class="mini-cart__products-item"
      >
        <span class="product-image no-effect">
          <img
            ref="img"
            :src="product.image"
            :alt="product.name"
          >
        </span>
        <div class="product-info">
          <span class="tag-font">{{ product.brand }}</span>
          <span class="mini-cart__product-title">{{ product.name }}</span>
          <span class="mini-cart__product-subtitle">
            {{ product.amount }} {{ product.priceUnit }}
          </span>
        </div>
      </li>
    </ul>

    <div class="mini-cart__controls u-flex u-flex-direction-column u-flex-align-center">
      <a
        :href="state.checkoutUrl"
        class="cta cta--average"
        v-text="state.translations.checkoutButtonText"
      />
      <a
        :href="state.allOrdersUrl"
        class="u-link--underline"
        v-text="state.translations.allOrdersLinkText"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import useMiniCart from '@/compositions/useMiniCart'

export default {
  setup() {
    const {
      state
    } = useMiniCart()

    const img = ref([])

    return {
      img,
      state
    }
  }
}
</script>
