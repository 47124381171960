<template>
  <div
    ref="sustainableRef"
    class="hide--print"
  >
    <span
      role="button"
      class="u-flex u-flex-align-center u-gap-xxs u-link--underline"
      @click="onOpenModalClick"
    >
      <span>{{ model.openModalText }}</span>
      <IconArrow
        class="icon"
        width="10"
        height="8"
      />
    </span>
    <sustainable-modal
      :modal-name="modalName"
      :model="model"
      :recipe-name="recipeName"
    />
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import 'swiper/swiper.css'
import useModal from '@/compositions/useModal'
import IconArrow from '@/assets/icons/arrow-right.svg'
import SustainableModal from '@/components/sustainable-modal.vue'
import GtmHelper from '@/helpers/GtmHelper'
import impressionTracking from '@/helpers/ImpressionTracking'

export default {
  components: {
    SustainableModal,
    IconArrow
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    recipeName: {
      type: String,
      required: true
    }
  },
  setup({ recipeName }) {
    const sustainableRef = ref()

    const initImpressionTracking = () => {
      impressionTracking.trackProductImpressions({
        el: sustainableRef.value,
        model: recipeName,
        functionName: 'sustainableRecipeSectionImpression'
      })
    }

    // This method is here because for some reason it doesn't work
    // if you put it under '$modal = proxy' line
    onMounted(() => {
      document.body.addEventListener('OneTrustBannerInteractionState', () => {
        setTimeout(initImpressionTracking, 300) // Add delay to make sure gtm container is loaded
      })
    })

    const modalName = 'sustainable-modal'
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      showPopup
    } = useModal($modal)

    const onOpenModalClick = () => {
      GtmHelper.sustainableOpenModalClick(recipeName)
      showPopup(modalName)
    }

    return {
      sustainableRef,
      modalName,

      onOpenModalClick
    }
  }
}
</script>
