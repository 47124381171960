import { Navigation, Pagination } from 'swiper/modules'

export default () => {
  const leadingZeroFilter = (value) => ((value && value.toString().length === 1) ? `0${value}` : value)
  const swiperPagination = (swiper, current, total) => `
      <div class="current">${leadingZeroFilter(current)}</div>
      <div class="last">${leadingZeroFilter(total)}</div>
    `
  const swiperOptions = {
    modules: [Navigation, Pagination],
    pagination: {
      el: '.swiper-pagination',
      type: 'custom',
      renderCustom: swiperPagination
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerView: 1.2,
    spaceBetween: 20,
    breakpoints: null
  }

  const setOptions = (options) => ({
    ...swiperOptions,
    ...options
  })

  return {
    setOptions,
    swiperPagination,
    leadingZeroFilter
  }
}
