import scrollToSection from '@/compositions/scrollToSection'
import GtmHelper from '@/helpers/GtmHelper'

export default () => {
  const threshold = 90
  let menuHeight = null
  let links = null
  const sections = []
  const stickyMenu = document.getElementById('sticky-menu')
  if (stickyMenu !== null) {
    menuHeight = document.getElementById('sticky-menu').offsetHeight
    links = document.querySelectorAll('.c-sticky-menu__link')
    links.forEach((link) => {
      const section = document.getElementById(link.hash.substring(1))
      if (section) {
        sections.push(section)
      }
    })
  } else {
    return
  }

  if (!sections.length) {
    return
  }

  const onLinkClick = (e) => {
    e.preventDefault()
    scrollToSection(e.target, menuHeight)
    GtmHelper.pushDataLayer({
      event: 'anchorMenuLink'
    })
    window.history.pushState(null, null, e.target.hash)
  }

  const highlightMenuLink = (target) => {
    links.forEach((link) => {
      link.classList.remove('selected')
    })
    target.classList.add('selected')
  }

  const handleScroll = () => {
    sections.forEach((section) => {
      const sectionTopPosition = section.offsetTop
      const scrollPosition = Math.ceil(window.scrollY + menuHeight - threshold)
      if (scrollPosition >= sectionTopPosition) {
        links.forEach((link) => {
          if (link.hash === `#${section.attributes.id.value}`) {
            highlightMenuLink(link)
          }
        })
      } else if (scrollPosition < sections[0].offsetTop) {
        links.forEach((link) => {
          link.classList.remove('selected')
        })
      }
    })
  }

  links.forEach((link) => {
    link.addEventListener('click', onLinkClick)
  })
  handleScroll()
  window.addEventListener('scroll', handleScroll)
}
