<template>
  <div
    class="c-theme-picker"
    @click.stop
  >
    <label
      v-for="theme in themes"
      :key="theme"
      class="c-theme-picker__item"
      :class="`u-theme--${themePrefix}${theme}`"
    >
      <input
        v-model="model"
        type="radio"
        name="theme"
        :value="theme"
        :required="required"
        @change="onChange($event.target.value)"
      >
      <span class="visuallyhidden">
        {{ theme }}
      </span>
      <span class="visual-indicator" />
    </label>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    themes: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    themePrefix: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  setup({ value }, context) {
    const model = ref(value)

    const onChange = (newVal) => {
      context.emit('input', newVal)
      context.emit('change', 'theme', newVal)
    }

    return {
      model,

      onChange
    }
  }
}
</script>
