import useHeaderControls from '@/compositions/useHeaderControls'

export default () => {
  const {
    toggleHeaderSidebar
  } = useHeaderControls()

  const loginButton = document.querySelector('.js-login-button')
  if (loginButton) {
    loginButton.addEventListener('click', (e) => {
      e.preventDefault()
      toggleHeaderSidebar()
    }, false)
  }
}
