<template>
  <affix
    relative-element-selector="#affix-parent"
    mobile-break-point="901"
  >
    <card-slider
      v-if="items.length"
      class="recipe-slider"
      card-type="recipeBusinessCard"
      list-name="Business Case Sidebar"
      :heading="heading"
      :items="items"
      :is-narrow="true"
    />
    <template v-else-if="youtubeVideoId">
      <generic-video
        :video-id="youtubeVideoId"
        :ambient="false"
        :thumbnail="imageUrl"
        class="item-video hide--print"
      />
      <generic-image
        :large-image-url="imageUrl"
        class="hide--screen"
      />
    </template>
    <generic-image
      v-else
      :large-image-url="imageUrl"
    />
  </affix>
</template>

<script>
import Affix from '@/components/affix.vue'
import CardSlider from '../card-slider.vue'

export default {
  components: {
    Affix,
    CardSlider
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    youtubeVideoId: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    }
  }
}
</script>
