<template>
  <div
    v-if="filters.length"
    :class="cssClass"
    class="filter-dropdown"
  >
    <span
      class="filter-dropdown__opener"
      @click="toggleDropdown"
    >
      {{ title }}
      <ArrowIcon class="icon" />
    </span>

    <select
      v-if="isMobile"
      :value="selected"
      name="sorting"
      class="filter-dropdown__select"
      @change="chooseFilter($event.target.value)"
    >
      <option
        v-for="filter in filters"
        :key="filter.value"
        :value="filter.value"
        v-text="filter.label"
      />
    </select>

    <template v-else>
      <transition name="fade">
        <div
          v-if="isVisible"
          v-on-clickaway="clickAway"
          class="newdropdown"
        >
          <div class="newdropdown-wrap">
            <ul class="newdropdown__list">
              <li
                v-for="filter in filters"
                :key="filter.value"
                class="newdropdown__list-item"
              >
                <span
                  :class="{ active: filter.value === selected }"
                  class="newdropdown__list-link"
                  @click="chooseFilter(filter.value)"
                >
                  {{ filter.label }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import ArrowIcon from '@/assets/icons/arrow_down--no-color.svg'

export default {
  components: {
    ArrowIcon
  },
  mixins: [
    clickaway
  ],
  props: {
    title: {
      type: String,
      default: 'Sortera'
    },
    filters: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isVisible: false,
      dropdownPosition: 'center'
    }
  },
  computed: {
    cssClass() {
      return [
        { 'show-dropdown': this.isVisible },
        `dropdown-${this.dropdownPosition}`
      ]
    },
    isMobile() {
      return this.$mq !== 'desktop'
    }
  },
  mounted() {
    this.checkDropdownPosition()
    window.addEventListener('resize', this.checkDropdownPosition.bind(this))
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDropdownPosition.bind(this))
  },
  methods: {
    checkDropdownPosition() {
      if (this.isMobile) {
        return
      }

      const boundings = this.$el.getBoundingClientRect()
      const thresholdWidth = 100

      if (boundings.left < boundings.width + thresholdWidth) {
        this.dropdownPosition = 'right'
      } else if (boundings.right + thresholdWidth > window.innerWidth) {
        this.dropdownPosition = 'left'
      } else {
        this.dropdownPosition = 'center'
      }
    },
    showDropdown() {
      this.isVisible = true
    },
    hideDropdown() {
      this.isVisible = false
    },
    toggleDropdown() {
      if (this.isVisible) {
        this.hideDropdown()
      } else {
        this.showDropdown()
      }
    },
    chooseFilter(filterValue) {
      this.hideDropdown()
      this.$emit('chooseFilter', filterValue)
    },
    clickAway() {
      if (this.isVisible) {
        this.hideDropdown()
      }
    }
  }
}
</script>
