import api from '@/lib/api'

export default {
  trackProductClick({
    queryTrackId,
    hitId,
    searchTerms,
    position
  }) {
    api.trackCardItemClick(queryTrackId, hitId, searchTerms, position, 'product')
  },
  trackAutoSuggestionClick(data) {
    api.trackAutoSuggestionClick(data)
  },
  trackCardItemClick({
    queryTrackType,
    queryTrackId,
    hitId,
    searchTerms,
    position
  }) {
    api.trackCardItemClick(queryTrackId, hitId, searchTerms, position, queryTrackType)
  }
}
