<template>
  <div class="active-filters">
    <transition-group
      name="reveal-list"
      tag="ul"
      class="tags-list tags-list--dark"
    >
      <li
        v-for="filter in filters"
        :key="filter.name || filter"
        class="tags-list__item"
      >
        <a
          class="tags-list__link"
          role="button"
          tabindex="0"
          @click="removeFilter(filter)"
          @keyup.enter="removeFilter(filter)"
        >
          {{ getDisplayName(filter.name || filter) }}
          <span class="icon-close" />
        </a>
      </li>
    </transition-group>
    <a
      v-if="filters.length > 1"
      class="active-filters__remove-link"
      role="button"
      tabindex="0"
      @click="clearFilters"
      @keyup.enter="clearFilters"
    >
      {{ clearFiltersText }}
    </a>
  </div>
</template>

<script>
const { clearFilters = '' } = window.arla.cms.resources.search

export default {
  props: {
    filters: {
      type: Array,
      required: true
    },
    clearFiltersText: {
      type: String,
      default: clearFilters
    }
  },
  methods: {
    removeFilter(filter) {
      this.$emit('removeFilter', filter)
    },
    clearFilters() {
      this.$emit('clearFilters')
    },
    getDisplayName(name) {
      const pos = name.lastIndexOf('__')
      if (pos > 0) {
        return name.substring(0, pos)
      }

      return name
    }
  }
}
</script>
