<template>
  <div
    :class="{ 'is-loading': loading }"
    class="search-overview"
  >
    <h1 class="search-overview__title">
      {{ pageTitle }}
    </h1>

    <div class="search-overview__content">
      <search-tabs />
      <items-overview-controls />
      <search-filters />
      <search-items :has-multiple-search="false" />
    </div>

    <bottom-banner
      v-if="searchType === 'product'"
      :model="bannerModel"
    />
    <cart-summary v-if="searchType === 'ordermaterial'" />
  </div>
</template>

<script>
import { computed, watch } from 'vue'

import SearchFilters from '@/components/search/search-filters.vue'
import SearchItems from '@/components/search/search-items.vue'
import SearchTabs from '@/components/search/search-tabs.vue'
import BottomBanner from '@/components/bottom-banner.vue'
import CartSummary from '@/components/orderMaterials/order-material-cart-summary.vue'
import ItemsOverviewControls from '@/components/itemsOverview/items-overview-controls.vue'

import router from '@/compositions/search/search-router'
import searchQueryState from '@/compositions/search/search-query'
import searchTabsConfig from '@/compositions/search/search-tabs-configuration'
import routerHelper from '@/compositions/search/search-router-helper'
import searchRepository from '@/compositions/search/search-repository'
import productsBottomBanner from '@/compositions/products-bottom-banner'

export default {
  router,
  components: {
    ItemsOverviewControls,
    SearchFilters,
    SearchItems,
    SearchTabs,
    BottomBanner,
    CartSummary
  },
  props: {
    tabsConfiguration: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    searchResultText: {
      type: String,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    searchTabsConfig.tabsConfigurationUse(props.tabsConfiguration, true)

    let initialized = false
    const { getRouteData, updateRoute } = routerHelper.useRouterHelper(router, props.baseUrl)
    const routeData = getRouteData()
    const { searchQuery, setSearchType } = searchQueryState.searchQueryUse(routeData)
    const {
      loading,
      searchResults,
      totalCount,
      hasResults
    } = searchRepository.useSearchResult()
    const { bannerModel } = productsBottomBanner.productsBottomBannerUse()

    const activateTabWithResults = () => {
      if (totalCount.value === 0) {
        const tabWithResults = props.tabsConfiguration.slice(1).find(({ type }) => {
          const typeResults = searchResults.value.find((results) => type === results.type)
          return typeResults.totalCount > 0
        })

        setTimeout(() => {
          setSearchType(tabWithResults.type) // Timeout fixes the issue in Edge
        }, 10)
      }

      initialized = true
    }

    watch(
      () => searchQuery.terms,
      (prevVal, newVal) => {
        if (prevVal !== newVal) {
          initialized = false
        }
      }
    )

    watch(
      () => searchResults.value,
      () => {
        if (!initialized && hasResults.value) {
          activateTabWithResults()
        }
      }
    )

    watch(
      () => [loading.value],
      () => {
        if (!loading.value) {
          updateRoute()
        }
      }
    )

    const pageTitle = computed(() => (
      searchQuery.terms.length
        ? `${props.searchResultText} “${searchQuery.terms[0]}”`
        : props.title
    ))

    return {
      pageTitle,
      loading,
      searchType: computed(() => searchQuery.searchType),
      bannerModel
    }
  }
}
</script>
