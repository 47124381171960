(() => {
  const html = document.getElementsByTagName('html')[0]

  if (('ontouchstart' in window)
    || (window.DocumentTouch && document instanceof window.DocumentTouch)
  ) {
    html.classList.add('touch')
  } else {
    html.classList.add('no-touch')
  }
})()
