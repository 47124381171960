<template>
  <div class="card-list">
    <search-items-header />

    <mixed-active-filters v-if="hasMultipleSearch" />
    <search-active-filters v-else />

    <div
      v-show="!noResult"
      v-infinite-scroll="searchMore"
      infinite-scroll-disabled="isInfiniteScrollDisabled"
      infinite-scroll-distance="300"
    >
      <ul
        v-if="view === 'grid'"
        class="card-list__items"
      >
        <li
          v-for="(item, index) in items"
          :key="item.id"
          :class="item.isFeatured ? 'card-list__item--wide' : ''"
          class="card-list__item"
        >
          <order-material-card
            v-if="searchType === 'ordermaterial'"
            :model="item"
            :position="index + 1"
            :query-track-id="trackId"
            :query-track-type="searchType"
            list-name="Order Material Overview Grid"
          />
          <product-card
            v-else-if="searchType === 'product'"
            :model="item"
            :position="index + 1"
            :query-track-id="trackId"
            list-name="Product Overview Grid"
          />
          <item-card
            v-else-if="searchType === 'recipe'"
            :model="item"
            :is-wide="item.isFeatured"
            :query-track-id="trackId"
            :query-track-type="searchType"
            :type="searchType"
            :link-text="item.isFeatured ? 'Läs mer': ''"
            :cta-type="item.isFeatured ? 'button' : ''"
            list-name="Recipe Overview"
            :class="item.featuredTheme ? 'u-bg-color--' + item.featuredTheme : ''"
          />
          <item-card
            v-else-if="searchType === 'businesscase'"
            :model="item"
            :is-wide="item.isFeatured"
            :query-track-id="trackId"
            :query-track-type="searchType"
            :type="searchType"
            :link-text="item.isFeatured ? 'Läs mer': ''"
            :cta-type="item.isFeatured ? 'button' : ''"
            list-name="Business Case Overview"
            :class="item.featuredTheme ? 'u-bg-color--' + item.featuredTheme : ''"
          />
          <item-card
            v-else
            :model="item"
            :query-track-id="trackId"
            :query-track-type="searchType"
            :type="searchType"
            list-name="Article Overview"
          />
        </li>
      </ul>

      <table
        v-if="view === 'list'"
        class="product-table table"
      >
        <template v-if="searchType === 'product'">
          <thead>
            <tr>
              <th>Benämning</th>
              <th>Vikt/volym</th>
            </tr>
          </thead>
          <tbody>
            <!-- TODO: Add proper category -->
            <product-table-item
              v-for="(item, index) in items"
              :key="item.id"
              :model="item"
              :position="index + 1"
              :query-track-id="trackId"
              list-name="Product Overview List"
            />
          </tbody>
        </template>

        <template v-if="searchType === 'ordermaterial'">
          <thead>
            <tr>
              <th>Benämning</th>
              <th>Pris/St</th>
              <th>Antal</th>
            </tr>
          </thead>
          <tbody>
            <order-material-table-item
              v-for="(item, index) in items"
              :key="item.id"
              :model="item"
              :position="index + 1"
              :query-track-id="trackId"
              :query-track-type="searchType"
            />
          </tbody>
        </template>
      </table>
    </div>

    <template v-if="noResult">
      <div class="no-results-container">
        <icon-no-results class="icon" />
        <div v-html="noResultHtml" />
      </div>
    </template>

    <loader :loading="loading" />
  </div>
</template>
<script>
import { computed } from 'vue'

import searchRepository from '@/compositions/search/search-repository'
import searchQueryState from '@/compositions/search/search-query'
import searchTabsConfig from '@/compositions/search/search-tabs-configuration'
import useFilters from '@/compositions/useFilters'

import IconNoResults from '@/assets/icons/no_results.svg'
import ItemCard from '@/components/itemsOverview/item-card.vue'
import Loader from '@/components/loader.vue'
import OrderMaterialCard from '@/components/orderMaterials/order-material-card.vue'
import OrderMaterialTableItem from '@/components/orderMaterials/order-material-table-item.vue'
import ProductCard from '@/components/products/product-card.vue'
import ProductTableItem from '@/components/products/product-table-item.vue'
import SearchActiveFilters from '@/components/search/search-active-filters.vue'
import MixedActiveFilters from '@/components/mixed-active-filters.vue'
import SearchItemsHeader from './search-items-header.vue'

export default {
  components: {
    IconNoResults,
    ItemCard,
    Loader,
    ProductCard,
    OrderMaterialCard,
    OrderMaterialTableItem,
    ProductTableItem,
    SearchItemsHeader,
    SearchActiveFilters,
    MixedActiveFilters
  },
  props: {
    hasMultipleSearch: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const { clearFilters } = window.arla.cms.resources.search

    const {
      searchQuery,
      removeAllCategories,
      activeCategories
    } = searchQueryState.searchQueryUse()
    const {
      items,
      loading,
      allItemsLoaded,
      searchMore,
      trackId
    } = searchRepository.useSearchResult()
    const { getActiveConfig, hasSearchTabs } = searchTabsConfig.tabsConfigurationUse()
    const { openFilterMenu, closeFilterMenu } = useFilters()

    const view = computed(() => {
      const activeConfig = getActiveConfig()
      if (searchQuery.view) {
        return searchQuery.view
      }

      return activeConfig.viewOptions.find((x) => x.default).value
    })

    const isInfiniteScrollDisabled = computed(() => loading.value || allItemsLoaded.value)

    return {
      trackId,
      items,
      loading,
      hasSearchTabs,
      searchType: computed(() => searchQuery.searchType),
      noResult: computed(() => !loading.value && items.value.length === 0),
      isInfiniteScrollDisabled,
      clearFilters,

      searchMore,

      view,
      noResultHtml: computed(() => getActiveConfig().noResultHtml),
      openFilterMenu,
      closeFilterMenu,

      filtersExist: computed(() => activeCategories.value.length > 0),
      removeAllCategories
    }
  }
}
</script>
