import { ref } from 'vue'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'

const headerSidebarIsVisible = ref(false)
const miniCartIsVisible = ref(true)
const redirectToEcom = ref(true)

export default () => {
  const showHeaderSidebar = (redirectToEcomOnLogin = true) => {
    redirectToEcom.value = redirectToEcomOnLogin
    if (headerSidebarIsVisible.value) {
      return
    }
    headerSidebarIsVisible.value = true
  }
  const hideHeaderSidebar = () => {
    headerSidebarIsVisible.value = false
    clearAllBodyScrollLocks()
  }
  const headerClickAway = (e) => {
    if (e.target.dataset.login !== undefined) {
      return
    }
    if (headerSidebarIsVisible.value) {
      headerSidebarIsVisible.value = false
      clearAllBodyScrollLocks()
    }
  }
  const toggleHeaderSidebar = (redirectToEcomOnLogin) => {
    if (headerSidebarIsVisible.value) {
      hideHeaderSidebar()
    } else {
      showHeaderSidebar(redirectToEcomOnLogin)
    }
  }

  return {
    miniCartIsVisible,
    headerSidebarIsVisible,
    redirectToEcom,

    headerClickAway,
    toggleHeaderSidebar,
    showHeaderSidebar,
    hideHeaderSidebar
  }
}
