<template>
  <modal
    :name="modalName"
    :adaptive="true"
    :max-width="500"
    height="auto"
    width="90%"
    reset
    class="v--modal"
    @before-open="beforeOpenModal()"
    @before-close="beforeCloseModal()"
  >
    <div class="popup__inner u-text-align--center">
      <div class="popup-header">
        <strong class="popup-header__text h4 mx-auto">
          {{ title }}
        </strong>
      </div>
      <div
        class="popup-content"
        v-html="translations.deleteCatalogueModalText"
      />
      <span
        v-if="hasError"
        class="form-message--error"
        v-html="translations.deleteCatalogueModalErrorText"
      />
    </div>
    <div class="popup-panel">
      <button
        class="cta cta--link"
        @click="onCancel"
      >
        {{ translations.deleteCatalogueModalCancelButton }}
      </button>
      <button
        class="cta cta--dark"
        @click="onSubmit"
      >
        {{ translations.deleteCatalogueModalConfirmButton }}
      </button>
      <span
        role="button"
        :aria-label="closeLabel"
        class="popup-content__button--close"
        @click="onCancel"
      />
    </div>
  </modal>
</template>

<script>
import { ref, getCurrentInstance, computed } from 'vue'
import useModal from '@/compositions/useModal'
import useCatalogue from '@/compositions/useCatalogue'
import GtmHelper from '@/helpers/GtmHelper'
import favoritesQueryState from '@/compositions/favorites/favorites-query'

export default {
  setup() {
    const {
      catalogueToDelete,
      catalogueDeleteModalName: modalName,
      catalogueCardTranslations: translations,

      deleteCatalogue,
      setCatalogueToDelete
    } = useCatalogue()
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    } = useModal($modal)
    const { resetLastUpdated } = favoritesQueryState.favoritesQueryUse()
    const loading = ref(false)
    const hasError = ref(false)
    const { closeLabel } = window.arla.cms.resources.general

    const onSubmit = () => {
      loading.value = true
      hasError.value = false
      deleteCatalogue(catalogueToDelete.value.id)
        .then(() => {
          GtmHelper.removeCatalogue(catalogueToDelete.value.id)
          resetLastUpdated()
          hidePopup(modalName)
        })
        .catch(() => {
          hasError.value = true
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onCancel = () => {
      hasError.value = false
      setCatalogueToDelete(null)
      hidePopup(modalName)
    }

    const title = computed(() => translations.value.deleteCatalogueModalTitle?.replace('{name}', catalogueToDelete.value?.name) || '')

    return {
      modalName,
      translations,
      title,
      hasError,
      catalogueToDelete,
      closeLabel,

      beforeOpenModal,
      beforeCloseModal,
      onSubmit,
      onCancel
    }
  }
}
</script>
