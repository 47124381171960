<template>
  <div
    :class="{ 'expanded' : isExpanded || isAnimating }"
    class="show-more-block"
  >
    <div class="show-more-block__toggler">
      <span
        role="button"
        class="show-more-block__toggler-button"
        @click="toggle"
        v-text="buttonText"
      />
    </div>
    <div
      ref="content"
      :style="showStyles ? contentStyle : ''"
      class="show-more-block__content"
    >
      <div
        class="show-more-block__content-inner"
        v-html="slotContent"
      />
    </div>
  </div>
</template>

<script>
import gtmHelper from '@/helpers/GtmHelper'
import expandCollapse from '@/mixins/expand-collapse'

export default {
  mixins: [
    expandCollapse
  ],
  props: {
    textMore: {
      type: String,
      default: 'Show more'
    },
    textLess: {
      type: String,
      default: 'Show less'
    },
    slotContent: {
      type: String,
      default: ''
    },
    minHeight: {
      type: Number,
      default: 200
    },
    listName: {
      type: String,
      required: true
    },
    isExpandedByDefault: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonText() {
      return this.isExpanded ? this.textLess : this.textMore
    }
  },
  methods: {
    trackExpand() {
      gtmHelper.showMoreInfo(this.listName)
    },
    trackCollapse() {
      gtmHelper.showLessInfo(this.listName)
    }
  }
}
</script>
