function useFilters() {
  const openFilterMenu = () => {
    document.documentElement.classList.add('show-filters')
  }
  const closeFilterMenu = () => {
    document.documentElement.classList.remove('show-filters')
  }

  return {
    openFilterMenu,
    closeFilterMenu
  }
}

export default useFilters
