const renderCaptcha = (sitekey, callback) => {
  if (window.grecaptcha) {
    window.grecaptcha.render('recaptcha', {
      sitekey,
      callback
    })
  }
}

const verifyCaptcha = (callback) => {
  if (window.grecaptcha) {
    const response = window.grecaptcha.getResponse()
    if (response.length === 0) {
      window.grecaptcha.execute()
    } else {
      callback(response)
    }
  }
}

const resetCatcha = () => {
  if (window.grecaptcha) {
    window.grecaptcha.reset()
  }
}

export {
  renderCaptcha,
  verifyCaptcha,
  resetCatcha
}
