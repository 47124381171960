import {
  getAllItems
} from '@/compositions/favorites/favorites-ls-repository'
import useAuth from '@/compositions/useAuth'
import FavoritesMigrateModal from '@/components/favorites/favorites-migrate-modal.vue'
import vueUtil from '@/vue-util'

export default () => {
  const { isAuth } = useAuth()
  if (!isAuth.value) return

  const localFavorites = getAllItems()
  if (!Object.keys(localFavorites).length) return

  vueUtil.mountComponents({
    FavoritesMigrateModal
  })
}
