<template>
  <svg
    v-if="segments"
    :width="chartWidth"
    :height="chartHeight"
    :viewBox="viewBox"
    class="donut"
  >
    <circle
      v-if="!isPie"
      :cx="centerX"
      :cy="centerY"
      :r="radius"
      :fill="holeColor"
      class="donut-hole"
    />
    <circle
      :cx="centerX"
      :cy="centerY"
      :r="radius"
      :stroke-width="strokeWidth"
      :stroke="ringColor"
      class="donut-ring"
      fill="transparent"
    />
    <circle
      v-for="segment in calculatedSegments"
      :key="segment.name"
      :cx="centerX"
      :cy="centerY"
      :r="radius"
      :stroke-width="strokeWidth"
      :stroke="segment.color"
      :stroke-dasharray="segment.strokeLength"
      :stroke-dashoffset="segment.strokeOffset"
      class="donut-segment"
      fill="transparent"
    />
  </svg>
</template>

<script>
// Main idea is from this article https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72
export default {
  props: {
    segments: {
      type: Array,
      required: false,
      default: () => []
    },
    chartValue: {
      type: Number,
      default: 100
    },
    chartWidth: {
      type: String,
      default: '200px'
    },
    chartHeight: {
      type: String,
      default: '200px'
    },
    strokeWidth: {
      type: String,
      default: '3'
    },
    separatorWidth: {
      type: Number,
      default: 2
    },
    ringColor: {
      type: String,
      default: '#fff'
    },
    holeColor: {
      type: String,
      default: '#fff'
    },
    isPie: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      centerX: 21,
      centerY: 21,
      radius: 15.91549430918954
    }
  },
  computed: {
    viewBox() {
      return `0 0 ${this.centerX * 2} ${this.centerY * 2}`
    },
    calculatedSegments() {
      const self = this
      let offset = 0 // Center
      const array = []
      this.segments.forEach((segment, index) => {
        if (!segment.value) {
          return
        }
        const newSegment = {}
        newSegment.name = segment.name
        newSegment.value = Math
          .max((segment.value / self.chartValue) * 100, self.separatorWidth + 1)
        newSegment.color = segment.color
        newSegment.strokeLength = `${newSegment.value - self.separatorWidth} ${self.separatorWidth - newSegment.value + 100}`
        newSegment.strokeOffset = index === 0 ? 25 : 100 - +offset + 25
        offset += +newSegment.value
        array.push(newSegment)
      })

      return array
    }
  }
}
</script>
