import { ref } from 'vue'
import Cookies from 'js-cookie'
import GtmHelper from '@/helpers/GtmHelper'

const hem = ref()
const targetingCategory = 'C0004'

const init = () => {
  hem.value = Cookies.get('hem')
}

const removeHEMFromLocalStorage = () => {
  localStorage.removeItem('hem')
}

const removeHEMFromCookies = () => {
  Cookies.remove('hem')
}

const checkTargetingGround = () => (window.OptanonActiveGroups
  && window.OptanonActiveGroups.includes(targetingCategory))

const concentCallback = (func) => {
  if (window.Optanon?.IsAlertBoxClosed()) {
    if (checkTargetingGround()) {
      func()
    } else {
      removeHEMFromCookies()
    }
    removeHEMFromLocalStorage()
  } else {
    document.body.addEventListener('OneTrustBannerInteractionState', () => {
      if (checkTargetingGround()) {
        func()
      } else {
        removeHEMFromCookies()
      }
      removeHEMFromLocalStorage()
    })
  }
}

const moveHemToCookies = (newHem) => {
  concentCallback(() => {
    hem.value = newHem
    Cookies.set('hem', newHem, { expires: 365 })
  })
}

const oldHem = localStorage.getItem('hem')
if (oldHem) {
  moveHemToCookies(oldHem)
}
// This function will be used on Epiforms submit. That's why it should have global context.
const setHem = (newHem, placement = 'url') => {
  concentCallback(() => {
    hem.value = newHem
    Cookies.set('hem', newHem, { expires: 365 })
    GtmHelper.setHem(newHem, placement)
  })
}

const checkHemInUrl = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const urlHem = urlParams.get('hem')

  if (urlHem) {
    setHem(urlHem)
  }
}

window.setHem = setHem
concentCallback(init)

export {
  hem,

  setHem,
  checkHemInUrl,
  moveHemToCookies
}
