<template>
  <div
    :class="{ 'is-loading': loading }"
    class="catalogue-overview"
  >
    <div class="catalogue-overview__text">
      <h1 class="catalogue-overview__title">
        {{ title }}
      </h1>
      <div
        v-if="description"
        v-html="description"
      />
    </div>

    <div class="catalogue-overview-search">
      <taxonomy-search
        :search-text="searchText"
      />
    </div>

    <search-tabs />
    <catalogue-overview-controls />
    <search-filters />
    <search-items />

    <cart-summary />
    <catalogue-modal
      v-if="isSet"
    />
  </div>
</template>

<script>
import { computed, watch } from 'vue'

import SearchFilters from '@/components/search/search-filters.vue'
import SearchItems from '@/components/search/search-items.vue'
import SearchTabs from '@/components/search/search-tabs.vue'
import TaxonomySearch from '@/components/search/taxonomy-search-form.vue'
import CatalogueOverviewControls from '@/components/catalogue/catalogue-overview-controls.vue'
import CartSummary from '@/components/catalogue/catalogue-cart-summary.vue'
import CatalogueModal from '@/components/catalogue/catalogue-modal.vue'

import router from '@/compositions/search/search-router'
import searchQueryState from '@/compositions/search/search-query'
import searchTabsConfig from '@/compositions/search/search-tabs-configuration'
import routerHelper from '@/compositions/search/search-router-helper'
import searchRepository from '@/compositions/search/search-repository'
import useCatalogue from '@/compositions/useCatalogue'

export default {
  router,
  components: {
    TaxonomySearch,
    SearchFilters,
    SearchItems,
    SearchTabs,
    CatalogueOverviewControls,
    CartSummary,
    CatalogueModal
  },
  props: {
    tabsConfiguration: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    searchResultText: {
      type: String,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    trackingPrefix: {
      type: String,
      required: true
    },
    translations: {
      type: Object,
      required: true
    },
    recaptchaKey: {
      type: String,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    themes: {
      type: Array,
      required: true
    }
  },
  setup({
    tabsConfiguration,
    baseUrl,
    trackingPrefix,
    translations,
    recaptchaKey,
    colors,
    themes
  }) {
    const { searchText } = window.arla.cms.resources.orderMaterials
    searchTabsConfig.tabsConfigurationUse(tabsConfiguration, true)

    let initialized = false
    const { getRouteData, updateRoute } = routerHelper.useRouterHelper(router, baseUrl)
    const routeData = getRouteData()
    const {
      searchQuery,
      setSearchType,
      setTrackingPrefix
    } = searchQueryState.searchQueryUse(routeData)
    const {
      loading,
      searchResults,
      totalCount,
      hasResults
    } = searchRepository.useSearchResult()
    const {
      isSet,

      setCataloguePage
    } = useCatalogue()
    setCataloguePage({
      translations,
      recaptchaKey,
      colors,
      themes
    })

    const activateTabWithResults = () => {
      if (totalCount.value === 0) {
        const tabWithResults = tabsConfiguration.slice(1).find(({ type }) => {
          const typeResults = searchResults.value.find((results) => type === results.type)
          return typeResults.totalCount > 0
        })

        setTimeout(() => {
          setSearchType(tabWithResults.type) // Timeout fixes the issue in Edge
        }, 10)
      }

      initialized = true
    }

    setTrackingPrefix(trackingPrefix)

    watch(
      () => searchQuery.terms,
      (prevVal, newVal) => {
        if (prevVal !== newVal) {
          initialized = false
        }
      }
    )

    watch(
      () => searchResults.value,
      () => {
        if (!initialized && hasResults.value) {
          activateTabWithResults()
        }
      }
    )

    watch(
      () => [loading.value],
      () => {
        if (!loading.value) {
          updateRoute()
        }
      }
    )

    return {
      loading,
      searchType: computed(() => searchQuery.searchType),
      searchText,
      isSet
    }
  }
}
</script>
