import { reactive } from 'vue'
import gtmHelper from '@/helpers/GtmHelper'

const favoritesQueryState = reactive({
  favoritesType: null,
  favoritesTypes: [],
  sorting: null,

  updated: Date.now(),
  initialized: false
})

let trackingPrefix = 'favorites-'
const setTrackingPrefix = (newPrefix) => {
  trackingPrefix = newPrefix
}

export default {
  favoritesQueryUse(routeData) {
    const state = favoritesQueryState

    const resetLastUpdated = () => {
      state.updated = Date.now()
    }

    if (!state.initialized && routeData) {
      state.favoritesType = routeData.favoritesType
      state.sorting = routeData.sorting
      state.initialized = true
      resetLastUpdated()
    }

    const setAllFavoritesTypes = (favoritesTypes) => {
      state.favoritesTypes = favoritesTypes
    }

    const setFavoritesType = (favoritesType) => {
      if (state.favoritesType === favoritesType) {
        return
      }

      state.favoritesType = favoritesType
      state.sorting = null

      resetLastUpdated()
    }

    const setSorting = (sorting) => {
      if (state.sorting === sorting) {
        return
      }

      state.sorting = sorting
      gtmHelper.changeSorting(sorting, trackingPrefix)
    }

    return {
      favoritesQuery: state,
      setAllFavoritesTypes,
      setFavoritesType,
      setSorting,
      resetLastUpdated,

      setTrackingPrefix
    }
  }
}
