import { reactive } from 'vue'

const state = reactive({
  modalName: '',
  placement: '',
  productID: '',
  productName: '',
  categoryName: '',
  brandName: '',
  model: {}
})

export default () => {
  const setState = ({
    newName,
    newModel,
    placement,
    productID,
    productName,
    categoryName,
    brandName
  }) => {
    state.modalName = newName
    state.model = newModel
    state.placement = placement
    state.productID = productID
    state.productName = productName
    state.categoryName = categoryName
    state.brandName = brandName
  }

  return {
    state,

    setState
  }
}
