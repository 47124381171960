<template>
  <div
    ref="element"
    :class="cssClass"
  >
    <div
      v-on-clickaway="headerClickAway"
      class="site-header-wrap"
    >
      <div
        role="navigation"
        aria-label="Navigation Container"
        class="site-header__container"
      >
        <div class="site-logo-wrapper">
          <a
            v-if="showLogo"
            :href="cmsNavigation.logoInfo.url"
            class="site-logo"
          >
            <logo-icon class="icon" />
            <span v-text="cmsNavigation.logoInfo.text" />
          </a>
        </div>

        <search
          v-if="isMobile && cmsNavigation.search"
          :model="cmsNavigation.search"
        />

        <div class="nav-wrapper">
          <main-menu
            :favorites="favoritesModel"
          />
          <search
            v-if="!isMobile && cmsNavigation.search"
            :model="cmsNavigation.search"
          />
        </div>

        <a
          v-if="isConnectAuth && notifications"
          :href="notifications.url"
          class="c-service-menu__button"
          :title="notifications.text"
        >
          <span class="u-position-relative u-line-height-0">
            <NotificationIcon class="icon" />
            <span
              v-if="notifications.count > 0"
              class="splash-sign splash-sign--xxs splash-sign--on-icon splash-sign--green"
            >
              <span
                class="text-icon text-icon--plain"
                v-text="notifications.count"
              />
            </span>
          </span>
        </a>

        <a
          v-if="showFavorites"
          :href="cmsNavigation.favorites.url"
          :title="cmsNavigation.favorites.text"
          class="favorites-link c-service-menu__button"
          @click="onFavoritesClick"
        >
          <IconBookmark class="icon" />
        </a>

        <div
          v-if="isConnectAuth"
          class="mini-cart-block"
        >
          <a
            :href="miniCart.checkoutUrl"
            class="mini-cart-block__button"
          >
            <span class="u-position-relative u-line-height-0">
              <CartEmptyIcon class="icon" />
              <span
                class="splash-sign splash-sign--xxs splash-sign--on-icon splash-sign--green"
              >
                <span
                  v-if="miniCart.cartItemCount > 0"
                  class="text-icon text-icon--plain"
                  v-text="miniCart.cartItemCount"
                />
              </span>
            </span>
          </a>

          <template v-if="!isMobile">
            <transition name="fade">
              <mini-cart-dropdown v-if="miniCart.isVisible" />
            </transition>
          </template>

          <mini-cart-modal
            v-else
            modal-name="mini-cart-modal"
          />
        </div>

        <login-opener
          :redirect-to-ecom-on-login="redirectToEcomOnLogin"
          :is-mobile="isMobile"
        />
      </div>

      <header-sidebar
        :translations="cmsNavigation.translations"
        :class="{ 'login-is-opened': headerSidebarIsVisible }"
      />
    </div>
  </div>
</template>

<script>
import {
  computed, ref, onBeforeUnmount, onMounted
} from 'vue'
import { mixin as clickaway } from 'vue-clickaway'

import LogoIcon from '@/assets/images/arla-logo.svg'
import IconBookmark from '@/assets/icons/streamline/streamlinehq-interface-bookmark.svg'
import CartEmptyIcon from '@/assets/icons/streamline/streamlinehq-shopping-cart-1-shopping-ecommerce-16.svg'
import NotificationIcon from '@/assets/icons/streamline/streamlinehq-alarm-bell-24.svg'

import useHeaderControls from '@/compositions/useHeaderControls'
import useHeaderModel from '@/compositions/useHeaderModel'
import useAuth from '@/compositions/useAuth'
import useMiniCart from '@/compositions/useMiniCart'
import useScrollHandler from '@/compositions/useScrollHandler'
import useFavorites from '@/compositions/favorites/favorites-id-repository'

import HeaderSidebar from '@/components/header/header-sidebar.vue'
import MiniCartDropdown from '@/components/header/mini-cart-dropdown.vue'
import MiniCartModal from '@/components/header/mini-cart-modal.vue'
import MainMenu from '@/components/header/main-menu.vue'
import Search from '@/components/header/header-main-navigation-search.vue'
import LoginOpener from '@/components/header/login-opener.vue'

const { isConnectAuth, isAuth } = useAuth()

export default {
  components: {
    MiniCartDropdown,
    MiniCartModal,
    HeaderSidebar,
    MainMenu,
    Search,
    LoginOpener,

    LogoIcon,
    IconBookmark,
    NotificationIcon,
    CartEmptyIcon
  },
  mixins: [
    clickaway
  ],
  props: {
    redirectToEcomOnLogin: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const element = ref()
    const isMobile = ref(window.matchMedia('(max-width: 992px)').matches)
    const { state: miniCart } = useMiniCart()
    const {
      headerSidebarIsVisible,

      showHeaderSidebar,
      headerClickAway
    } = useHeaderControls()

    const {
      cmsNavigation,
      notifications,

      fetchCmsNavigation
    } = useHeaderModel()

    const checkMobile = () => {
      isMobile.value = window.matchMedia('(max-width: 992px)').matches
    }

    const {
      fixed,
      pinned,

      handleScroll,
      setPinned
    } = useScrollHandler()
    const onScroll = () => {
      handleScroll(element.value)
    }

    const cssClass = computed(() => ([
      'site-header',
      {
        'header--pinned': pinned.value,
        'header--fixed': fixed.value
      }
    ]))

    const showLogo = computed(() => (
      cmsNavigation.value.logoInfo
      && cmsNavigation.value.logoInfo.url
      && cmsNavigation.value.logoInfo.text
    ))

    const showFavorites = computed(() => (
      cmsNavigation.value.favorites
        && cmsNavigation.value.favorites.url
        && isAuth.value
    ))

    const { totalCount } = useFavorites()
    const favoritesModel = computed(() => {
      const {
        favorites: {
          url, text
        }
      } = cmsNavigation.value

      return {
        showFavorites,
        url,
        text,
        totalCount: totalCount.value
      }
    })

    const onFavoritesClick = (e) => {
      if (!isAuth.value) {
        e.preventDefault()
        showHeaderSidebar(false)
      }
    }

    window.addEventListener('resize', checkMobile)
    onMounted(() => {
      setTimeout(() => {
        handleScroll(element.value)
        setPinned(true)
        window.addEventListener('scroll', onScroll)
      })
    })
    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkMobile)
      window.removeEventListener('scroll', onScroll)
    })

    fetchCmsNavigation()
    return {
      element,
      cmsNavigation,
      notifications,
      isConnectAuth,
      miniCart,
      headerSidebarIsVisible,
      isMobile,
      fixed,
      pinned,
      cssClass,
      showLogo,
      showFavorites,
      favoritesModel,

      fetchCmsNavigation,
      headerClickAway,
      handleScroll,
      onFavoritesClick
    }
  }
}
</script>
