import GtmHelper from '@/helpers/GtmHelper'

export default async (element, url) => {
  const activeClass = 'copied'
  await window.navigator.clipboard.writeText(url)

  const linkTextElement = element.querySelector('.js-text')
  if (linkTextElement) {
    linkTextElement.innerHTML = element.dataset.text
  }
  element.classList.add(activeClass)
  setTimeout(() => {
    element.classList.remove(activeClass)
  }, 2000)
  GtmHelper.pushDataLayer({
    event: 'copyLink'
  })
}
