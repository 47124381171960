<template>
  <div
    class="newdropdown"
    @mouseenter="removeHideTimeout"
    @mouseleave="hideMiniCart"
  >
    <div class="newdropdown-wrap">
      <mini-cart />
    </div>
  </div>
</template>

<script>
import MiniCart from '@/components/header/mini-cart.vue'
import useMiniCart from '@/compositions/useMiniCart'

export default {
  components: {
    MiniCart
  },
  setup() {
    const {
      removeHideTimeout,
      hideMiniCart
    } = useMiniCart()

    return {
      hideMiniCart,
      removeHideTimeout
    }
  }
}
</script>
