import { reactive, computed } from 'vue'
import favoritesQueryState from './favorites-query'

const favoritesTabConfigurationState = reactive({
  tabsConfiguration: [],
  initialized: false
})

export default {
  favoritesTabsConfigurationUse(configuration) {
    const {
      favoritesQuery,
      setFavoritesType,
      setAllFavoritesTypes
    } = favoritesQueryState.favoritesQueryUse()
    const state = favoritesTabConfigurationState

    if (configuration && !state.initialized) {
      state.tabsConfiguration = configuration
      setFavoritesType(configuration.find((x, index) => index === 0).type)
      setAllFavoritesTypes(configuration.map((x) => x.type))
      state.initialized = true
    }

    function getConfigBySearchType(favoritesType) {
      return state.tabsConfiguration.find((x) => x.type === favoritesType)
    }
    function getActiveConfig() {
      return getConfigBySearchType(favoritesQuery.favoritesType)
    }

    return {
      getActiveConfig,
      getConfigBySearchType,

      initialized: computed(() => state.initialized),
      tabsConfiguration: computed(() => state.tabsConfiguration)
    }
  }
}
