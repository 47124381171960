import { computed, reactive, watch } from 'vue'
import api from '@/lib/api'
import favoritesQueryState from './favorites-query'

const favoritesResultState = reactive({
  loading: false,
  allItemsLoaded: false,
  totalCount: 0,
  hasResults: false,
  items: [],
  relatedItems: [],
  trackId: ''
})

const getFetchApiName = (type) => {
  switch (type) {
    case 'catalogue':
      return 'getCatalogues'
    case 'product':
      return 'getFavoriteProducts'
    case 'recipe':
      return 'getFavoriteRecipes'
    default:
      return 'getFavoriteBusinessCases'
  }
}

export default {
  useFavoritesResult() {
    const { favoritesQuery } = favoritesQueryState.favoritesQueryUse()
    const state = favoritesResultState

    const sortItems = (a, b) => a.name.localeCompare(b.name)
    const sortByDates = (a, b) => (new Date(b.utcUpdateTime) - new Date(a.utcUpdateTime))

    const sortedItems = computed(() => {
      const items = [...state.items]
      switch (favoritesQuery.sorting) {
        case 'NameAZ':
          return items.sort(sortItems)
        case 'NameZA':
          return items.sort(sortItems).reverse()
        case 'Date':
          return items.sort(sortByDates)
        case 'DateDESC':
          return items.sort(sortByDates).reverse()
        default:
          return items
      }
    })

    async function fetchFavoriteItems() {
      state.items = []
      state.relatedItems = []
      state.totalCount = 0
      state.allItemsLoaded = false
      state.hasResults = false

      const apiName = getFetchApiName(favoritesQuery.favoritesType)
      const response = await api[apiName]({
        sorting: favoritesQuery.sorting
      })
      if (response.data.searchResult) {
        Object.assign(state, response.data.searchResult)
      } else {
        state.items = []
        state.totalCount = 0
        state.allItemsLoaded = false
        state.hasResults = false
      }
      if (response.data.relatedItems) {
        state.relatedItems = response.data.relatedItems
      } else {
        state.relatedItems = []
      }
      state.hasResults = state.totalCount > 0
      state.allItemsLoaded = true
    }

    const removeItemById = (id = null) => {
      const filteredItems = state.items.filter((item) => item.id !== id)
      state.totalCount -= 1
      state.items = filteredItems
    }

    async function fetchItems() {
      if (!state.loading) {
        state.loading = true
        fetchFavoriteItems().then(() => {
          state.loading = false
        })
      }
    }

    watch(
      () => [favoritesQuery.updated],
      () => {
        if (!state.loading && favoritesQuery.favoritesType) {
          fetchItems()
        }
      }
    )

    return {
      allItemsLoaded: computed(() => state.allItemsLoaded),
      loading: computed(() => state.loading),
      totalCount: computed(() => state.totalCount),
      items: sortedItems,
      relatedItems: computed(() => state.relatedItems),
      trackId: computed(() => state.trackId),
      hasResults: computed(() => state.hasResults),
      fetchItems,
      removeItemById
    }
  }
}
