import favoritesQueryState from './favorites-query'
import favoritesTabsConfig from './favorites-tabs-configuration'

export default {
  useRouterHelper(routerParam, baseUrl) {
    const {
      getActiveConfig,
      getConfigBySearchType
    } = favoritesTabsConfig.favoritesTabsConfigurationUse()

    const { favoritesQuery } = favoritesQueryState.favoritesQueryUse()
    const router = routerParam

    function updateRoute() {
      const query = {}

      const config = getActiveConfig()
      const path = baseUrl.concat(favoritesQuery.favoritesType)

      const defaultSorting = config.sortingOptions.find((x) => x.default).value
      if (favoritesQuery.sorting && favoritesQuery.sorting !== defaultSorting) {
        query.sorting = favoritesQuery.sorting
      }

      router.push({
        path,
        query
      }).catch(() => {})
    }

    function getRouteData() {
      let href = router.currentRoute.path
        .substr(router.currentRoute.path.lastIndexOf(baseUrl) + baseUrl.length)
      if (href.endsWith('/')) {
        href = href.substring(0, href.length - 1)
      }
      const favoritesType = href.substring(href.lastIndexOf('/') + 1) || 'product'
      getConfigBySearchType(favoritesType)

      const sorting = router.currentRoute.query.sorting || null

      return {
        favoritesType,
        sorting
      }
    }

    return {
      getRouteData,
      updateRoute
    }
  }
}
