<template>
  <form
    class="c-redirect-tool-block form--default"
    @submit.prevent
  >
    <div class="form-row">
      <label
        for="product-ean"
        class="label"
      >
        Product EAN
      </label>
      <input
        id="product-ean"
        v-model="ean"
        required
        type="number"
        name="productEan"
        tabindex="0"
        placeholder="Enter 13 digit EAN"
        :class="{ 'error': ean.length % 13 !== 0 }"
        pattern="\d{13}"
      >
    </div>
    <template v-if="model.wholesalers">
      <div class="form-row">
        <label
          for="wholesaler"
          class="label"
        >
          Wholesaler
        </label>
        <select
          id="wholesaler"
          v-model="wholesalerId"
          class="form-dropdown"
          required
        >
          <option
            disabled
            hidden
            value=""
          >
            Choose wholesaler
          </option>
          <option
            v-for="(value, key) in model.wholesalers"
            :key="key"
            :value="key"
            v-text="value"
          />
        </select>
      </div>
      <div class="form-row">
        <label
          for="block-type"
          class="label"
        >
          Use link in
        </label>
        <select
          id="block-type"
          v-model="placement"
          class="form-dropdown"
          required
        >
          <option
            v-for="(value, key) in model.placements"
            :key="key"
            :value="key"
            v-text="value"
          />
        </select>
      </div>
      <div
        v-if="redirectPageUrl"
        class="form-row"
      >
        <div class="mb-xxs">
          <a
            :href="redirectPageUrl"
            :title="redirectPageUrl"
            rel="noopener noreferrer"
            v-text="redirectPageUrl"
          />
        </div>
        <div>
          <a
            :href="redirectPageUrl"
            class="cta cta--link copy-link--with-text"
            :title="redirectPageUrl"
            rel="noopener noreferrer"
            @click.prevent="useCopyLink($event.currentTarget, redirectPageUrl)"
          >
            <IconShare class="icon" />
            Copy to clipboard
            <IconTick class="icon icon--copied" />
          </a>
        </div>
      </div>
    </template>
  </form>
</template>

<script>
import { ref, computed } from 'vue'
import IconShare from '@/assets/icons/share.svg'
import IconTick from '@/assets/icons/tick.svg'
import useCopyLink from '@/compositions/useCopyLink'
import getGuid from '@/helpers/getGuid'

export default {
  components: {
    IconShare,
    IconTick
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup({ model }) {
    const placement = ref(Object.keys(model.placements)[0])
    const ean = ref('')
    const wholesalerId = ref('')
    const { redirectPageUrlTemplate } = model
    const guid = getGuid()

    const redirectPageUrl = computed(() => (!wholesalerId.value || ean.value.length !== 13 ? ''
      : redirectPageUrlTemplate
        .replace('{ean}', ean.value)
        .replace('{placement}', placement.value)
        .replace('{wholesaler_id}', wholesalerId.value)
        .replace('&qr_code_id=', placement.value === 'qr' ? '&qr_code_id=' : '')
        .replace('{qr_code_id}', placement.value === 'qr' ? guid : '')
    ))

    return {
      ean,
      placement,
      wholesalerId,
      redirectPageUrl,

      useCopyLink
    }
  }
}
</script>
