import Proxy from '@/components/Proxy'

function toPascalCase(kebabCase) {
  const camelCase = kebabCase.replace(/-([a-z])/g, (g) => g[1].toUpperCase())
  return camelCase[0].toUpperCase() + camelCase.slice(1)
}

function parseProps(dataset) {
  const props = {}

  Object.keys(dataset).forEach((prop) => {
    const val = dataset[prop]

    if (val === 'true') {
      props[prop] = true
    } else if (val === 'false') {
      props[prop] = false
    } else if (val === 'null') {
      props[prop] = null
    } else if (Number(val)) {
      props[prop] = Number(val)
    } else if (val.length > 1
      && ((val[0] === '[' && val[val.length - 1] === ']')
      || (val[0] === '{' && val[val.length - 1] === '}'))) {
      props[prop] = JSON.parse(val)
    } else {
      props[prop] = val
    }
  })

  return props
}

export default {
  mountComponents(components) {
    const vueBlocksArray = Array.from(document.querySelectorAll('[data-vue]'))

    if (vueBlocksArray.length) {
      vueBlocksArray.forEach((el) => {
        const componentName = toPascalCase(el.dataset.vue)
        if (!components[componentName]) return

        const component = components[componentName]
        const props = parseProps(el.dataset)
        props.slotContent = el.innerHTML

        new Proxy({
          propsData: {
            component,
            props
          }
        }).$mount(el)
      })
    }
  }
}
