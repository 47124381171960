<template>
  <div class="card-list__header">
    <div
      class="card-list__count"
      v-html="totalCountText"
    />
    <div class="card-list__controls">
      <div
        v-if="sortingOptions.length"
        class="filter-dropdown-warp"
      >
        <filter-dropdown
          :filters="sortingOptions"
          :selected="sorting"
          title="Sortera"
          @chooseFilter="setSorting"
        />
      </div>
      <search-view-selector />
    </div>
  </div>
</template>
<script>

import { computed } from 'vue'
import FilterDropdown from '@/components/filter-dropdown.vue'

import searchTabsConfig from '@/compositions/search/search-tabs-configuration'
import searchQueryState from '@/compositions/search/search-query'
import searchRepository from '@/compositions/search/search-repository'
import SearchViewSelector from './search-view-selector.vue'

export default {
  components: {
    FilterDropdown,
    SearchViewSelector
  },
  setup() {
    const { getActiveConfig } = searchTabsConfig.tabsConfigurationUse()

    const {
      searchQuery,
      setSorting
    } = searchQueryState.searchQueryUse()

    const { totalCount } = searchRepository.useSearchResult()
    const sorting = computed(() => {
      if (searchQuery.sorting) {
        return searchQuery.sorting
      }

      return getActiveConfig().sortingOptions.find((x) => x.default).value
    })
    const totalCountText = computed(() => {
      const { totalCountMessageDesktop } = getActiveConfig().translations
      return totalCountMessageDesktop.replace('{count}', `<strong>${totalCount.value}</strong>`)
    })

    return {
      sorting,

      sortingOptions: computed(() => getActiveConfig().sortingOptions),
      totalCountText,

      setSorting
    }
  }
}
</script>
