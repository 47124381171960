export default {
  basicDetails: {
    component: 'CatalogueBasicDetails',
    isFinal: false
  },
  visualDetails: {
    component: 'CatalogueVisualDetails',
    isFinal: false
  },
  additionalInformation: {
    component: 'CatalogueAdditionalInformation',
    isFinal: true
  }
}
