import { reactive } from 'vue'
import api from '@/lib/api'
import useAuth from '@/compositions/useAuth'

const productsBottomBannerState = reactive({
  title: '',
  text: '',
  linkText: '',
  linkUrl: '',
  linkOpenInNewWindow: '',
  initialized: false
})

export default {
  productsBottomBannerUse() {
    const { isBusinessAuth } = useAuth()
    if (isBusinessAuth.value) {
      api.getProductsBottomBannerInfo().then((response) => {
        productsBottomBannerState.title = response.data.title
        productsBottomBannerState.text = response.data.text
        productsBottomBannerState.linkText = response.data.linkText
        productsBottomBannerState.linkUrl = response.data.linkUrl
        productsBottomBannerState.linkOpenInNewWindow = response.data.linkOpenInNewWindow
        productsBottomBannerState.initialized = true
        productsBottomBannerState.trackingData = {
          cta: JSON.stringify({ event: 'searchBannerOpen' }),
          closeButton: JSON.stringify({ event: 'searchBannerClose' })
        }
      })
    }

    return {
      bannerModel: productsBottomBannerState
    }
  }
}
