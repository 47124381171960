import GtmHelper from '@/helpers/GtmHelper'

export default ($modal) => {
  const showPopup = (modalName) => {
    $modal.show(modalName)
  }

  const hidePopup = (modalName) => {
    $modal.hide(modalName)
  }

  const beforeOpenModal = (blockTitle, bodyCssClasses = ['show-v-modal']) => {
    document.documentElement.classList.add(...bodyCssClasses)
    if (blockTitle) {
      GtmHelper.expandBlock(blockTitle)
    }
  }

  const beforeCloseModal = (blockTitle, bodyCssClasses = ['show-v-modal']) => {
    document.documentElement.classList.remove(...bodyCssClasses)
    if (blockTitle) {
      GtmHelper.shrinkBlock(blockTitle)
    }
  }

  return {
    showPopup,
    hidePopup,
    beforeOpenModal,
    beforeCloseModal
  }
}
