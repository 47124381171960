<template>
  <div
    v-if="showBottomBanner"
    class="bottom-banner cart-summary"
  >
    <div class="bottom-banner__inner">
      <div class="bottom-banner__inner-container">
        <div class="cart-summary__sum">
          <span>{{ translations.quantity }}</span><br>
          <strong>{{ cartSummary.sum }}</strong>
        </div>
        <div class="cart-summary__price">
          <span>{{ translations.summa }}</span><br>
          <strong>{{ price }}</strong>
        </div>
        <a
          :href="translations.checkoutPageUrl"
          :class="{ disabled: cartSummary.sum === 0 }"
          class="cta"
          tabindex="0"
          @click="materialGoToCheckout"
        >
          {{ translations.buyButtonText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import useOrderMaterial from '@/compositions/orderMaterials/useOrderMaterial'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  setup() {
    const translations = window.arla.cms.resources.orderMaterials.basketBanner
    const { cartSummary } = useOrderMaterial()
    const price = cartSummary.totalPrice || '0,00 kr'

    const materialGoToCheckout = () => {
      GtmHelper.materialGoToCheckout(cartSummary.value.sum)
    }

    return {
      cartSummary,
      price,
      showBottomBanner: true,
      translations,
      materialGoToCheckout
    }
  }
}
</script>
