<template>
  <div
    id="site-search-block"
    class="site-search-block"
    @click.stop
  >
    <form
      id="site-search-form"
      :class="{ 'show-search': searchIsShown }"
      class="site-search-form"
      method="GET"
      autocomplete="off"
      @submit.prevent="applySearchTerm"
    >
      <div
        v-on-clickaway="searchClickAway"
        class="site-search-form__field-container"
      >
        <input
          id="site-search-input"
          ref="searchField"
          :placeholder="model.searchPlaceholderText"
          class="site-search-form__search-input"
          type="search"
          name="query"
          required
          autocomplete="off"
          @input="debounceSearch"
        >
        <SearchIcon
          v-if="isMobile"
          class="icon"
        />
        <span
          v-if="isMobile"
          role="button"
          class="x-mark"
          @click="hideSearch"
        >
          <!--  TODO: Add translation  -->
          Hide Search
        </span>
      </div>
      <label
        class="site-search-form__opener"
        for="site-search-input"
        @click="showSearch"
      >
        <span class="visuallyhidden">{{ model.searchLabel }}</span>
        <SearchIcon class="icon" />
      </label>
      <button
        v-show="searchIsShown || isMobile"
        :disabled="!queryText.length"
        class="site-search-form__search-submit"
        value="search"
      >
        {{ model.searchLabel }}
      </button>
    </form>

    <search-dropdown
      v-show="showSearchDropdown"
      ref="searchDropdown"
    />
  </div>
</template>

<script>
import {
  ref, watch, computed, onBeforeUnmount
} from 'vue'
import { mixin as clickaway } from 'vue-clickaway'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import searchQueryState from '@/compositions/search/search-query'
import quickSearchRepository from '@/compositions/search/quick-search-repository'

import SearchDropdown from '@/components/search/search-dropdown.vue'
import SearchIcon from '@/assets/icons/search.svg'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    SearchDropdown,
    SearchIcon
  },
  mixins: [
    clickaway
  ],
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const {
      setTerm, searchResults, resetQuickSearchResults
    } = quickSearchRepository.quickSearchUse()
    const { setTerms } = searchQueryState.searchQueryUse()

    const searchDropdown = ref()
    const searchIsShown = ref(false)
    const trySubmit = ref(false)
    const queryText = ref('')
    const isMobile = ref(window.matchMedia('(max-width: 992px)').matches)
    const showSearchDropdown = computed(() => (isMobile.value ? searchIsShown.value
      : searchResults.value.length && searchIsShown.value))
    const activeClass = 'show-search'
    const searchField = ref()

    watch(queryText, (value) => {
      if (value.length > 2) {
        setTerm(value)
        GtmHelper.mainMenuTypeSearchTerm(value)
      } else {
        resetQuickSearchResults()
      }
    })

    const getLocation = (href) => {
      const el = document.createElement('a')
      el.href = href
      return el
    }

    const hideSearch = () => {
      if (trySubmit.value) {
        trySubmit.value = false
        return
      }

      searchIsShown.value = false
      document.body.classList.remove(activeClass)
      enableBodyScroll(searchDropdown.value.$el)
    }

    const showSearch = () => {
      setTimeout(() => {
        searchIsShown.value = true
        document.body.classList.add(activeClass)
        disableBodyScroll(searchDropdown.value.$el)
        GtmHelper.mainMenuSearchClick()
      }, 0)
      setTimeout(() => {
        searchField.value.focus()
      }, 100)
    }

    const applySearchTerm = () => {
      trySubmit.value = true

      const searchUrlObject = getLocation(props.model.searchUrl)
      const searchTerm = queryText.value

      if (window.location.pathname.includes(searchUrlObject.pathname)) {
        setTerms([searchTerm])
        hideSearch()
        trySubmit.value = false
      } else {
        window.location = props.model.searchUrl.concat(searchTerm)
      }

      queryText.value = ''
    }

    const searchClickAway = () => {
      if (searchIsShown.value) {
        hideSearch()
      }
    }

    let debounce = null
    const debounceSearch = (event) => {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        queryText.value = event.target.value
      }, 600)
    }

    const checkMobile = () => {
      isMobile.value = window.matchMedia('(max-width: 992px)').matches
    }

    window.addEventListener('resize', checkMobile)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkMobile)
    })

    return {
      searchField,
      queryText,
      isMobile,
      searchIsShown,
      showSearchDropdown,
      searchDropdown,

      applySearchTerm,
      showSearch,
      hideSearch,
      searchClickAway,
      debounceSearch
    }
  }
}
</script>
