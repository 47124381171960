<template>
  <div class="service-menu form--default c-header-sidebar-section">
    <div class="u-flex u-flex-direction-column u-gap-sm mb-md">
      <strong class="h4 mb-0">
        Hej, {{ state.userName }}
      </strong>

      <select
        v-if="state.stores && state.stores.length"
        :value="selectedStore"
        class="form-dropdown"
        @change="selectStore($event.target.value)"
      >
        <option
          v-for="item in state.stores"
          :key="item.id"
          :value="item.id"
          v-text="`${item.customerNumber} - ${item.name}`"
        />
      </select>

      <a
        v-if="userProfileModel"
        :href="userProfileModel.url"
        class="text-link u-text-nowrap base-font--sm u-text-underline"
        v-text="userProfileModel.title"
      />

      <a
        v-if="logoutModel"
        :href="logoutModel.url"
        class="cta cta--average cta--dark u-text-nowrap"
        @click="onLogoutClick"
        v-text="logoutModel.title"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import useConnect from '@/compositions/useConnect'
import useAuth from '@/compositions/useAuth'
import { TYPES } from '@/compositions/useLogin'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  props: {
    translations: {
      type: Object,
      required: true
    }
  },
  setup() {
    const {
      state, selectStore
    } = useConnect()
    const { isBusinessAuth } = useAuth()
    // TODO: Should we change the model for user menu for connect?
    const userProfileModel = computed(() => state.userMenu[0])
    const logoutModel = computed(() => state.userMenu[1])
    const selectedStore = computed(() => (state.selectedStore ? state.selectedStore.id : null))

    const onLogoutClick = () => {
      const type = isBusinessAuth.value ? TYPES.business : TYPES.private
      GtmHelper.logOut(type)
    }
    return {
      state,
      logoutModel,
      userProfileModel,
      selectedStore,

      selectStore,
      onLogoutClick
    }
  }
}
</script>
