<template>
  <div
    :class="{ 'is-loading': loading }"
    class="items-overview"
  >
    <div class="items-overview__text">
      <h1 class="items-overview__title">
        {{ title }}
      </h1>
      <div
        v-if="description"
        v-html="description"
      />
    </div>

    <div class="items-overview__search">
      <catalogue-button
        v-if="catalogueButtonModel"
        class="u-visible--desktop"
        :model="catalogueButtonModel"
      />
      <taxonomy-search
        :search-text="searchText"
      />
    </div>

    <items-overview-controls>
      <catalogue-button
        v-if="catalogueButtonModel"
        :model="catalogueButtonModel"
      />
    </items-overview-controls>
    <filters />
    <items />

    <cart-summary
      v-if="hasCart"
    />
  </div>
</template>

<script>
import { computed, watch } from 'vue'

import Filters from '@/components/search/search-filters.vue'
import Items from '@/components/search/search-items.vue'
import TaxonomySearch from '@/components/search/taxonomy-search-form.vue'
import CatalogueButton from '@/components/catalogue/catalogue-button.vue'
import ItemsOverviewControls from '@/components/itemsOverview/items-overview-controls.vue'

import router from '@/compositions/search/search-router'
import searchQueryState from '@/compositions/search/search-query'
import searchTabsConfig from '@/compositions/search/search-tabs-configuration'
import routerHelper from '@/compositions/search/search-router-helper'
import searchRepository from '@/compositions/search/search-repository'
import CartSummary from '@/components/orderMaterials/order-material-cart-summary.vue'

export default {
  router,
  components: {
    CartSummary,
    Filters,
    Items,
    TaxonomySearch,
    ItemsOverviewControls,
    CatalogueButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    tabsConfiguration: {
      type: Array,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    trackingPrefix: {
      type: String,
      required: true
    },
    hasCart: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { searchText } = window.arla.cms.resources.orderMaterials
    const { getActiveConfig } = searchTabsConfig.tabsConfigurationUse(props.tabsConfiguration)
    const { getRouteData, updateRoute } = routerHelper.useRouterHelper(router, props.baseUrl)
    const routeData = getRouteData()
    const { setTrackingPrefix } = searchQueryState.searchQueryUse(routeData)
    const { loading } = searchRepository.useSearchResult()
    const catalogueButtonModel = computed(() => {
      if (!getActiveConfig().catalogueButton) {
        return null
      }

      const {
        url,
        text,
        attributes: {
          target
        }
      } = getActiveConfig().catalogueButton

      return {
        url,
        text,
        target
      }
    })

    setTrackingPrefix(props.trackingPrefix)

    watch(
      () => [loading.value],
      () => {
        if (!loading.value) {
          updateRoute()
        }
      }
    )
    return {
      loading,
      searchText,
      catalogueButtonModel
    }
  }
}
</script>
