<template>
  <card-slider
    v-if="favoritesType !== 'catalogue' && relatedItems.length"
    :heading="relatedItemsTitle"
    :items="relatedItems"
    :card-type="favoritesType"
  />
</template>
<script>
import { computed } from 'vue'

import CardSlider from '@/components/card-slider.vue'
import favoritesRepository from '@/compositions/favorites/favorites-repository'
import favoritesTabsConfig from '@/compositions/favorites/favorites-tabs-configuration'

// TODO: Add proper translation for card slider heading

export default {
  components: {
    CardSlider
  },
  setup() {
    const { relatedItems } = favoritesRepository.useFavoritesResult()
    const { getActiveConfig } = favoritesTabsConfig.favoritesTabsConfigurationUse()

    return {
      favoritesType: computed(() => getActiveConfig().type),
      relatedItemsTitle: computed(() => getActiveConfig().translations.relatedItemsTitle),
      relatedItems
    }
  }
}
</script>
