<template>
  <div
    id="recaptcha"
    data-size="invisible"
  />
</template>
<script>
import { renderCaptcha, verifyCaptcha } from '@/helpers/recaptcha'

export default {
  props: {
    recaptchaKey: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.recaptcha.net/recaptcha/api.js?onload=renderCaptcha&render=explicit')
    document.head.appendChild(recaptchaScript)

    const getRecaptchaResponse = function (response) {
      if (response) {
        context.emit('submit', response)
      }
    }

    window.renderCaptcha = () => {
      renderCaptcha(props.recaptchaKey, getRecaptchaResponse)
    }

    const verify = () => {
      verifyCaptcha((response) => {
        context.emit('submit', response)
      })
    }

    return {
      verify
    }
  }
}
</script>
