<template>
  <div>
    <button
      class="cta cta--delete"
      @click.prevent="showPopup(modalName)"
    >
      <span>{{ model.buttonText }}</span>
      <IconBin class="icon" />
    </button>
    <Modal
      :model="model"
      :modal-name="modalName"
    />
  </div>
</template>

<script>
import IconBin from '@/assets/icons/bin.svg'
import Modal from '@/components/userCockpit/delete-user-profile-modal.vue'
import useModal from '@/compositions/useModal'
import { getCurrentInstance } from 'vue'

export default {
  components: {
    Modal,
    IconBin
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup() {
    const modalName = 'delete-user-modal'
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      showPopup
    } = useModal($modal)

    return {
      modalName,

      showPopup
    }
  }
}
</script>
