<template>
  <div class="form--default c-header-sidebar-section">
    <div class="form-title">
      <strong class="h3 mb-xxs u-flex">{{ translations.supLoginTitle }}</strong>
      <div
        class="mb-0 base-font--sm"
        v-html="translations.supLoginDescription"
      />
    </div>
    <div class="u-flex u-flex-direction-column u-gap-sm mb-md">
      <a
        v-if="translations.supFacebookSignInUrl && translations.supFacebookLoginLabel"
        :href="translations.supFacebookSignInUrl"
        class="cta cta--average cta--facebook u-text-nowrap u-width-100"
        @click="onButtonClick('facebook', translations.supFacebookLoginLabel)"
      >
        <IconFacebook class="icon" />
        <span v-text="translations.supFacebookLoginLabel" />
      </a>
      <a
        v-if="translations.supAppleSignInUrl && translations.supAppleLoginLabel"
        :href="translations.supAppleSignInUrl"
        class="cta cta--average cta--apple u-text-nowrap u-width-100"
        @click="onButtonClick('apple', translations.supAppleLoginLabel)"
      >
        <IconApple class="icon" />
        <span v-text="translations.supAppleLoginLabel" />
      </a>
      <a
        v-if="translations.supGoogleSignInUrl && translations.supGoogleLoginLabel"
        :href="translations.supGoogleSignInUrl"
        class="cta cta--average cta--google u-text-nowrap u-width-100"
        @click="onButtonClick('google', translations.supGoogleLoginLabel)"
      >
        <IconGoogle class="icon" />
        <span v-text="translations.supGoogleLoginLabel" />
      </a>
      <a
        v-if="translations.supAppleSignInUrl && translations.supEmailLoginLabel"
        :href="translations.supEmailSignInUrl"
        class="cta cta--link cta--average u-text-nowrap u-width-100"
        @click="onButtonClick('email', translations.supEmailLoginLabel)"
      >
        <IconEmail class="icon" />
        <span v-text="translations.supEmailLoginLabel" />
      </a>
    </div>
  </div>
</template>

<script>
import IconFacebook from '@/assets/icons/social-media-facebook-1.svg'
import IconApple from '@/assets/icons/social-media-apple-logo.svg'
import IconGoogle from '@/assets/icons/social-media-google-logo.svg'
import IconEmail from '@/assets/icons/email-action-unread.svg'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    IconFacebook,
    IconApple,
    IconGoogle,
    IconEmail
  },
  props: {
    translations: {
      type: Object,
      required: true
    }
  },
  setup() {
    const onButtonClick = (buttonName, type) => {
      GtmHelper.logInSUPButtonClick(buttonName, type)
    }

    return {
      onButtonClick
    }
  }
}
</script>
