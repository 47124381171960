<template>
  <div class="accordion-list">
    <accordion-item
      v-for="(item, index) in items"
      :key="index"
      :model="item"
      :index="index"
      :first-open="true"
    />
  </div>
</template>

<script>
import AccordionItem from '@/components/accordion/accordion-item.vue'

export default {
  components: {
    AccordionItem
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
