<template>
  <div
    v-if="videoId || thumbnail"
    :class="{ 'transition--delay' : isFirstPlay }"
    class="ambient-media"
  >
    <youtube
      v-if="showPlayer"
      :player-width="'100%'"
      :player-height="'100%'"
      :player-vars="playerOptions"
      :mute="true"
      :host="'https://www.youtube-nocookie.com'"
      class="ambient-media__player"
      @ready="videoReady"
      @playing="videoPlaying"
      @ended="videoEnded"
    />
    <transition name="thumb">
      <div
        v-show="!isVideoPlaying"
        class="ambient-media__cover"
      >
        <generic-image
          v-if="thumbnail"
          :large-image-url="thumbnail"
          :width="thumbnailWidth"
          :height="thumbnailHeight"
          class="ambient-media__thumbnail"
        />
      </div>
    </transition>
    <div class="ambient-media__overlay" />
  </div>
</template>

<script>
export default {
  props: {
    videoId: {
      type: String,
      required: false,
      default: null
    },
    thumbnail: {
      type: String,
      required: false,
      default: null
    },
    thumbnailWidth: {
      type: Number,
      required: false,
      default: null
    },
    thumbnailHeight: {
      type: Number,
      required: false,
      default: null
    },
    hideOnMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPlayer: false,
      isFirstPlay: true,
      isVideoPlaying: false,
      playerOptions: {
        autoplay: 1,
        controls: 0,
        loop: 1,
        playlist: null,
        showinfo: 0,
        modestbranding: 1,
        rel: 0,
        playsinline: 1
      },
      player: null,
      duration: 0
    }
  },
  computed: {
    videoThumbnail() {
      return (this.videoId !== null && this.thumbnail === null)
        ? '' : this.thumbnail
    }
  },
  mounted() {
    const showPlayer = this.videoId && (this.hideOnMobile ? this.$mq === 'desktop' : true)

    setTimeout(() => {
      this.showPlayer = showPlayer
    }, 500)
  },
  methods: {
    videoReady(e) {
      this.player = e.target
      this.player.loadVideoById(this.videoId)
    },
    videoPlaying() {
      this.duration = this.player.getDuration()
      this.isVideoPlaying = true

      if (this.duration > 0) {
        setTimeout(() => {
          this.isVideoPlaying = false
        }, (this.duration - 1) * 1000)
      }
    },
    videoEnded() {
      this.isFirstPlay = false
      this.isVideoPlaying = false
      setTimeout(() => {
        this.player.playVideo()
      }, 500)
    }
  }
}
</script>
