import { computed, reactive } from 'vue'
import api from '@/lib/api'
import { getFrequencyTime } from '@/helpers/date-util'
import { setHem } from '@/helpers/hem'

const hem = localStorage.getItem('hem') || ''
const showedPopups = JSON.parse(localStorage.getItem('showedPopups')) || []
const popupShowedTime = parseInt(localStorage.getItem('popupShowedTime'), 10) || 0
let sessionsNumber = parseInt(localStorage.getItem('popupSessions'), 10) || 0
const state = reactive({
  contentModel: {
    header: '',
    description: '',
    hem: null,
    imageUrl: '',

    surveyId: null,
    question: null,
    theme: 'yellow',
    token: null,
    typeName: '',
    link: {
      url: '',
      text: ''
    }
  },
  popupId: null,
  popups: {
    number: null,
    frequency: null,
    activationInSeconds: null,
    activationPercentOfPage: null,
    availablePopupIds: [],
    language: null
  },
  hem,
  popupShowedTime,
  showedPopups
})

export default () => {
  const initPopup = () => api.getInfoPopupModel({
    popupId: state.popupId,
    hem: state.hem
  })
    .then(({ data }) => {
      state.contentModel = data
      state.showedPopups.push(state.popupId)

      localStorage.setItem('showedPopups', JSON.stringify(state.showedPopups))
      localStorage.setItem('popupShowedTime', (new Date()).getTime())
      if (state.popups.frequency === 'session') {
        sessionStorage.setItem('infoPopupIsShown', 'true')
        sessionsNumber += 1
        localStorage.setItem('popupSessions', sessionsNumber)
      }
    })

  const submitSurvey = (answerId) => api.submitSurveyApi({
    popupId: state.popupId,
    hem: state.hem,
    surveyId: state.contentModel.surveyId,
    questionId: state.contentModel.question.id,
    token: state.contentModel.token,
    answerId
  })
    .then(({ data }) => {
      state.contentModel = data
    })
    .catch(() => {
      state.contentModel = {
        header: 'Something went wrong...',
        isError: true
      }
    })

  const getPopupIdToShow = () => {
    if (!state.showedPopups.length) {
      return state.popups.availablePopupIds[0]
    }

    const id = state.popups.availablePopupIds.find((x) => !state.showedPopups.find((y) => y === x))
    if (id) {
      return id
    }

    state.showedPopups = state.showedPopups.filter((x) => (
      !state.popups.availablePopupIds.find((y) => y === x)))
    return state.popups.availablePopupIds[0]
  }

  const showPopupBySessions = () => {
    if (sessionStorage.getItem('infoPopupIsShown')) {
      return
    }

    if (sessionsNumber >= state.popups.number) {
      sessionsNumber = 0
    }
    if (sessionsNumber === 0) {
      state.popupId = getPopupIdToShow()
    }
  }

  const showPopupByTime = () => {
    const frequency = getFrequencyTime(state.popups.number, state.popups.frequency)
    const currentTime = (new Date()).getTime()

    if (state.popupShowedTime + frequency < currentTime) {
      state.popupId = getPopupIdToShow()
    }
  }

  const setPopups = (data) => {
    state.popups = data
    if (state.popups.frequency === 'session') {
      showPopupBySessions()
    } else {
      showPopupByTime()
    }
  }

  const checkDebugMode = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const showPopup = Number(urlParams.get('showPopup'))

    if (showPopup) {
      state.popupId = state.popups.availablePopupIds[showPopup - 1]
    }
  }

  const checkHemInUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlHem = urlParams.get('hem')

    if (urlHem) {
      setHem(urlHem)
      state.hem = urlHem
    }
  }

  return {
    popupId: computed(() => state.popupId),
    contentModel: computed(() => state.contentModel),
    hem: computed(() => state.hem),

    initPopup,
    setPopups,
    checkHemInUrl,
    submitSurvey,
    checkDebugMode
  }
}
