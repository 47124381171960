function useImage() {
  const getImageUrl = (image, width, mode = 'crop') => {
    if (image) {
      return image.concat(`?width=${width}&mode=${mode}&quality=80&format=webp`)
    }

    return ''
  }

  return {
    getImageUrl
  }
}

export default useImage
