import { computed, reactive } from 'vue'
import api from '@/lib/api'

const state = reactive({
  items: {
    product: [],
    recipe: [],
    businesscase: [],
    catalogue: []
  },
  loading: false
})

const totalCount = computed(() => Object.values(state.items)
  .reduce((accumulator, itemsArray) => accumulator + itemsArray.length, 0))

function setFavorites(items) {
  state.items = items
}

function getItemIds(type) {
  return state.items[type]
}

function isFavorite(id, type) {
  const storage = state.items[type]
  return storage.findIndex((itemID) => itemID === id) !== -1
}

function addToFavorites(ids, type) {
  state.loading = true
  return api.addFavorites({
    ids: ids.join('|'),
    type
  }).then(() => {
    state.items[type].push(...ids)
  }).finally(() => {
    state.loading = false
  })
}

function removeBulkFromFavorites(ids, type) {
  const itemsToRemove = []
  const filteredItems = state.items[type].filter((id) => {
    if (ids.indexOf(id) === -1) {
      return true
    }

    itemsToRemove.push(id)
    return false
  })

  if (itemsToRemove.length) {
    state.loading = true
    return api.removeFavorites({
      ids: itemsToRemove.join('|'),
      type
    }).then(() => {
      state.items[type] = filteredItems
    }).finally(() => {
      state.loading = false
    })
  }

  return Promise.resolve()
}

function removeFromFavorites(id, type) {
  return removeBulkFromFavorites([id], type)
}

export default () => ({
  items: computed(() => state.items),
  loading: computed(() => state.loading),
  totalCount
})

export {
  setFavorites,
  getItemIds,
  isFavorite,
  addToFavorites,
  removeFromFavorites
}
