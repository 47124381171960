<template>
  <ValidationObserver
    ref="observer"
    tag="form"
    class="form--default"
    slim
    @submit.prevent="onSubmit"
  >
    <div class="form-row">
      <h4>{{ formTranslations.formTitle }}</h4>
    </div>

    <div class="form-row">
      <label
        for="order-company"
        class="form-label"
        v-text="formTranslations.companyLabel"
      />
      <ValidationProvider
        v-slot="{ errors, classes }"
        :name="formTranslations.companyLabel"
        rules="required"
        slim
      >
        <input
          id="order-company"
          v-model="model.company"
          :class="classes"
          :placeholder="formTranslations.companyLabel"
          type="text"
        >
        <span class="form-message--error">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="form-row">
      <label
        for="order-name"
        class="form-label"
        v-text="formTranslations.nameLabel"
      />
      <ValidationProvider
        v-slot="{ errors, classes }"
        :name="formTranslations.nameLabel"
        rules="required"
        slim
      >
        <input
          id="order-name"
          v-model="model.name"
          :class="classes"
          :placeholder="formTranslations.nameLabel"
          type="text"
        >
        <span class="form-message--error">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="form-row">
      <label
        for="order-adress"
        class="form-label"
        v-text="formTranslations.addressLabel"
      />
      <ValidationProvider
        v-slot="{ errors, classes }"
        :name="formTranslations.addressLabel"
        rules="required"
        slim
      >
        <input
          id="order-adress"
          v-model="model.address"
          :class="classes"
          :placeholder="formTranslations.addressLabel"
          type="text"
        >
        <span class="form-message--error">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="form-row u-flex u-flex-justify-space-between">
      <div class="form-column form-column--postcode">
        <label
          for="order-postcode"
          class="form-label"
          v-text="formTranslations.postNumberLabel"
        />
        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="formTranslations.postNumberLabel"
          rules="required|numeric|digits:5"
          slim
        >
          <input
            id="order-postcode"
            v-model="model.postcode"
            :class="classes"
            :placeholder="formTranslations.postNumberLabel"
            type="text"
          >
          <span class="form-message--error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="form-column">
        <label
          for="order-city"
          class="form-label"
          v-text="formTranslations.cityLabel"
        />
        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="formTranslations.cityLabel"
          rules="required"
          slim
        >
          <input
            id="order-city"
            v-model="model.city"
            :class="classes"
            :placeholder="formTranslations.cityLabel"
            type="text"
          >
          <span class="form-message--error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="form-row">
      <label
        for="order-phone"
        class="form-label"
        v-text="formTranslations.phoneLabel"
      />
      <ValidationProvider
        v-slot="{ errors, classes }"
        :name="formTranslations.phoneLabel"
        rules="required|phone"
        slim
      >
        <input
          id="order-phone"
          v-model="model.phone"
          :class="classes"
          :placeholder="formTranslations.phoneLabel"
          type="text"
        >
        <span class="form-message--error">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="form-row">
      <label
        for="order-emal"
        class="form-label"
        v-text="formTranslations.emailLabel"
      />
      <ValidationProvider
        v-slot="{ errors, classes }"
        :name="formTranslations.emailLabel"
        rules="required|email"
        slim
      >
        <input
          id="order-emal"
          v-model="model.email"
          :class="classes"
          :placeholder="formTranslations.emailLabel"
          type="text"
        >
        <span class="form-message--error">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="form-row form-row--choice form-row--checkbox">
      <ValidationProvider
        v-slot="{ errors, classes }"
        :name="'Terms'"
        :rules="{ required: { allowFalse: false } }"
        slim
      >
        <span
          :class="classes"
          class="form-row--choice__item label"
        >
          <input
            id="form-terms"
            v-model="model.terms"
            type="checkbox"
            class="form-checkbox"
          >
          <label
            v-if="formTranslations.termsText"
            for="form-terms"
            class="form-choice__label"
            v-html="formTranslations.termsText"
          />
          <label
            for="form-terms"
            class="form-choice__indicator"
          />
        </span>
        <span class="form-message--error">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div
      v-if="submitState.hasError"
      class="form-row__messages"
    >
      <span
        v-for="(error, index) in errors.list"
        :key="'error' + index"
        class="form-message--error form-message--with-icon"
      >
        <RemoveIcon class="icon" />
        {{ error }}
      </span>
    </div>

    <hr>

    <div class="form-row">
      <span class="base-font--sm">{{ formTranslations.deliveryText }}</span><br>
      <strong class="h5">{{ summaryText }}</strong>
    </div>

    <button
      type="submit"
      class="cta cta--average"
      v-text="formTranslations.submitButtonText"
    />

    <recaptcha
      ref="recaptchaForm"
      :recaptcha-key="recaptchaKey"
      @submit="submitOrder"
    />
  </ValidationObserver>
</template>

<script>
import {
  reactive, ref, computed, onMounted
} from 'vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate-success'
import {
  required, email, numeric, digits
} from 'vee-validate-success/dist/rules'
import useOrderMaterials from '@/compositions/orderMaterials/useOrderMaterial'
import api from '@/lib/api'
import Recaptcha from '@/components/recaptcha.vue'
import RemoveIcon from '@/assets/icons/circle-remove.svg'
import { phone } from '@/helpers/VeeValidate'
import GtmHelper from '@/helpers/GtmHelper'

extend('required', required)
extend('email', email)
extend('numeric', numeric)
extend('digits', digits)
extend('phone', phone)

export default {
  components: {
    Recaptcha,
    ValidationObserver,
    ValidationProvider,
    RemoveIcon
  },
  props: {
    recaptchaKey: {
      type: String,
      required: true
    },
    formTranslations: {
      type: Object,
      required: true
    },
    errorText: {
      type: String,
      default: 'Something went wrong.'
    },
    customerName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const cleanFormModel = {
      company: props.customerName,
      name: '',
      address: '',
      postcode: null,
      city: '',
      phone: null,
      email: '',
      terms: false,
      subscribe: false,
      items: []
    }
    const {
      getItems, cartSummary, submitState, updateSubmitState, clearCart
    } = useOrderMaterials()
    const recaptchaForm = ref(null)
    const observer = ref(null)
    const errors = ref({
      list: [props.errorText]
    })
    const model = reactive(cleanFormModel)
    const summaryText = computed(() => {
      const price = cartSummary.value.totalPrice || '0,00'
      return `SUMMA: ${price} kr`
    })

    const submitOrder = (token) => {
      model.items = getItems()
      updateSubmitState({ loading: true })
      api.submitOrderMaterials(model, token).then(() => {
        GtmHelper.materialOrder(cartSummary.value.sum)
        updateSubmitState({
          isSubmitted: true,
          hasError: false,
          submittedPrice: cartSummary.totalPrice
        })
        Object.assign(model, cleanFormModel)
        clearCart()
        requestAnimationFrame(() => {
          observer.value.reset()
        })
      }).catch(({ response }) => {
        if (response.status === 400) {
          // Validation error
          errors.value.list = response.data
        } else {
          errors.value.list = props.errorMessage
        }
        updateSubmitState({ hasError: true })
      }).then(() => {
        updateSubmitState({ loading: false })
      })
    }

    const onSubmit = async () => {
      const isValid = await observer.value.validate()
      if (!isValid) {
        // ABORT!!
      } else {
        recaptchaForm.value.verify()
      }
    }

    onMounted(() => {
      setTimeout(() => {
        observer.value.reset() // FIX: IE11 invalid field on component init
      }, 0)
    })

    return {
      submitState,
      summaryText,
      observer,
      recaptchaForm,
      model,
      onSubmit,
      submitOrder,
      errors
    }
  }
}
</script>
