<template>
  <a
    :href="model.url"
    class="recipe-business-card"
    @click="itemClick"
  >
    <div class="recipe-business-card__image">
      <img
        v-if="model.image"
        ref="img"
        :src="model.image"
        :alt="model.name"
      >
      <div
        v-else
        class="recipe-business-card__image-placeholder"
      >
        <div class="recipe-business-card__image-placeholder__icon" />
        <span v-if="model.imagePlaceholderText">{{ model.imagePlaceholderText }}</span>
      </div>
    </div>

    <div class="recipe-business-card__inner">
      <div class="recipe-business-card__body">
        <strong class="recipe-business-card__title">{{ model.name }}</strong>
        <table class="recipe-business-card__table">
          <tbody>
            <tr>
              <th>
                <span>{{ translations.rawMaterialCostText }}</span>
              </th>
              <td class="u-text-nowrap">{{ model.rawMaterialCost }}</td>
            </tr>
            <tr>
              <th class="u-width-70">
                <span>{{ translations.priceExclVatText }}</span>
              </th>
              <td class="u-text-nowrap">{{ model.priceExclVat }}</td>
            </tr>
            <tr>
              <th class="u-width-70">
                <span>{{ translations.profitText }}</span>
              </th>
              <td class="u-text-nowrap">{{ model.profit }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      class="recipe-business-card__bottom"
    >
      {{ model.profitPerYear }}./år
    </div>
  </a>
</template>

<script>
import gtmHelper from '@/helpers/GtmHelper'
import epiTracking from '@/helpers/epi-tracking'

const translations = window.arla.cms.resources.businessCases
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    imagePlaceholderText: {
      type: String,
      default: ''
    },
    listName: {
      type: String,
      required: true
    },
    queryTrackId: {
      type: String,
      default: ''
    },
    queryTrackType: {
      type: String,
      default: ''
    }
  },
  computed: {
    trackData() {
      return {
        list: this.listName,
        ...this.model
      }
    },
    epiTrackData() {
      return {
        queryTrackType: this.queryTrackType,
        queryTrackId: this.queryTrackId,
        hitId: this.model.hitId,
        position: this.position
      }
    },
    translations() {
      return translations
    }
  },
  methods: {
    itemClick() {
      gtmHelper.recipeBusinessCardClicks(this.trackData)
      if (this.queryTrackType && this.queryTrackId) {
        epiTracking.trackCardItemClick(this.epiTrackData)
      }
    }
  }
}
</script>
