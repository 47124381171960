<template>
  <div class="block-slider__item-wrap">
    <div class="block-slider__item">
      <div class="item-text">
        <span
          v-if="model.tagLine"
          class="tag-line"
        >
          {{ model.tagLine }}
        </span>
        <component
          :is="headingTag"
          v-if="model.title"
          class="item-title"
        >
          {{ model.title }}
        </component>
        <div
          v-if="model.text"
          v-html="model.text"
        />
        <a
          v-if="model.linkUrl"
          :href="model.linkUrl"
          :target="model.linkOpenInNewWindow ? '_blank' : '_self'"
          class="block-slider__item-button"
          v-text="model.linkText"
        />
      </div>
      <div class="item-media">
        <generic-video
          v-if="model.videoId"
          :video-id="model.videoId"
          :ambient="false"
          class="item-video"
        />
        <generic-image
          v-else-if="hasImage"
          :small-image-break-point="480"
          :small-image-url="displayImage.mobileImageUrl"
          :large-image-url="displayImage.desktopImageUrl"
          :alt-text="model.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fallbackImage: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    headingTag: {
      type: String,
      required: true
    }
  },
  computed: {
    displayImage() {
      const mobileImageUrl = this.model.image.mobileImageUrl || this.fallbackImage.mobileImageUrl
      const desktopImageUrl = this.model.image.desktopImageUrl || this.fallbackImage.desktopImageUrl

      return {
        mobileImageUrl,
        desktopImageUrl
      }
    },
    hasImage() {
      const { mobileImageUrl, desktopImageUrl } = this.displayImage

      return mobileImageUrl || desktopImageUrl
    }
  }
}
</script>
