import { ref, watch } from 'vue'

export default (key, storageType = sessionStorage) => {
  const storage = storageType.getItem(key)
  const variable = ref(storage ? JSON.parse(storage) : undefined)

  watch(
    () => variable.value,
    (to) => {
      storageType.setItem(key, JSON.stringify(to))
    },
    { deep: true }
  )

  return variable
}
