<template>
  <a
    :href="cardUrl"
    :target="model.linkOpenInNewWindow ? '_blank' : '_self'"
    class="item-card"
    @click="itemClick"
  >
    <div class="item-card__image">
      <img
        v-if="model.image"
        ref="img"
        :src="model.image"
        :alt="model.name"
      >
      <div
        v-else
        class="item-card__image-placeholder"
      >
        <div class="item-card__image-placeholder__icon" />
        <span v-if="model.imagePlaceholderText">{{ model.imagePlaceholderText }}</span>
      </div>

      <span
        v-if="model.isSustainable"
        class="icon u-bg-color--goda-mal"
      >
        <IconSustainable
          style="width: 41px; height: 21px"
        />
      </span>
    </div>

    <div class="item-card__inner">
      <div class="item-card__body">
        <h4 class="item-card__title">{{ model.name }}</h4>
        <div
          v-if="model.description"
          v-html="model.description"
        />
        <slot />
      </div>

      <div
        v-if="model.linkText"
        class="item-card__bottom"
      >
        <span
          class="new-teaser__link"
          v-text="model.linkText"
        />
      </div>
    </div>
  </a>
</template>

<script>
import gtmHelper from '@/helpers/GtmHelper'
import epiTracking from '@/helpers/epi-tracking'
import IconSustainable from '@/assets/icons/goda-mal.svg'

export default {
  components: {
    IconSustainable
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    imagePlaceholderText: {
      type: String,
      default: ''
    },
    listName: {
      type: String,
      required: true
    },
    queryTrackId: {
      type: String,
      default: ''
    },
    queryTrackType: {
      type: String,
      default: ''
    }
  },
  computed: {
    trackData() {
      return {
        list: this.listName,
        ...this.model
      }
    },
    epiTrackData() {
      return {
        queryTrackType: this.queryTrackType,
        queryTrackId: this.queryTrackId,
        hitId: this.model.hitId,
        position: this.position
      }
    },
    cardUrl() {
      return this.model.url.replace('%2B', '+')
    }
  },
  methods: {
    itemClick() {
      gtmHelper.teaserClick({
        title: this.model.name,
        teaserType: 'Default'
      })

      if (this.queryTrackType && this.queryTrackId) {
        epiTracking.trackCardItemClick(this.epiTrackData)
      }
    }
  }
}
</script>
