import { computed, reactive, watch } from 'vue'
import api from '@/lib/api'
import searchQueryState from './search-query'

const searchResultState = reactive({
  loading: false,
  allItemsLoaded: false,
  totalCount: 0,
  hasResults: false,
  items: [],
  termsFacet: [],
  searchResults: {},
  trackId: ''
})

export default {
  useSearchResult() {
    const {
      searchQuery,
      activeCategoriesNames,
      activeCategories,

      incrementPage
    } = searchQueryState.searchQueryUse()
    const state = searchResultState

    async function fetchData() {
      state.items = []
      state.totalCount = ''
      state.allItemsLoaded = false
      state.hasResults = false
      const response = await api.getSearchItems(searchQuery, { skip: 0 })
      state.searchResults = response.data.searchResults

      const activeSearchResult = state.searchResults.find((x) => x.type === searchQuery.searchType)
      state.totalCount = activeSearchResult.totalCount
      state.items = activeSearchResult.items
      state.termsFacet = activeSearchResult.termsFacet
      state.trackId = activeSearchResult.trackId
      state.hasResults = state.searchResults.some(({ totalCount }) => totalCount > 0)
    }

    async function fetchExtraItems() {
      const skip = searchQuery.take * (searchQuery.page - 1)
      const { take } = searchQuery

      const searchRequest = {
        skip,
        take,
        terms: searchQuery.terms,
        sorting: searchQuery.sorting
      }
      if (searchQuery.searchType === 'product') {
        searchRequest.activeCategories = activeCategories.value
        const response = await api.getProducts(searchRequest)
        state.items.push(...response.data.items)
      }

      if (searchQuery.searchType === 'recipe') {
        searchRequest.activeCategories = activeCategories.value
        const response = await api.getRecipes(searchRequest)
        state.items.push(...response.data.items)
      }

      if (searchQuery.searchType === 'article') {
        searchRequest.activeCategories = activeCategoriesNames.value
        const response = await api.getArticles(searchRequest)
        state.items.push(...response.data.items)
      }

      if (searchQuery.searchType === 'ordermaterial') {
        searchRequest.activeCategories = activeCategoriesNames.value
        const response = await api.getOrderMaterials(searchRequest)
        state.items.push(...response.data.items)
      }

      if (searchQuery.searchType === 'businesscase') {
        searchRequest.activeCategories = activeCategoriesNames.value
        const response = await api.getBusinessCases(searchRequest)
        state.items.push(...response.data.items)
      }

      if (skip + take >= state.totalCount) {
        state.allItemsLoaded = true
      }
    }

    async function search() {
      if (!state.loading) {
        state.loading = true
        fetchData().then(() => {
          state.loading = false
        })
      }
    }

    async function searchMore() {
      if (state.totalCount === state.items.length || state.allItemsLoaded) {
        return
      }

      if (!state.loading) {
        incrementPage()
        state.loading = true
        fetchExtraItems().then(() => {
          state.loading = false
        })
      }
    }

    watch(
      () => [searchQuery.updated],
      () => {
        if (!state.loading && searchQuery.searchType) {
          search()
        }
      },
      {
        immediate: true // Not lazy anymore
      }
    )

    return {
      allItemsLoaded: computed(() => state.allItemsLoaded),
      loading: computed(() => state.loading),
      searchResults: computed(() => state.searchResults),
      totalCount: computed(() => state.totalCount),
      items: computed(() => state.items),
      termsFacet: computed(() => state.termsFacet),
      trackId: computed(() => state.trackId),
      hasResults: computed(() => state.hasResults),
      search,
      searchMore
    }
  }
}
