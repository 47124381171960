<template>
  <div>
    <modal
      :name="modalName"
      :adaptive="true"
      :max-width="440"
      height="auto"
      width="100%"
      scrollable
      reset
      class="v--modal info-popup"
      :class="themeCssClass"
      @before-open="beforeOpenModal(null, ['enable-body-scroll'])"
      @before-close="beforeCloseModal(null, ['enable-body-scroll'])"
    >
      <InfoPopupSurvey
        v-if="isSurvey"
        :model="contentModel"
      />
      <InfoPopupForm
        v-else
        :model="contentModel"
        @ctaClick="onCtaClick"
      />
      <span
        role="button"
        :aria-label="closeLabel"
        class="popup-content__button--close"
        @click="onClosePopup"
      />
    </modal>
  </div>
</template>

<script>
import { computed, getCurrentInstance, onMounted } from 'vue'
import InfoPopupForm from '@/components/infoPopup/InfoPopupForm.vue'
import InfoPopupSurvey from '@/components/infoPopup/InfoPopupSurvey.vue'
import useInfoPopup from '@/compositions/useInfoPopup'
import useModal from '@/compositions/useModal'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    InfoPopupForm,
    InfoPopupSurvey
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    modalName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      showPopup,
      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    } = useModal($modal)
    const {
      activationInSeconds,
      activationPercentOfPage
    } = props.model
    const {
      contentModel,
      popupId,
      hem,

      initPopup,
      setPopups,
      checkHemInUrl,
      checkDebugMode
    } = useInfoPopup()
    checkHemInUrl()
    setPopups(props.model)
    checkDebugMode()

    const isSurvey = computed(() => contentModel.value.question)
    const trackData = computed(() => {
      const isSignedUp = !!hem.value
      const {
        header: popupTitle,
        typeName: popupType
      } = contentModel.value

      return {
        popupTitle,
        popupType,
        isSignedUp
      }
    })
    const { closeLabel } = window.arla.cms.resources.general

    const openModal = () => {
      showPopup(props.modalName)
      GtmHelper.infoPopupOpen(trackData.value)
    }

    const onClosePopup = () => {
      hidePopup(props.modalName)
      GtmHelper.infoPopupClose(trackData.value)
    }

    const onCtaClick = () => {
      GtmHelper.infoPopupCtaClick(trackData.value)
    }

    const showPopupAfterSomeTime = () => {
      const timer = (new Date()).getTime()
      initPopup()
        .then(() => {
          if (!contentModel.value) {
            return
          }
          const timeDiff = (new Date()).getTime() - timer
          setTimeout(() => {
            openModal()
          }, Math.max(activationInSeconds * 1000 - timeDiff), 0)
        })
    }

    const showPopupAfterScrolling = () => {
      const { scrollHeight } = document.documentElement

      const threshold = scrollHeight * (activationPercentOfPage / 100)
      const onScroll = () => {
        const scrollTop = window.pageYOffset

        if (scrollTop > threshold) {
          window.removeEventListener('scroll', onScroll)
          initPopup()
            .then(() => {
              if (!contentModel.value) {
                return
              }
              openModal()
            })
        }
      }
      window.addEventListener('scroll', onScroll)
    }

    if (popupId.value) {
      onMounted(() => {
        if (activationInSeconds) {
          showPopupAfterSomeTime()
        } else if (activationPercentOfPage) {
          showPopupAfterScrolling()
        }
      })
    }

    const themeCssClass = computed(() => (contentModel.value
      ? `u-theme--${contentModel.value.theme || 'gray'}` : ''))

    return {
      contentModel,
      themeCssClass,
      isSurvey,
      closeLabel,

      hidePopup,
      beforeOpenModal,
      beforeCloseModal,
      openModal,
      onClosePopup,
      onCtaClick
    }
  }
}
</script>
