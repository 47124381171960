<template>
  <modal
    :name="modalName"
    :adaptive="true"
    :max-width="500"
    height="auto"
    width="90%"
    reset
    :click-to-close="false"
    class="v--modal"
    @before-open="beforeOpenModal()"
    @before-close="beforeCloseModal()"
  >
    <div class="popup__inner u-text-align--center">
      <div class="popup-header">
        <strong class="popup-header__text h4 mx-auto">
          {{ currentModel.heading }}
        </strong>
      </div>
      <div
        class="popup-content"
        v-html="currentModel.text"
      />
      <div
        v-if="hasError"
        class="form-message--error"
        v-html="model.errorText"
      />
    </div>
    <div class="popup-panel">
      <button
        class="cta cta--transparent"
        @click="onCancel"
        v-text="currentModel.cancelButtonText"
      />
      <button
        class="cta cta--dark"
        @click="onSubmit"
        v-text="currentModel.confirmButtonText"
      />
    </div>
  </modal>
</template>

<script>
import {
  ref, computed, getCurrentInstance
} from 'vue'
import useModal from '@/compositions/useModal'
import api from '@/lib/api'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    modalName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    } = useModal($modal)
    const step = ref(1)
    const isLoading = ref(false)
    const hasError = ref(false)
    const {
      logoutUrl,
      firstConfirmationModal,
      secondConfirmationModal
    } = props.model
    const currentModel = computed(() => (step.value === 1
      ? firstConfirmationModal : secondConfirmationModal))

    const deleteProfile = async () => {
      isLoading.value = true
      hasError.value = false
      try {
        await api.deleteSUPProfile()
        GtmHelper.trackDeleteAccount()
        window.location = logoutUrl
      } catch (e) {
        hasError.value = true
      }
      isLoading.value = false
    }

    const onSubmit = async () => {
      if (isLoading.value) return
      if (step.value === 1) {
        step.value += 1
      } else {
        await deleteProfile()
        hidePopup(props.modalName)
        step.value = 1
      }
    }

    const onCancel = () => {
      if (isLoading.value) return
      hidePopup(props.modalName)
      step.value = 1
    }

    return {
      currentModel,
      isLoading,
      hasError,

      beforeOpenModal,
      beforeCloseModal,
      onSubmit,
      onCancel
    }
  }
}
</script>
