<template>
  <tr
    v-if="state.name"
    class="order-material-table-item product-table-item"
  >
    <td v-if="!isMobile">
      <div class="item-card__controls">
        <input
          v-model.number="itemAmount"
          v-tooltip="{
            content: tooltipModel.content,
            show: notEnoughInStock,
            autoHide: false,
            placement: 'top-start',
            trigger: 'manual',
            classes : 'error'
          }"
          :class="{ invalid: hasError }"
          min="1"
          type="number"
          class="item-card__amount-field"
          aria-label="Amount of product"
          @change="updateCart"
        >
      </div>
    </td>
    <td class="cell--name">
      <div class="product-table-item__info">
        <span
          :class="{ 'img-placeholder': !state.image}"
          class="product-image"
          v-on="state.image ? { click: orderMaterialClick } : {}"
        >
          <img
            :src="smallImageUrl"
            :alt="state.name"
            class="product-image__thumbnail"
          >
        </span>
        <span class="product-table-item__info__text">
          <span class="product-table-item__title">{{ state.name }}</span>
          <span
            v-if="state.description"
            class="product-table-item__subtitle"
          >
            {{ state.description }}
          </span>
        </span>
      </div>

      <image-modal
        v-if="state.image"
        :name="state.name"
        :image="largeImageUrl"
        :modal-name="model.id"
      />

      <div
        v-if="isMobile"
        class="item-card__controls item-card__controls--mobile"
      >
        <input
          v-model.number="state.amount"
          v-tooltip="{
            content: tooltipModel.content,
            autoHide: false,
            show: notEnoughInStock,
            placement: 'top-start',
            trigger: 'manual',
            classes : 'error'
          }"
          :class="{ invalid: hasError }"
          min="1"
          type="number"
          class="item-card__amount-field"
          aria-label="Amount of product"
          @change="updateCart"
        >
        <span class="item-card__units">{{ 'SP' }}</span>
        <span>{{ price }}</span>
        <button
          class="cta--remove"
          @click.prevent="onRemoveFromCart"
        >
          <BinIcon class="icon" />
          <span class="u-hide-on-screenonly">Ta bort sak</span>
        </button>
      </div>
    </td>
    <td v-if="!isMobile">
      <span>{{ price }}</span>
    </td>
    <td v-if="!isMobile">
      <button
        class="cta--remove"
        @click.prevent="onRemoveFromCart"
      >
        <BinIcon class="icon" />
        <span class="u-hide-on-screenonly">Ta bort sak</span>
      </button>
    </td>
  </tr>
</template>

<script>
import {
  ref, reactive, computed, watch, getCurrentInstance
} from 'vue'

import BinIcon from '@/assets/icons/bin.svg'
import ImageModal from '@/components/orderMaterials/order-material-image-modal.vue'
import useOrderMaterial from '@/compositions/orderMaterials/useOrderMaterial'
import useModal from '@/compositions/useModal'
import useImage from '@/compositions/useImage'
import epiTracking from '@/helpers/epi-tracking'
import gtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    ImageModal,
    BinIcon
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    queryTrackId: {
      type: String,
      default: ''
    },
    queryTrackType: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const translations = window.arla.cms.resources.orderMaterials
    const { getImageUrl } = useImage()
    const {
      itemsData, cartSummary, getAmountAddedToCart, addToCart, removeFromCart
    } = useOrderMaterial()
    const {
      queryTrackType,
      queryTrackId,
      position
    } = props
    const state = reactive({
      ...itemsData.find(({ id }) => props.model.id === id),
      amount: getAmountAddedToCart(props.model.id),
      position
    })
    const smallImageUrl = computed(() => getImageUrl(state.image
      || translations.fallbackImage, 300))
    const largeImageUrl = computed(() => getImageUrl(state.image
      || translations.fallbackImage, 600))
    let prevAmount = state.amount
    const price = computed(() => (props.model.price ? `${state.price} kr` : translations.card.freePrice))
    const itemAmount = computed({
      get: () => state.amount,
      set: (newValue) => {
        if (newValue > 0) {
          state.amount = newValue
        }
      }
    })

    const hasError = ref(false)
    const notEnoughInStock = ref(false)
    const notEnoughInStockText = translations.moreThanInStockTooltip.content.replace('{count}', `<strong>${state.sellableNumberOfItems}</strong>`)
    const tooltipModel = computed(() => ({
      content: notEnoughInStockText
    }))
    const updateCart = () => {
      const cartItemIsDeleted = state.amount === 0
      hasError.value = false
      notEnoughInStock.value = false

      if (cartItemIsDeleted) {
        removeFromCart(state)
        gtmHelper.materialCheckoutChangeAmount(state.name, prevAmount)
      } else if (state.amount > state.sellableNumberOfItems) {
        hasError.value = true
        notEnoughInStock.value = true

        setTimeout(() => {
          hasError.value = false
          notEnoughInStock.value = false
        }, 4000)
      } else {
        const quantity = state.amount - prevAmount
        gtmHelper.materialCheckoutChangeAmount(state.name, Math.abs(quantity))

        addToCart(state)
        prevAmount = state.amount
      }
    }

    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const { showPopup } = useModal($modal)
    const epiTrackingData = reactive({
      queryTrackType, queryTrackId, position, hitId: state.hitId
    })
    const orderMaterialClick = () => {
      showPopup(props.model.id)
      epiTracking.trackCardItemClick(epiTrackingData)
    }

    const onRemoveFromCart = () => {
      removeFromCart(state)
      gtmHelper.materialRemoveFromCart(state.name, prevAmount)
    }

    const mediaQueryString = '(max-width: 768px)'
    const isMobile = ref(window.matchMedia(mediaQueryString).matches)
    window.addEventListener('resize', () => {
      console.log(window.matchMedia(mediaQueryString).matches)
      isMobile.value = window.matchMedia(mediaQueryString).matches
    }, false)

    watch(
      () => cartSummary.value,
      () => {
        state.amount = getAmountAddedToCart(props.model.id)
      }
    )

    return {
      state,
      itemAmount,
      price,
      smallImageUrl,
      largeImageUrl,
      tooltipModel,
      hasError,
      notEnoughInStock,
      isMobile,

      orderMaterialClick,
      updateCart,
      onRemoveFromCart
    }
  }
}
</script>
