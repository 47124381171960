<template>
  <div
    v-if="viewOptions.length > 1"
    class="card-list__view-selector"
  >
    <a
      v-for="viewOption in viewOptions"
      :key="viewOption.value"
      :class="{ active: viewOption.value === view }"
      :title="viewOption.label"
      class="card-list__view-selector-link"
      @click="setView(viewOption.value)"
    >
      <commponent
        :is="`icon-${viewOption.value}`"
        class="icon"
      />
    </a>
  </div>
</template>

<script>
import { computed } from 'vue'

import IconGrid from '@/assets/icons/view--grid.svg'
import IconList from '@/assets/icons/view--list.svg'

import searchQueryState from '@/compositions/search/search-query'
import searchTabsConfig from '@/compositions/search/search-tabs-configuration'

export default {
  components: {
    IconGrid,
    IconList
  },
  setup() {
    const { getActiveConfig } = searchTabsConfig.tabsConfigurationUse()
    const { searchQuery, setView } = searchQueryState.searchQueryUse()

    const view = computed(() => {
      const activeConfig = getActiveConfig()
      if (searchQuery.view) {
        return searchQuery.view
      }

      return activeConfig.viewOptions.find((x) => x.default).value
    })
    return {
      view,
      viewOptions: computed(() => getActiveConfig().viewOptions),

      setView
    }
  }
}
</script>
