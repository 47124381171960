<template>
  <div class="order-material-card product-card">
    <div
      :class="{ 'img-placeholder': !model.image}"
      class="product-card__image"
      v-on="model.image ? { click: orderMaterialClick } : {}"
    >
      <img
        v-if="smallImageUrl"
        ref="img"
        :src="smallImageUrl"
        :alt="model.name"
      >
    </div>

    <div class="product-card__text">
      <strong class="product-card__title">{{ model.name }}</strong>
      <span class="product-card__subtitle">{{ model.description }}</span>
    </div>

    <div class="product-card__bottom">
      <div class="item-card__prices">
        <span class="item-card__prices-title"> {{ translations.card.priceTitle }}</span>
        <span class="item-card__price">{{ price }}</span>
        <Tooltip
          v-if="outOfStock"
          :model="tooltipModel.outOfStock"
        />
      </div>

      <div class="item-card__controls">
        <input
          v-model.number="state.amount"
          v-tooltip="{
            content: tooltipModel.notEnoughInStock.content,
            show: notEnoughInStock,
            autoHide: false,
            placement: 'top-start',
            trigger: 'manual',
            classes : 'error'
          }"
          :class="{ invalid: hasError }"
          :disabled="outOfStock"
          min="0"
          type="number"
          class="item-card__amount-field"
          aria-label="Amount of product"
          @focus="hasError = false"
          @keyup.enter="updateCart"
        >
        <span class="item-card__units">{{ translations.card.priceUnit }}</span>
        <button
          :disabled="outOfStock"
          class="item-card__cta"
          @click.prevent="updateCart"
        >
          {{ translations.card.addToBasket }}
        </button>
      </div>
    </div>

    <image-modal
      v-if="model.image"
      :name="model.name"
      :image="largeImageUrl"
      :modal-name="model.id"
    />
  </div>
</template>

<script>
import BaseItem from '@/components/orderMaterials/order-material-item.vue'

export default {
  extends: BaseItem
}
</script>
