<template>
  <div class="c-recipe__nutritions">
    <h4 class="c-recipe__nutritions__title">
      {{ nutritionIntroText }} {{ nutrition.specifiedPerText }}
    </h4>
    <donut
      v-if="chartSegments.length"
      :segments="chartSegments"
      :ring-color="'#e8e8e8'"
      class="c-recipe__nutritions__chart"
    />
    <div class="c-recipe__nutritions-info">
      <ul class="c-recipe__nutritions-info__content">
        <li class="c-recipe__nutritions-info__item fat">
          <span>{{ fatText }}</span>
          <strong>{{ nutrition.fat | localizeNumberOrEmpty }} {{ gramText }}</strong>
        </li>
        <li class="c-recipe__nutritions-info__item carbohydrate">
          <span>{{ carbohydrateText }}</span>
          <strong>{{ nutrition.carbohydrate | localizeNumberOrEmpty }} {{ gramText }}</strong>
        </li>
        <li class="c-recipe__nutritions-info__item protein">
          <span>{{ proteinText }}</span>
          <strong>{{ nutrition.protein | localizeNumberOrEmpty }} {{ gramText }}</strong>
        </li>
      </ul>
      <ul class="c-recipe__nutritions-info__energy">
        <li class="c-recipe__nutritions-info__item">
          <span>{{ energyKjText }}</span>
          <strong>{{ nutrition.energy | localizeNumberOrEmpty }} {{ kjText }}</strong>
        </li>
        <li class="c-recipe__nutritions-info__item">
          <span>{{ energyKcalText }}</span>
          <strong>{{ nutrition.energyKcal | localizeNumberOrEmpty }} {{ kcalText }}</strong>
        </li>
      </ul>
    </div>
    <p
      v-if="nutritionText"
      class="c-recipe__nutritions__text"
    >
      {{ nutritionText }}
    </p>
  </div>
</template>

<script>
import Donut from '@/components/donut.vue'

const translations = window.arla.cms.resources.recipes.recipePage

export default {
  components: {
    Donut
  },
  filters: {
    localizeNumberOrEmpty: (value) => {
      if (!Number(value)) return '-'
      return Number(value.toFixed(2)).toLocaleString('sv')
    }
  },
  props: {
    nutrition: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      nutritionIntroText: translations.nutritionIntroText,
      nutritionText: translations.nutritionText,
      fatText: translations.nutritionFat,
      carbohydrateText: translations.nutritionCarbohydrate,
      proteinText: translations.nutritionProtein,
      gramText: translations.nutritionGram,
      energyKjText: translations.nutritionEnergyKj,
      energyKcalText: translations.nutritionEnergyKcal,
      kcalText: translations.nutritionKcal,
      kjText: translations.nutritionKj
    }
  },
  computed: {
    chartSegments() {
      const segments = []
      if (this.nutrition.fat) {
        segments.push({
          name: 'fat',
          value: parseInt(this.nutrition.fat, 10),
          color: '#7C5E78'
        })
      }
      if (this.nutrition.protein) {
        segments.push({
          name: 'protein',
          value: parseInt(this.nutrition.protein, 10),
          color: '#B4CBC2'
        })
      }
      if (this.nutrition.carbohydrate) {
        segments.push({
          name: 'carbohydrate',
          value: parseInt(this.nutrition.carbohydrate, 10),
          color: '#DCB596'
        })
      }
      return segments
    }
  }
}
</script>
