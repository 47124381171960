<template>
  <div
    body-scroll-lock-ignore
    class="c-main-menu__dropdown"
  >
    <div class="c-main-menu__dropdown-inner">
      <div
        v-for="(child, i) in section.children"
        :key="i"
        class="c-main-menu__dropdown-section"
      >
        <a
          v-if="child.url"
          :href="child.url"
          :class="{
            'is-active': child.isActive
          }"
          class="c-main-menu__section-title"
          @click="trackTitleClick(child.title)"
        >
          {{ child.title }}
        </a>
        <span
          v-else
          class="c-main-menu__section-title"
        >
          {{ child.title }}
        </span>
        <ul
          class="c-main-menu__dropdown-list u-bare-list"
          :class="{
            'no-heading': !child.title && anyTitle
          }"
        >
          <li
            v-for="(subChild, j) in child.children"
            :key="j"
          >
            <a
              class="c-main-menu__dropdown-link"
              :href="subChild.url"
              :class="{ 'is-active': subChild.isActive }"
              v-bind="subChild.attributes"
              @click="trackMenuClick(subChild.title, subChild.isUnderlined)"
            >
              <span :class="{ 'u-text-underline': subChild.isUnderlined }">
                {{ subChild.title }}
              </span>
              <IconArrow
                v-if="subChild.isUnderlined"
                class="icon"
              />
              <span
                v-if="subChild.isNew"
                class="c-label--new"
              >
                Ny
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div
        v-if="section.featureItems && section.featureItems.lenght > 0"
        class="c-main-menu__dropdown--featured-section"
      >
        <a
          v-for="(featureItem, k) in section.featureItems"
          :key="k"
          :href="featureItem.url"
          :target="featureItem.openInNewWindow ? '_blank' : '_self'"
          class="c-main-menu__featured-item"
          @click="trackFeaturedItemClick(featureItem.text)"
        >
          <img
            :src="featureItem.imageUrl"
            width="243"
            height="151"
            alt="image"
          >
          <strong> {{ featureItem.text }}</strong>
          <span>{{ featureItem.linkText }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import GtmHelper from '@/helpers/GtmHelper'
import IconArrow from '@/assets/icons/arrow-right.svg'

export default {
  components: {
    IconArrow
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    anyTitle() {
      return this.section.children.some((s) => s.title)
    }
  },
  methods: {
    trackTitleClick(title) {
      GtmHelper.mainMenuSectionTitleClick(title)
    },
    trackMenuClick(title, isSeeMore) {
      if (isSeeMore) {
        GtmHelper.mainMenuDropdownSeeMoreTextClick(title)
      } else {
        GtmHelper.mainMenuDropdownLinkClick(title)
      }
    },
    trackFeaturedItemClick(title) {
      GtmHelper.mainMenuDropdownVisualCTATextClick(title)
    }
  }
}

</script>
