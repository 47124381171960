<template>
  <div
    :class="{ 'is-loading': submitState.loading }"
    class="order-material-checkout"
  >
    <template v-if="!submitState.isSubmitted">
      <h1
        v-if="title"
        class="items-overview__title"
      >
        {{ title }}
      </h1>

      <checkout-form
        :recaptcha-key="recaptchaKey"
        :form-translations="formTranslations"
        :error-text="confirmation.errorText"
        :customer-name="customerName"
      />

      <aside>
        <cart :cart-title="cartTranslations.cartTitle" />

        <div class="u-flex u-flex-justify-end">
          <a
            :href="translations.url"
            class="link--dark u-link--underline base-font--sm"
            @click="backToAllMaterials"
          >
            <strong>{{ cartTranslations.cartLinkText }}</strong>
          </a>
        </div>
      </aside>
    </template>

    <div
      v-else
      class="success-content"
    >
      <BoxIcon class="icon" />
      <div
        class="success-content__text"
        v-html="confirmation.confirmationText"
      />
      <hr>
      <div class="summary">
        <span class="sub-font"> {{ translations.basketBanner.summa }}:</span>
        <h4>{{ summaryText }}</h4>
      </div>

      <div>
        <a
          :href="confirmation.backButtonLink"
          class="cta cta--average"
          @click="confirmGoToOrderButtonClick"
          v-text="confirmation.backButtonText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import BoxIcon from '@/assets/icons/order.svg'
import CheckoutForm from '@/components/orderMaterials/order-material-checkout-form.vue'
import Cart from '@/components/orderMaterials/order-material-cart.vue'
import useOrderMaterials from '@/compositions/orderMaterials/useOrderMaterial'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    CheckoutForm,
    Cart,
    BoxIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    recaptchaKey: {
      type: String,
      required: true
    },
    customerName: {
      type: String,
      required: true
    },
    formTranslations: {
      type: Object,
      required: true
    },
    cartTranslations: {
      type: Object,
      required: true
    },
    confirmation: {
      type: Object,
      required: true
    }
  },
  setup() {
    const translations = window.arla.cms.resources.orderMaterials
    const { submitState } = useOrderMaterials()
    const summaryText = computed(() => {
      const price = submitState.submittedPrice || '0,00'
      return `${price} kr`
    })

    const backToAllMaterials = () => {
      GtmHelper.backToAllMaterials()
    }

    const confirmGoToOrderButtonClick = () => {
      GtmHelper.confirmGoToOrderButton()
    }

    return {
      summaryText,
      submitState,
      translations,
      backToAllMaterials,
      confirmGoToOrderButtonClick
    }
  }
}
</script>
