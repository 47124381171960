export default () => {
  const notification = document.querySelector('.c-notification')
  if (!notification) {
    return
  }
  const delay = 6000
  const cssClass = 'hidden'

  const url = new URL(window.location.href)
  const showNotification = !!url.searchParams.get('message')

  if (showNotification) {
    url.searchParams.delete('message')
    window.history.pushState(null, '', url.toString())
  }
  const show = () => {
    notification.classList.remove(cssClass)
  }

  const hide = () => {
    notification.classList.add(cssClass)
  }

  if (showNotification) {
    show()
    setTimeout(hide, delay)
  }
}
