export default {
  routes: {
    productsList: 'productsList'
  },
  apiUrls: {
    serviceorder: '/api/materialorder/',
    login: '/api/authentication/authenticateuser/',
    user: {
      data: '/api/user/data',
      packageInfo: '/api/user/packageinfo',
      favorites: {
        products: '/api/user/favorites/products',
        recipes: '/api/user/favorites/recipes',
        businessCases: '/api/user/favorites/businesscases',
        catalogues: '/api/user/favorites/catalogues'
      }
    },

    cart: {
      cart: '/api/cart/',
      cartGroups: '/api/cart/cartitemgroups/',
      cartOrder: '/api/cart/cartorder/',
      basicCartOrders: '/api/cart/basiccartorders/',
      quickEntry: '/api/cart/quickentry/',
      add: '/api/cart/addItems'
    },
    myAssortment: {
      myAssortment: '/api/myassortment/',
      save: 'save/',
      updateFromStatistics: '/api/myassortment/updatefromstatistics/'
    },
    assortment: {
      code: '/api/assortment/code/',
      assortment: '/api/assortment/',
      assortmentGroups: '/api/assortment/groups/'
    },
    search: {
      searchitems: '/api/search/searchitems',
      recipes: '/api/search/recipes',
      recipeCategories: '/api/search/recipecategories',
      products: '/api/search/products',
      articles: '/api/search/articles',
      orderMaterials: '/api/search/ordermaterials',
      orderMaterialsByIds: '/api/search/getordermaterialsbyids',
      businessCases: '/api/search/businesscases',
      trackCardItemClick: '/api/search/trackcarditemclick',
      trackAutoSuggestionClick: '/api/search/trackautosuggestionclick',
      autoSuggestions: '/api/search/autosuggestion'
    },
    orderMaterials: {
      submitOrder: '/api/ordermaterials/submit'
    },
    general: {
      productsBottomBanner: '/api/general/productsbottombanner'
    },
    businesscases: {
      businesscases: '/api/businesscases'
    },
    Header: {
      CmsNavigation: '/api/header/cmsnavigation',
      ConnectSelectStore: '/api/connect/selectstore'
    },
    order: {
      listByDate: '/api/order/listbydate/',
      itemGroups: '/api/order/itemgroups/'
    },
    deliveryPlan: {
      listByWeek: '/api/deliveryplan/listbyweek/',
      orderLinks: '/api/deliveryplan/orderlinks/'
    },
    profile: {
      markRead: '/api/message/'
    },
    inspirationBlockData: '/api/home/inspirationblockdata/',
    catalogue: '/api/catalogue/',
    favorites: {
      items: '/api/favorites/items',
      migrate: '/api/favorites/migrate'
    },
    mySettings: {
      deleteProfile: '/api/mysettings/profile'
    }
  }
}
