<template>
  <div class="catalogue-card">
    <a
      :href="model.url"
      class="catalogue-card-link"
      target="_blank"
      @click="itemClick"
    >
      <div class="catalogue-card__image">
        <img
          v-if="model.image"
          :src="model.image"
          :alt="model.name"
        >
      </div>
      <div class="catalogue-card__text">
        <span class="catalogue-card__kicker">{{ model.date }}</span>
        <strong class="catalogue-card__title">{{ model.name }}</strong>
      </div>
    </a>

    <div class="catalogue-card__controls">
      <a
        v-if="model.editUrl"
        :href="model.editUrl"
        class="catalogue-card__controls-item"
        @click="onEditClick"
      >
        <IconEdit class="icon" />
        <span class="visuallyhidden">{{ editLabel }}</span>
      </a>

      <span
        class="catalogue-card__controls-item"
        @click.prevent="onDeleteClick"
      >
        <IconBin class="icon" />
        <span class="visuallyhidden">{{ deleteLabel }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import IconBin from '@/assets/icons/bin.svg'
import IconEdit from '@/assets/icons/pencil.svg'
import GtmHelper from '@/helpers/GtmHelper'
import useModal from '@/compositions/useModal'
import useCatalogue from '@/compositions/useCatalogue'
import { getCurrentInstance } from 'vue'

export default {
  components: {
    IconBin,
    IconEdit
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    listName: {
      type: String,
      required: true
    }
  },
  setup({
    model,
    position,
    listName
  }) {
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      catalogueDeleteModalName,

      setCatalogueToDelete
    } = useCatalogue()
    const trackData = {
      position,
      list: listName,
      ...model
    }
    const {
      showPopup
    } = useModal($modal)
    const {
      deleteLabel,
      editLabel
    } = window.arla.cms.resources.general

    const onDeleteClick = () => {
      setCatalogueToDelete(model)
      showPopup(catalogueDeleteModalName)
    }

    const onEditClick = () => {
      GtmHelper.catalogueEditClick(model.id)
    }

    const itemClick = () => {
      GtmHelper.catalogueClicks(trackData)
    }

    return {
      deleteLabel,
      editLabel,

      itemClick,
      onEditClick,
      onDeleteClick
    }
  }
}
</script>
