<template>
  <div
    class="catalogue-checkbox form-row--choice form-row--checkbox"
    @click.stop
  >
    <label class="item-filter__list-option form-row--choice__item">
      <input
        :checked="isChecked({ id, type })"
        type="checkbox"
        class="form-checkbox"
        @change="onChange"
      >
      <span class="form-choice__label">{{ catalogueTranslations.addToCatalogueLabel }}</span>
      <span class="form-choice__indicator" />
    </label>
  </div>
</template>

<script>
import useCatalogue from '@/compositions/useCatalogue'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      addToCatalogue,
      removeFromCatalogue,
      isChecked,
      catalogueTranslations
    } = useCatalogue()

    const itemData = props
    const onChange = (event) => {
      if (event.target.checked) {
        addToCatalogue(itemData)
      } else {
        removeFromCatalogue(itemData)
      }
    }

    return {
      catalogueTranslations,

      isChecked,
      onChange
    }
  }
}
</script>
