import { reactive, toRefs } from 'vue'

const state = reactive({
  fixed: false,
  pinned: false,
  lastPosition: 0
})
export default () => {
  const handleScroll = (element) => {
    let scrolledUp
    const headerHeight = element.clientHeight
    const headerRect = element.getBoundingClientRect()

    state.fixed = headerRect.top < 0

    if (state.lastPosition < window.scrollY) {
      scrolledUp = false
    }

    if (state.lastPosition > window.scrollY) {
      scrolledUp = true
    }

    state.pinned = scrolledUp || headerHeight + headerRect.top > 0

    state.lastPosition = window.scrollY
  }

  const setPinned = (value) => {
    state.pinned = value
  }

  return {
    ...toRefs(state),

    handleScroll,
    setPinned
  }
}
