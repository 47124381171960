import searchQueryState from './search-query'
import searchTabsConfig from './search-tabs-configuration'

export default {
  useRouterHelper(routerParam, baseUrl) {
    const {
      getActiveConfig,
      getConfigBySearchType,
      hasSearchTabs
    } = searchTabsConfig.tabsConfigurationUse()

    const {
      searchQuery,
      activeCategoriesNames
    } = searchQueryState.searchQueryUse()
    const router = routerParam

    function updateRoute() {
      const query = {}

      const urlParams = new URLSearchParams(window.location.search)
      const catalogueId = urlParams.get('id')
      if (catalogueId) {
        query.id = catalogueId
      }

      const config = getActiveConfig()
      const filterParams = config.categories.flatMap((a) => a.categories)
        .filter((x) => activeCategoriesNames.value.includes(x.name))
        .map((category) => category.slug).filter((x) => x)
        .sort()
      if (hasSearchTabs.value) {
        filterParams.splice(0, 0, searchQuery.searchType)
      }

      const path = baseUrl.concat(filterParams.join('+'))

      const termsUrl = searchQuery.terms.sort().join('+')
      if (termsUrl !== '') {
        query.query = termsUrl
      }

      const defaultSorting = config.sortingOptions.find((x) => x.default).value
      if (searchQuery.sorting && searchQuery.sorting !== defaultSorting) {
        query.sorting = searchQuery.sorting
      }

      if (config.viewOptions && config.viewOptions.length > 1) {
        const defaultView = config.viewOptions.find((x) => x.default).value
        if (searchQuery.view && searchQuery.view !== defaultView) {
          query.sorting = searchQuery.sorting
        }
      }

      router.push({
        path,
        query
      }).catch(() => {})
    }

    function getRouteData() {
      let href = router.currentRoute.path
        .substring(router.currentRoute.path.lastIndexOf(baseUrl) + baseUrl.length)
      if (href.endsWith('/')) {
        href = href.substring(0, href.length - 1)
      }
      const filters = href.substring(href.lastIndexOf('/') + 1)
      const activeFiltersSlugs = (filters || '').split('+').filter((t) => t)

      if (hasSearchTabs.value) {
        if (activeFiltersSlugs.length === 0) {
          activeFiltersSlugs.push('product')
        }
      }
      let config
      let searchType
      if (activeFiltersSlugs.length === 0 || !hasSearchTabs.value) {
        config = getActiveConfig()
        searchType = config.type
      } else {
        [searchType] = activeFiltersSlugs
        config = getConfigBySearchType(searchType)
      }
      const activeCategories = config.categories.flatMap((a) => a.categories)
        .filter((x) => activeFiltersSlugs.includes(x.slug))

      const terms = (router.currentRoute.query.query || '').split('+').filter((t) => t)
      const sorting = router.currentRoute.query.sorting
        || config.sortingOptions.find((x) => x.default).value
      const view = router.currentRoute.query.view || null
      const catalogueId = router.currentRoute.query.id || null

      return {
        activeCategories,
        searchType,
        terms,
        sorting,
        view,
        catalogueId
      }
    }

    return {
      getRouteData,
      updateRoute
    }
  }
}
