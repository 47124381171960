<template>
  <div
    v-if="category.categories && category.categories.length"
    :class="{ 'expanded' : isExpanded || isAnimating }"
    class="item-filter"
  >
    <h3
      class="item-filter__title"
      @click="toggle"
    >
      {{ category.name }}
      <ArrowIcon class="icon" />
    </h3>

    <div
      ref="content"
      :style="showStyles ? contentStyle : ''"
      class="item-filter__list-wrap"
    >
      <ul class="item-filter__list">
        <li
          v-for="childCategory in categoriesFiltered"
          :key="childCategory.slug"
          :class="{ disabled: !childCategory.hasProducts && !categoryIsChecked(childCategory) }"
          class="item-filter__list-item form-row form-row--choice form-row--checkbox"
        >
          <label class="item-filter__list-option form-row--choice__item">
            <input
              :id="childCategory.slug"
              :checked="categoryIsChecked(childCategory.name)"
              :value="childCategory.name"
              type="checkbox"
              class="form-checkbox"
              @change="changeFilter($event.target.checked, childCategory)"
            >
            <span class="form-choice__label">
              <img
                v-if="childCategory.icon"
                :src="childCategory.icon"
                :alt="childCategory.name"
                width="40"
                height="20px"
              >
              <template v-else>
                {{ getCategoryDisplayName(childCategory.name) }}
              </template>
              ({{ childCategory.productsCount }})
            </span>
            <span class="form-choice__indicator" />
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import ArrowIcon from '@/assets/icons/arrow_down--no-color.svg'

import searchRepository from '@/compositions/search/search-repository'
import searchQueryState from '@/compositions/search/search-query'
import expandCollapse from '@/mixins/expand-collapse'

export default {
  components: { ArrowIcon },
  mixins: [
    expandCollapse
  ],
  props: {
    category: {
      type: Object,
      required: true
    },
    minHeight: {
      type: Number,
      default: 0
    },
    isExpandedByDefault: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const {
      activeCategoriesNames,

      addCategory,
      removeCategory
    } = searchQueryState.searchQueryUse()
    const { termsFacet } = searchRepository.useSearchResult()

    function categoryIsChecked(categoryName) {
      return activeCategoriesNames.value.length > 0
        && activeCategoriesNames.value.includes(categoryName)
    }

    const categoriesFiltered = computed(() => {
      const groups = []

      props.category.categories.forEach((childCategory) => {
        let filteredTerms = []
        if (termsFacet.value) {
          filteredTerms = termsFacet.value.filter((term) => {
            const { uid, name } = childCategory
            return term.term === (uid || name)
          })
        }
        const newGroup = { ...childCategory }

        if (filteredTerms && filteredTerms.length > 0) {
          newGroup.hasProducts = true
          newGroup.productsCount = filteredTerms[0].count
        } else {
          newGroup.hasProducts = false
          newGroup.productsCount = 0
        }

        groups.push(newGroup)
      })

      return groups
    })

    function changeFilter(checked, category) {
      if (checked) {
        addCategory(category)
      } else {
        removeCategory(category)
      }
    }

    function getCategoryDisplayName(name) {
      const pos = name.lastIndexOf('__')
      if (pos > 0) {
        return name.substring(0, pos)
      }

      return name
    }

    return {
      categoriesFiltered,
      changeFilter,
      categoryIsChecked,
      getCategoryDisplayName
    }
  }
}
</script>
