<template>
  <div>
    <div class="search-overview favorites-overview">
      <h1 class="search-overview__title">
        {{ title }}
      </h1>

      <div class="search-overview__content">
        <favorites-tabs
          v-if="initiated"
        />
        <favorites-items />
      </div>
    </div>

    <favorites-related-items
      v-if="initiated"
      class="card-slider--background"
    />

    <catalogue-delete-modal />
  </div>
</template>

<script>
import { watch } from 'vue'

import FavoritesTabs from '@/components/favorites/favorites-tabs.vue'

import router from '@/compositions/favorites/favorites-router'
import favoritesQueryState from '@/compositions/favorites/favorites-query'
import favoritesTabsConfig from '@/compositions/favorites/favorites-tabs-configuration'
import FavoritesItems from '@/components/favorites/favorites-items.vue'
import FavoritesRelatedItems from '@/components/favorites/favorites-related-items.vue'
import CatalogueDeleteModal from '@/components/catalogue/catalogue-delete-modal.vue'
import routerHelper from '@/compositions/favorites/favorites-router-helper'
import useAuth from '@/compositions/useAuth'
import useFavorites, { getItemIds } from '@/compositions/favorites/favorites-id-repository'
import useCatalogue from '@/compositions/useCatalogue'

export default {
  router,
  components: {
    CatalogueDeleteModal,
    FavoritesTabs,
    FavoritesItems,
    FavoritesRelatedItems
  },
  props: {
    tabsConfiguration: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    translations: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { initiated } = useAuth()
    favoritesTabsConfig.favoritesTabsConfigurationUse(props.tabsConfiguration)

    const { getRouteData, updateRoute } = routerHelper.useRouterHelper(router, props.baseUrl)
    const routeData = getRouteData()
    const { setCatalogueCardTranslations } = useCatalogue()
    setCatalogueCardTranslations(props.translations)

    const onUserData = () => {
      const ids = getItemIds(routeData.favoritesType)
      const { items } = useFavorites()
      if (!ids.length) {
        const tabWithResults = Object.entries(items.value).find(([, entry]) => entry.length > 0)
        if (tabWithResults) {
          [routeData.favoritesType] = tabWithResults
        }
      }
      const { favoritesQuery } = favoritesQueryState.favoritesQueryUse(routeData)

      watch(
        () => [favoritesQuery.updated],
        () => {
          if (favoritesQuery.favoritesType) {
            updateRoute()
          }
        }
      )
    }

    watch(
      () => initiated.value,
      () => {
        setTimeout(onUserData, 0)
      }
    )

    return {
      initiated
    }
  }
}
</script>
