<template>
  <div
    ref="slider"
    class="c-card-slider"
    :class="cssClass"
  >
    <component
      :is="headingTag || 'strong'"
      v-if="heading"
      class="card-slider__heading"
    >
      {{ heading }}
    </component>

    <div class="card-slider__carousel-container">
      <div
        v-if="isSingle"
        class="card-slider__item"
      >
        <component
          :is="card[items[0].cardType || cardType]"
          :model="itemsWithBusinessData[0]"
          :position="1"
          :query-track-id="queryTrackId"
          :image-placeholder-text="imagePlaceholderText"
          :category="itemsWithBusinessData[0].category"
          :link-text="readMore"
          :cta-type="ctaType"
          :type="cardType"
          :is-wide="true"
          :list-name="listName"
          class="card-slider__item-inner u-bg-color--yellow"
        />
      </div>

      <div
        v-else
        ref="swiperContainer"
        class="swiper"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in itemsWithBusinessData"
            :key="index"
            class="swiper-slide card-slider__item"
          >
            <component
              :is="card[item.cardType || cardType]"
              :model="item"
              :position="index + 1"
              :query-track-id="queryTrackId"
              :image-placeholder-text="imagePlaceholderText"
              :category="item.category"
              :link-text="readMore"
              :cta-type="ctaType"
              :type="cardType"
              :list-name="listName"
              class="card-slider__item-inner"
            />
          </div>
        </div>
        <div
          v-show="seeAllUrl || isSlider"
          class="swiper-control-panel"
        >
          <a
            v-if="seeAllUrl"
            :href="seeAllUrl"
            class="card-slider__see-more-link"
          >
            {{ seeAllTitle }}
          </a>

          <div
            v-show="isSlider"
            class="swiper-controls"
          >
            <div class="swiper-pagination slider__carousel__pagination" />

            <div
              class="swiper-navigation"
            >
              <!--  TODO: consider adding translations  -->
              <button class="swiper-button-prev">
                Prev
              </button>
              <button class="swiper-button-next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, onMounted, computed, onBeforeUnmount
} from 'vue'
import Swiper from 'swiper'
import 'swiper/swiper.css'
import ProductCard from '@/components/products/product-card.vue'
import CatalogueCard from '@/components/catalogue/catalogue-card.vue'
import ItemCard from '@/components/itemsOverview/item-card.vue'
import RecipeBusinessCard from '@/components/recipes/recipe-business-card.vue'
import TeaserCard from '@/components/teaser-card.vue'
import GtmHelper from '@/helpers/GtmHelper'
import useSlider from '@/compositions/useSlider'
import useAuth from '@/compositions/useAuth'
import api from '@/lib/api'

export default {
  components: {
    ProductCard,
    CatalogueCard,
    ItemCard,
    RecipeBusinessCard,
    TeaserCard
  },
  props: {
    isNarrow: {
      type: Boolean,
      default: false
    },
    hasBigCards: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: ''
    },
    seeAllUrl: {
      type: String,
      default: ''
    },
    seeAllTitle: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    cardType: {
      type: String,
      default: 'product'
    },
    ctaType: {
      type: String,
      default: 'link'
    },
    queryTrackId: {
      type: String,
      default: ''
    },
    readMore: {
      type: String,
      default: ''
    },
    headingTag: {
      type: String,
      default: 'strong'
    },
    listName: {
      type: String,
      default: 'Card Slider'
    }
  },
  setup({
    isNarrow,
    hasBigCards,
    seeAllUrl,
    cardType,
    items,
    listName
  }) {
    const { isBusinessAuth } = useAuth()
    const itemsWithBusinessData = ref(items)
    if (isBusinessAuth.value && items.length && cardType === 'product') {
      const eans = items.map((item) => item.ean)
      api.getProductsPackageInfo(eans)
        .then(({ data }) => {
          if (!Object.keys(data).length) {
            return
          }
          itemsWithBusinessData.value = itemsWithBusinessData.value.map((item) => ({
            ...item,
            packagesInfo: [
              ...data[item.ean]
            ]
          }))
        })
    }

    let swiper = null
    const swiperContainer = ref(null)
    const currentSlide = ref(0)
    const itemsCount = items.length
    const card = {
      product: 'productCard',
      catalogue: 'catalogueCard',
      recipe: 'itemCard',
      article: 'itemCard',
      businesscase: 'itemCard',
      teaser: 'teaserCard',
      recipeBusinessCard: 'recipeBusinessCard'
    }
    const { imagePlaceholderText } = window.arla.cms.resources.recipes

    const isSlider = ref(true)
    const isSingle = computed(() => itemsCount === 1 && hasBigCards)
    const cssClass = computed(() => ([
      { 'card-slider--narrow': isNarrow },
      { 'has-link': seeAllUrl },
      { 'has-big-cards': hasBigCards || itemsCount < 3 }
    ]))

    const { setOptions } = useSlider()
    const oneSlideBreakpoint = isNarrow || hasBigCards ? 568 : 375
    const numberOfSliderDesktop = isNarrow || hasBigCards ? 2 : 4
    const breakpoints = {
      768: {
        slidesPerView: itemsCount > 2 ? numberOfSliderDesktop : 2,
        slidesPerGroup: itemsCount > 2 ? numberOfSliderDesktop : 2,
        spaceBetween: 30
      }
    }
    breakpoints[oneSlideBreakpoint] = {
      slidesPerView: 2.2,
      slidesPerGroup: 2
    }
    const swiperOptions = setOptions({
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      spaceBetween: 20,
      breakpoints
    })

    const onPageChange = ({ activeIndex }) => {
      const direction = currentSlide.value < activeIndex ? 'forward' : 'backward'
      GtmHelper.changeSlide(direction, cardType, listName)
      currentSlide.value = activeIndex
    }

    const setIsSlider = (newValue) => {
      isSlider.value = newValue
    }

    const onBreakpointChange = (_, breakpointParams) => {
      setIsSlider(swiper.slides.length > breakpointParams.slidesPerGroup)
    }

    onMounted(() => {
      // eslint-disable-next-line no-unused-vars
      swiper = new Swiper(swiperContainer.value, swiperOptions)
      setIsSlider(swiper.slides.length > swiper.params.slidesPerGroup)
      swiper.on('slideChange', onPageChange)
      swiper.on('breakpoint', onBreakpointChange)
    })

    onBeforeUnmount(() => {
      swiper.destroy()
    })

    return {
      swiperContainer,
      isSingle,
      isSlider,
      cssClass,
      imagePlaceholderText,
      card,
      currentSlide,
      itemsWithBusinessData
    }
  }
}
</script>
