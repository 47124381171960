<template>
  <div
    class="header-sidebar"
    @click="hideHeaderSidebar"
  >
    <div
      ref="sidebarRef"
      class="header-sidebar-container"
      @click.stop
    >
      <button
        class="cta-icon-close"
        @click="hideHeaderSidebar"
      >
        <span class="u-hide-on-screenonly">Close sidebar</span>
        <CloseIcon class="icon" />
      </button>
      <LoginBlock
        v-if="!isConnectAuth && !isSupAuth"
        :error-text="errorText"
        :translations="translations"
      />
      <ServiceMenu
        v-else
        :translations="translations"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import LoginBlock from '@/components/login/login-block.vue'
import ServiceMenu from '@/components/header/service-menu.vue'
import CloseIcon from '@/assets/icons/streamline/streamlinehq-close-interface-essential-16.svg'
import useHeaderControls from '@/compositions/useHeaderControls'
import useAuth from '@/compositions/useAuth'

export default {
  components: {
    ServiceMenu,
    LoginBlock,
    CloseIcon
  },
  props: {
    errorText: {
      type: String,
      default: 'Något gick fel.'
    },
    translations: {
      type: Object,
      required: true
    }
  },
  setup() {
    const sidebarRef = ref(null)
    const {
      headerSidebarIsVisible,

      hideHeaderSidebar
    } = useHeaderControls()
    const {
      isConnectAuth,
      isSupAuth
    } = useAuth()

    onMounted(() => {
      watch(
        () => headerSidebarIsVisible.value,
        (isVisible) => {
          if (isVisible) {
            disableBodyScroll(sidebarRef.value)
          } else {
            enableBodyScroll(sidebarRef.value)
          }
        }
      )
    })

    return {
      sidebarRef,
      isConnectAuth,
      isSupAuth,

      hideHeaderSidebar
    }
  }
}
</script>
