<template>
  <nav
    aria-label="Order Web Navigation"
    role="navigation"
    class="login-opener"
    :class="{ 'is-active': headerSidebarIsVisible }"
  >
    <button
      class="login-opener-button"
      :aria-label="cmsNavigation.translations.login"
      @click.prevent="loginClickTrigger"
    >
      <IconUserLoggedIn
        v-if="isAuth"
        class="icon"
      />
      <IconUser
        v-else
        class="icon"
      />
      <template v-if="isConnectAuth">
        <span class="login-opener-button__text">
          <span
            v-if="selectedStore"
            class="login-opener__customer-name"
          >
            {{ selectedStore.name }}
          </span>
          <span v-if="selectedStore">{{ selectedStore.customerNumber }}</span>
        </span>
        <ThreeDotsIcon
          v-if="!isMobile"
          class="icon icon--dots"
        />
      </template>
    </button>
  </nav>
</template>

<script>
import { computed } from 'vue'

import IconUser from '@/assets/icons/streamline/streamlinehq-single-neutral-users-16.svg'
import IconUserLoggedIn from '@/assets/icons/profile-logged-in.svg'
import ThreeDotsIcon from '@/assets/icons/streamline/streamlinehq-navigation-menu-vertical-filled.svg'
import GtmHelper from '@/helpers/GtmHelper'

import useHeaderControls from '@/compositions/useHeaderControls'
import useHeaderModel from '@/compositions/useHeaderModel'
import useAuth from '@/compositions/useAuth'
import useConnect from '@/compositions/useConnect'

const { isConnectAuth, isAuth } = useAuth()

export default {
  components: {
    IconUser,
    IconUserLoggedIn,
    ThreeDotsIcon
  },
  props: {
    redirectToEcomOnLogin: {
      type: Boolean,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const {
      headerSidebarIsVisible,

      toggleHeaderSidebar
    } = useHeaderControls()

    const {
      cmsNavigation
    } = useHeaderModel()

    const {
      state: connectMenu
    } = useConnect()
    const stores = computed(() => (connectMenu ? connectMenu.stores : []))
    const selectedStore = computed(() => stores.value.find((item) => item.isSelected))

    const loginClickTrigger = () => {
      toggleHeaderSidebar(props.redirectToEcomOnLogin)
      if (headerSidebarIsVisible.value) {
        GtmHelper.showHeaderSidebar()
      }
    }

    return {
      cmsNavigation,
      isConnectAuth,
      isAuth,
      headerSidebarIsVisible,
      selectedStore,

      toggleHeaderSidebar,
      loginClickTrigger
    }
  }
}
</script>
