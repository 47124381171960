import api from '@/lib/api'

const TYPES = [
  'product', 'recipe', 'businesscase'
]

const STORAGE_NAMES = {
  product: 'favorite-products',
  recipe: 'favorite-recipes',
  businesscase: 'favorite-businesscase'
}

const getAllItems = () => {
  const favorites = {}
  TYPES.forEach((type) => {
    const items = localStorage.getItem(STORAGE_NAMES[type])
    if (items) {
      favorites[type] = items.split(',')
    }
  })
  return favorites
}

const getTotal = () => TYPES.reduce((accumulator, type) => {
  const items = localStorage.getItem(STORAGE_NAMES[type])
  if (items) {
    return accumulator + items.split(',').length
  }
  return accumulator
}, 0)

const clearStorage = () => {
  Object.values(STORAGE_NAMES).forEach((storageName) => {
    localStorage.removeItem(storageName)
  })
}

const migrateToAccount = (items) => api.migrateFavorites(items)
  .then(() => {
    clearStorage()
  })

export {
  getAllItems,
  getTotal,
  migrateToAccount,
  clearStorage
}
