import onWindowScroll from '@/helpers/on-window-scroll'

export default () => {
  const button = document.getElementById('go-top-button')
  if (!button) {
    return
  }

  const activeClass = 'is-visible'

  const inFunction = () => {
    button.classList.add(activeClass)
  }

  const outFunction = () => {
    button.classList.remove(activeClass)
  }

  onWindowScroll({
    inFunction,
    outFunction,
    delay: 100
  })
}
