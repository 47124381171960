var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-material-card product-card"},[_c('div',_vm._g({staticClass:"product-card__image",class:{ 'img-placeholder': !_vm.model.image}},_vm.model.image ? { click: _vm.orderMaterialClick } : {}),[(_vm.smallImageUrl)?_c('img',{ref:"img",attrs:{"src":_vm.smallImageUrl,"alt":_vm.model.name}}):_vm._e()]),_c('div',{staticClass:"product-card__text"},[_c('strong',{staticClass:"product-card__title"},[_vm._v(_vm._s(_vm.model.name))]),_c('span',{staticClass:"product-card__subtitle"},[_vm._v(_vm._s(_vm.model.description))])]),_c('div',{staticClass:"product-card__bottom"},[_c('div',{staticClass:"item-card__prices"},[_c('span',{staticClass:"item-card__prices-title"},[_vm._v(" "+_vm._s(_vm.translations.card.priceTitle))]),_c('span',{staticClass:"item-card__price"},[_vm._v(_vm._s(_vm.price))]),(_vm.outOfStock)?_c('Tooltip',{attrs:{"model":_vm.tooltipModel.outOfStock}}):_vm._e()],1),_c('div',{staticClass:"item-card__controls"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.state.amount),expression:"state.amount",modifiers:{"number":true}},{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.tooltipModel.notEnoughInStock.content,
          show: _vm.notEnoughInStock,
          autoHide: false,
          placement: 'top-start',
          trigger: 'manual',
          classes : 'error'
        }),expression:"{\n          content: tooltipModel.notEnoughInStock.content,\n          show: notEnoughInStock,\n          autoHide: false,\n          placement: 'top-start',\n          trigger: 'manual',\n          classes : 'error'\n        }"}],staticClass:"item-card__amount-field",class:{ invalid: _vm.hasError },attrs:{"disabled":_vm.outOfStock,"min":"0","type":"number","aria-label":"Amount of product"},domProps:{"value":(_vm.state.amount)},on:{"focus":function($event){_vm.hasError = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateCart.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.state, "amount", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"item-card__units"},[_vm._v(_vm._s(_vm.translations.card.priceUnit))]),_c('button',{staticClass:"item-card__cta",attrs:{"disabled":_vm.outOfStock},on:{"click":function($event){$event.preventDefault();return _vm.updateCart.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.translations.card.addToBasket)+" ")])])]),(_vm.model.image)?_c('image-modal',{attrs:{"name":_vm.model.name,"image":_vm.largeImageUrl,"modal-name":_vm.model.id}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }