<template>
  <form @submit.prevent="addToCart">
    <div class="item-card__controls-wrap">
      <input
        v-model.number="amount"
        :class="{ invalid: hasError }"
        :disabled="!isAvailable"
        min="0"
        type="number"
        class="item-card__amount-field"
        aria-label="Amount of product"
        @focus="hasError = false"
      >
      <template v-if="isAvailable">
        <span
          v-if="model.packagesInfo.length === 1"
          class="item-card__units"
        >
          {{ selectedPackage.orderingUnit }}
        </span>

        <select
          v-else
          :value="selectedPackage.code"
          class="form-dropdown"
          @change="selectPackage($event.target.value)"
        >
          <option
            v-for="item in model.packagesInfo"
            :key="item.code"
            :value="item.code"
            v-text="item.orderingUnit"
          />
        </select>
      </template>
      <button
        type="submit"
        :disabled="!isAvailable"
        class="item-card__cta"
      >
        {{ translations.addToBasket }}
        <cssLoader
          v-if="state.loading"
          class="css-loader"
        />
      </button>
      <Tooltip
        v-if="!isAvailable"
        :model="tooltipModel.notAuth"
        title-color-css-class=""
      />
    </div>
    <div
      v-if="errorMessage"
      class="form-message--error"
    >
      <div v-html="translations.notificationMessage" />
    </div>
  </form>
</template>

<script>
import { ref } from 'vue'
import useMiniCart from '@/compositions/useMiniCart'
import Tooltip from '@/components/tooltip.vue'
import cssLoader from '@/components/css-loader.vue'

export default {
  components: {
    Tooltip,
    cssLoader
  },
  props: {
    translations: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    placement: {
      type: String,
      default: 'Product Details'
    }
  },
  setup(props) {
    const {
      state, addToMiniCart
    } = useMiniCart()
    const hasError = ref(false)
    const amount = ref(null)
    const errorMessage = ref(null)
    const {
      model: {
        ean, name, brand, image, category
      },
      placement
    } = props
    const isAvailable = props.model.packagesInfo.length
    const selectedPackage = ref(isAvailable ? props.model.packagesInfo[0] : null)

    const addToCart = () => {
      if (state.loading) {
        return
      }
      if (typeof amount.value !== 'number' || amount.value === 0) {
        hasError.value = true
      } else {
        hasError.value = false
        errorMessage.value = null
        state.loading = true

        const {
          code,
          orderingUnit: priceUnit
        } = selectedPackage.value

        addToMiniCart({
          code,
          name,
          brand,
          category,
          amount: amount.value,
          priceUnit,
          image,
          placement
        }).then((data) => {
          if (data && !data.success) {
            errorMessage.value = data.message
            hasError.value = true
          }
          amount.value = null
        }).catch((error) => {
          errorMessage.value = error
          hasError.value = true
        }).then(() => {
          state.loading = false
        })
      }
    }

    const selectPackage = (code) => {
      selectedPackage.value = props.model.packagesInfo.find((item) => item.code === code)
    }

    const tooltipModel = {
      notAuth: {
        content: 'Produkten ingår inte i det sortiment som ni kan beställa av oss.'
      },
      supAuth: {
        content: props.translations.privateUserBuyButtonTooltip
      }
    }

    return {
      state,
      amount,
      hasError,
      errorMessage,
      selectedPackage,
      isAvailable,
      tooltipModel,

      addToCart,
      selectPackage
    }
  }
}
</script>
