<template>
  <a
    :href="cardUrl"
    class="item-card"
    :class="cssClass"
    @click="itemClick"
  >
    <div class="item-card__image">
      <img
        v-if="model.image"
        ref="img"
        :src="model.image"
        :alt="model.name"
      >
      <div
        v-else
        class="item-card__image-placeholder"
      >
        <div class="item-card__image-placeholder__icon" />
        <span v-if="model.imagePlaceholderText">{{ model.imagePlaceholderText }}</span>
      </div>

      <add-to-favorites
        v-if="showFavoritesButton"
        :item="{
          id: model.id,
          name: model.name
        }"
        :type="type"
      />

      <span
        v-if="model.isSustainable"
        class="icon u-bg-color--goda-mal"
      >
        <IconSustainable
          style="width: 41px; height: 21px"
        />
      </span>
    </div>

    <div class="item-card__inner">
      <div class="item-card__body">
        <CatalogueCheckbox
          v-if="isCataloguePage"
          :id="model.id"
          :name="model.name"
          :type="type"
        />
        <h4 class="item-card__title">{{ model.name }}</h4>
        <div
          v-if="model.description"
          v-html="model.description"
        />
        <slot />
      </div>

      <div
        v-if="linkText && isWide"
        class="item-card__bottom"
      >
        <span
          :class="ctaCssClass"
          v-text="linkText"
        />
      </div>
    </div>

    <div
      v-if="linkText && !isWide"
      class="item-card__bottom"
    >
      <span
        :class="ctaCssClass"
        v-text="linkText"
      />
    </div>
  </a>
</template>

<script>
import gtmHelper from '@/helpers/GtmHelper'
import epiTracking from '@/helpers/epi-tracking'

import AddToFavorites from '@/components/add-to-favorites.vue'
import CatalogueCheckbox from '@/components/catalogue/catalogue-checkbox.vue'
import useCatalogue from '@/compositions/useCatalogue'
import IconSustainable from '@/assets/icons/goda-mal.svg'

export default {
  components: {
    CatalogueCheckbox,
    AddToFavorites,
    IconSustainable
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    imagePlaceholderText: {
      type: String,
      default: ''
    },
    listName: {
      type: String,
      required: true
    },
    queryTrackId: {
      type: String,
      default: ''
    },
    queryTrackType: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    ctaType: {
      type: String,
      default: 'link'
    },
    type: {
      type: String,
      default: ''
    },
    isWide: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { isCataloguePage } = useCatalogue()

    return {
      isCataloguePage
    }
  },
  computed: {
    trackData() {
      return {
        list: this.listName,
        ...this.model
      }
    },
    epiTrackData() {
      return {
        queryTrackType: this.queryTrackType,
        queryTrackId: this.queryTrackId,
        hitId: this.model.hitId,
        position: this.position
      }
    },
    ctaCssClass() {
      return this.ctaType === 'link' ? 'new-teaser__link' : 'cta cta--transparent'
    },
    showFavoritesButton() {
      return this.type === 'recipe' || this.type === 'businesscase'
    },
    cssClass() {
      const obj = [
        { 'item-card--wide': this.isWide }
      ]
      if (this.isWide) {
        const bgColor = this.model.featuredTheme ? this.model.featuredTheme : 'yellow'
        obj.push(`u-bg-color--${bgColor}`)
      }
      return obj
    },
    cardUrl() {
      return this.model.url.replace('%2B', '+')
    }
  },
  methods: {
    itemClick() {
      if (this.type === 'article') {
        gtmHelper.articleClicks(this.trackData)
      }
      if (this.type === 'recipe') {
        gtmHelper.recipeClicks(this.trackData)
      }
      if (this.type === 'businesscase') {
        gtmHelper.businessCaseClicks(this.trackData)
      }
      if (this.type === 'teaser') {
        gtmHelper.teaserClick({
          title: this.model.name,
          teaserType: 'Default'
        })
      }
      if (this.queryTrackType && this.queryTrackId) {
        epiTracking.trackCardItemClick(this.epiTrackData)
      }
    }
  }
}
</script>
