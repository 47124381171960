<template>
  <div
    :class="{ 'accordion-item--expanded' : isExpanded || isAnimating }"
    class="accordion-item"
  >
    <div class="accordion-item__header">
      <span class="accordion-item__header-title">{{ model.title }}</span>
      <span
        class="accordion-item__header-opener"
        @click="isExpanded && !isAnimating ? collapse() : expand()"
      />
    </div>
    <div
      v-show="isAnimating || isExpanded"
      ref="content"
      :style="showStyles ? contentStyle : ''"
      class="accordion-item__content"
    >
      <div
        class="accordion-item__content"
        v-html="model.content"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    firstOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const isExpanded = this.firstOpen && this.index === 0

    return {
      isExpanded,
      isAnimating: false,
      showStyles: false,
      contentStyle: {
        overflow: 'hidden',
        height: '0'
      }
    }
  },
  methods: {
    expand() {
      const { content } = this.$refs

      this.showStyles = true
      this.isAnimating = true

      const listener = () => {
        this.isAnimating = false
        this.isExpanded = true
        this.showStyles = false

        content.removeEventListener('transitionend', listener, false)
      }

      content.addEventListener('transitionend', listener, false)

      setTimeout(() => {
        this.contentStyle.height = `${content.scrollHeight}px`
      }, 50) // 50 msec delay is set because of IE11 problems
    },
    collapse() {
      const { content } = this.$refs

      const listener = () => {
        this.isAnimating = false
        this.showStyles = false

        content.removeEventListener('transitionend', listener, false)
      }

      content.addEventListener('transitionend', listener, false)

      this.contentStyle.height = `${content.scrollHeight}px`
      this.showStyles = true

      setTimeout(() => {
        this.isAnimating = true
        this.isExpanded = false
        this.contentStyle.height = '0'
      }, 50) // 50 msec delay is set because of IE11 problems
    }
  }
}
</script>
