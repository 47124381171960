import { reactive, computed } from 'vue'
import searchQueryState from './search-query'

const tabConfigurationState = reactive({
  tabsConfiguration: [],
  hasSearchTabs: false,
  initialized: false
})

export default {
  tabsConfigurationUse(configuration, hasSearchTabs = false) {
    const { searchQuery, setSearchType, setAllSearchTypes } = searchQueryState.searchQueryUse()
    const state = tabConfigurationState

    if (configuration && !state.initialized) {
      state.tabsConfiguration = configuration
      setSearchType(configuration.find((x, index) => index === 0).type)
      setAllSearchTypes(configuration.map((x) => x.type))
      state.hasSearchTabs = hasSearchTabs
      state.initialized = true
    }

    function getConfigBySearchType(searchType) {
      return state.tabsConfiguration.find((x) => x.type === searchType)
    }
    function getActiveConfig() {
      return getConfigBySearchType(searchQuery.searchType)
    }

    return {
      getActiveConfig,
      getConfigBySearchType,

      hasSearchTabs: computed(() => state.hasSearchTabs),
      tabsConfiguration: computed(() => state.tabsConfiguration)
    }
  }
}
