<template>
  <div
    :class="{ 'load': loading }"
    class="loader"
  >
    <LoadIcon class="icon-loading" />
  </div>
</template>

<script>
import LoadIcon from '@/assets/icons/loading.svg'

export default {
  components: {
    LoadIcon
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>
