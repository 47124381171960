import GtmHelper from '@/helpers/GtmHelper'

export default () => {
  if (!window.navigator.clipboard) {
    document.documentElement.classList.add('no-clipboard')
    return
  }

  const copyLink = async (e) => {
    e.preventDefault()
    const activeClass = 'copied'
    const link = e.currentTarget
    const url = window.location.href
    await window.navigator.clipboard.writeText(url)

    const linkTextElement = link.querySelector('.js-text')
    if (linkTextElement) {
      linkTextElement.innerHTML = link.dataset.text
    }
    link.classList.add(activeClass)
    setTimeout(() => {
      link.classList.remove(activeClass)
    }, 2000)
    GtmHelper.pushDataLayer({
      event: 'copyLink'
    })
  }

  const link = document.querySelector('.js-copy-link')
  if (link) {
    link.addEventListener('click', copyLink, false)

    const linkTextElement = link.querySelector('.js-text')
    if (linkTextElement) {
      link.addEventListener('mouseenter', () => {
        linkTextElement.innerHTML = link.dataset.hoverText
      }, false)
      link.addEventListener('mouseleave', () => {
        linkTextElement.innerHTML = ''
      }, false)
    }
  }
}
