<template>
  <div
    class="item-filters"
    @click="closeFilterMenu"
  >
    <div
      class="item-filters-wrap"
      @click.stop
    >
      <div class="item-filters-inner">
        <search-filters-list
          v-for="(category, index) in categories"
          :key="category.slug"
          :category="category"
          :is-expanded-by-default="index < expandedFilters"
        />
      </div>

      <div class="item-filters__bottom-panel u-hidden--desktop">
        <div class="item-filters__bottom-panel__row">
          <button
            class="cta"
            @click="closeFilterMenu"
            v-text="closeFilterMenuText"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'

import SearchFiltersList from '@/components/search/search-filters-list.vue'
import searchTabsConfig from '@/compositions/search/search-tabs-configuration'
import searchRepository from '@/compositions/search/search-repository'
import useFilters from '@/compositions/useFilters'

export default {
  components: { SearchFiltersList },
  setup() {
    const { getActiveConfig } = searchTabsConfig.tabsConfigurationUse()
    const { closeFilterMenu } = useFilters()

    const { totalCount } = searchRepository.useSearchResult()

    const closeFilterMenuText = computed(() => {
      const { totalCountMessageDesktop } = getActiveConfig().translations
      return totalCountMessageDesktop.replace('{count}', `${totalCount.value}`) || 'Close'
    })

    return {
      categories: computed(() => getActiveConfig().categories),
      expandedFilters: 2,
      closeFilterMenu,
      closeFilterMenuText
    }
  }
}
</script>
