<template>
  <nav
    ref="nav"
    class="tab-links"
  >
    <a
      v-for="(config, index) in tabsConfiguration"
      :key="config.type"
      :class="getTabClass(config.type)"
      :tabindex="index"
      class="tab-link"
      @click.prevent="onTabClick(config.type)"
      @keyup.enter="onTabClick(config.type)"
    >
      {{ getTabName(config) }}
    </a>
  </nav>
</template>

<script>
import { ref, onMounted } from 'vue'
import searchQueryState from '@/compositions/search/search-query'
import searchRepository from '@/compositions/search/search-repository'
import searchTabsConfig from '@/compositions/search/search-tabs-configuration'
import { handleNavHorizontalScroll } from '@/helpers/CheckPositionInViewport'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  setup() {
    const {
      searchQuery,
      trackingPrefix,

      setSearchType
    } = searchQueryState.searchQueryUse()
    const { loading, searchResults } = searchRepository.useSearchResult()
    const { tabsConfiguration } = searchTabsConfig.tabsConfigurationUse()
    const nav = ref(null)

    const getTabClass = (tabType) => {
      let cssCombinedClass = tabType
      if (searchQuery.searchType === tabType) {
        cssCombinedClass += ' active'
      }
      return cssCombinedClass
    }

    const getTabName = (tabConfig) => {
      if (Array.isArray(searchResults.value)) {
        const localSearchResult = searchResults.value.find((x) => x.type === tabConfig.type)
        return tabConfig.translations.tabName.concat(' (', localSearchResult.totalCount, ')')
      }

      return tabConfig.translations.tabName
    }
    const onTabClick = (type) => {
      setSearchType(type)
      GtmHelper.overviewContentTabClick(type, trackingPrefix)
    }

    onMounted(() => {
      handleNavHorizontalScroll(nav.value)
    })

    return {
      nav,
      loading,
      tabsConfiguration,

      getTabClass,
      getTabName,
      onTabClick
    }
  }
}
</script>
