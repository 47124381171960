<template>
  <div class="card-list">
    <items-header />

    <div
      v-show="!noResult"
    >
      <ul class="card-list__items four-columns">
        <li
          v-for="(item, index) in items"
          :key="item.id"
          class="card-list__item"
        >
          <product-card
            v-if="searchType === 'product'"
            :model="item"
            :position="index + 1"
            :query-track-id="trackId"
            list-name="Product Favorites"
            :remove-from-favorites="true"
          />
          <catalogue-card
            v-else-if="searchType === 'catalogue'"
            :model="item"
            :position="index + 1"
            :query-track-id="trackId"
            list-name="Catalogue Favorites"
          />
          <item-card
            v-else
            :model="item"
            :query-track-id="trackId"
            :query-track-type="searchType"
            list-name="Recipe Favorites"
            :type="searchType"
            :remove-from-favorites="true"
          />
        </li>
      </ul>
    </div>

    <template v-if="noResult">
      <div class="no-results-container">
        <icon-no-results class="icon" />
        <div v-html="noResultHtml" />
      </div>
    </template>

    <loader :loading="loading" />
  </div>
</template>
<script>
import { computed } from 'vue'

import IconNoResults from '@/assets/icons/no_results.svg'
import ItemCard from '@/components/itemsOverview/item-card.vue'
import Loader from '@/components/loader.vue'
import ProductCard from '@/components/products/product-card.vue'
import CatalogueCard from '@/components/catalogue/catalogue-card.vue'
import ItemsHeader from '@/components/favorites/favorites-items-header.vue'

import favoritesRepository from '@/compositions/favorites/favorites-repository'
import favoritesTabsConfig from '@/compositions/favorites/favorites-tabs-configuration'
import favoritesQueryState from '@/compositions/favorites/favorites-query'

export default {
  components: {
    IconNoResults,
    ItemCard,
    Loader,
    ProductCard,
    ItemsHeader,
    CatalogueCard
  },
  setup() {
    const { favoritesQuery } = favoritesQueryState.favoritesQueryUse()
    const { getActiveConfig } = favoritesTabsConfig.favoritesTabsConfigurationUse()
    const {
      items,
      loading,
      trackId
    } = favoritesRepository.useFavoritesResult()

    return {
      trackId,
      items,
      loading,
      searchType: computed(() => favoritesQuery.favoritesType),
      noResult: computed(() => !loading.value && items.value.length === 0),
      noResultHtml: computed(() => getActiveConfig().noResultHtml)
    }
  }
}
</script>
