import { ref } from 'vue'
import api from '@/lib/api'
import useHeader from '@/compositions/useHeaderModel'
import useAuth from '@/compositions/useAuth'
import { setFavorites } from '@/compositions/favorites/favorites-id-repository'

const { setUserNavigation } = useHeader()
const { setAuth } = useAuth()

const includeCatalogues = !!document.querySelector('.include-catalogues')
const userIsInitiated = ref(false)

export default () => {
  api.getUserData(includeCatalogues).then(({
    data: {
      favorites,
      navigation,
      userType
    }
  }) => {
    setAuth(userType)

    if (navigation) {
      setUserNavigation(navigation)
    }

    if (favorites) {
      setFavorites({
        ...favorites
      })
    }

    userIsInitiated.value = true
  }).catch((e) => {
    console.error(e)
  })
}

export { userIsInitiated }
