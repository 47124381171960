import debounce from '@/helpers/debounce'

export default ({
  inFunction = null,
  outFunction = null,
  threshold = window.screen.height,
  delay = 300
}) => {
  if (!inFunction && !outFunction) {
    return
  }
  let lastScrollTop = 0
  let isActive = false
  const onScroll = debounce(() => {
    const scrollTop = window.scrollY

    if (scrollTop > lastScrollTop) {
      // Scroll down
      if (
        !isActive
        && scrollTop > threshold
        && inFunction
      ) {
        inFunction()
        isActive = true
      }
    } else if (
      isActive
      && scrollTop < threshold
      && outFunction
    ) {
      // Scroll up
      outFunction()
      isActive = false
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
  }, delay)

  window.addEventListener('scroll', onScroll)
  onScroll()
}
