import GtmHelper from '@/helpers/GtmHelper'

export default () => {
  if (!window.navigator.share) {
    document.documentElement.classList.add('no-share')
    return
  }

  const share = async () => {
    const url = window.location.href
    const { title } = window.document
    await window.navigator.share({
      title,
      url
    })
    GtmHelper.pushDataLayer({
      event: 'shareRecipe'
    })
  }

  const link = document.querySelector('.js-share-button')
  if (link) {
    link.addEventListener('click', share, false)
  }
}
