<template>
  <div>
    <h3
      v-if="model.header"
      class="u-flex"
    >
      {{ model.header }}
    </h3>
    <p v-if="model.description">
      {{ model.description }}
    </p>
    <img
      v-if="model.imageUrl"
      :src="model.imageUrl"
      :alt="model.header"
      width="365"
      height="200"
      class="u-block"
      :class="{ 'mb-sm': model.link.url }"
    >
    <a
      v-if="model.link && model.link.url"
      :href="model.link.url"
      class="cta cta--transparent"
      v-bind="model.link.attributes"
      @click="$emit('ctaClick')"
    >
      {{ model.link.text }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
