<template>
  <a
    v-if="model.url"
    :href="model.url"
    class="cta cta--transparent u-text-nowrap"
    :target="model.target"
    @click="onButtonClick"
  >
    <IconArrow class="icon" />
    <span v-text="model.text" />
  </a>
</template>

<script>
import IconArrow from '@/assets/icons/arrow-corner.svg'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    IconArrow
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup() {
    const onButtonClick = () => {
      GtmHelper.goToCatalogue()
    }

    return {
      onButtonClick
    }
  }
}
</script>
