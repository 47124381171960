<template>
  <search-input
    :placeholder="searchText"
    @submit="addTerm"
  />
</template>

<script>
import SearchInput from '@/components/search-input.vue'
import searchQueryState from '@/compositions/search/search-query'

export default {
  components: {
    SearchInput
  },
  props: {
    searchText: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { addTerm } = searchQueryState.searchQueryUse()

    return { addTerm }
  }
}
</script>
