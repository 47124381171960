<template>
  <modal
    :name="modalName"
    :adaptive="true"
    :max-width="600"
    height="auto"
    width="100%"
    scrollable
    reset
    class="v--modal"
    @before-open="beforeOpenModal()"
    @before-close="beforeCloseModal()"
  >
    <div class="popup__inner popup__inner--wide">
      <div class="popup-header">
        <strong class="popup-header__text h3">
          {{ catalogueTranslations.modalHeading }}
        </strong>
      </div>
      <div class="popup-content">
        <CatalogueForm
          @submit="hidePopup(modalName)"
        />
      </div>
      <span
        role="button"
        :aria-label="closeLabel"
        class="popup-content__button--close"
        @click="hidePopup(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import { getCurrentInstance } from 'vue'
import useModal from '@/compositions/useModal'
import useCatalogue from '@/compositions/useCatalogue'
import CatalogueForm from '@/components/catalogue/catalogue-form.vue'

export default {
  components: {
    CatalogueForm
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    } = useModal($modal)

    const {
      modalName,
      catalogueTranslations
    } = useCatalogue()

    const { closeLabel } = window.arla.cms.resources.general

    return {
      modalName,
      catalogueTranslations,
      closeLabel,

      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    }
  }
}
</script>
