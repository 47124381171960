<template>
  <nav
    ref="mainMenu"
    class="c-main-menu"
    :class="{
      'is-opened-overlay': isOverlayVisible,
      'has-no-animation': isAnimated,
      'is-opened': isMobileOpened,
      'is-closing': isClosing
    }"
  >
    <div
      class="c-main-menu__overlay"
      @click="hideOpenedSubmenu()"
    />
    <button
      class="c-main-menu__button"
      :aria-label="cmsNavigation.translations ? cmsNavigation.translations.openNavigation : ''"
      @click="toggleButton"
    >
      <span class="c-main-menu__button-inner">
        <span class="c-main-menu__button-line" />
        <span class="c-main-menu__button-line" />
        <span class="c-main-menu__button-line" />
      </span>
    </button>
    <button
      v-if="isSubmenuOpened"
      class="c-main-menu__close-button"
      @click="backToMainMenu"
    >
      <IconArrowLeft
        class="c-main-menu__close-button-icon"
      />
    </button>

    <div class="c-main-menu-wrap">
      <ul
        ref="nav"
        class="c-main-menu__list u-bare-list"
      >
        <li
          v-if="showFavorites"
          class="c-main-menu__item c-main-menu__favourites-item"
        >
          <a
            class="c-main-menu__item-link"
            :href="favorites.url"
            @click="favoritesClickListener($event, favorites, favorites.text)"
          >
            <IconBookmark class="icon c-icon-24 c-icon-fill-none mr-10px" />
            {{ favorites.text }}
            <span class="splash-sign splash-sign--counter ml-auto">
              <span class="text-icon text-icon--plain">
                {{ favorites.totalCount }}
              </span>
            </span>
          </a>
        </li>
        <li
          v-for="(section, index) in navigation"
          :key="section.title"
          class="c-main-menu__item"
          :class="{
            'is-active-item': index === openIndex || section.isSubmenuOpened
          }"
          @mouseenter="onMenuItemHover(section, index)"
          @mouseleave="onMenuItemLeave(section, index)"
        >
          <a
            class="c-main-menu__item-link"
            :href="section.url"
            :class="{
              'is-active': section.isActive,
              'is-highlighted': section.isHighlighted
            }"
            @click="onMenuItemClick($event, section, section.title)"
          >
            {{ section.title }}
            <IconArrowLeft
              v-if="section.children.length"
              class="c-main-menu__item-link-icon"
            />
          </a>
          <SubMenu
            v-show="section.children.length"
            :section="section"
            :is-active="isSubmenuOpened && section.isSubmenuOpened"
          />
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import IconArrowLeft from '@/assets/icons/arrow_left--updated.svg'
import IconBookmark from '@/assets/icons/streamline/streamlinehq-interface-bookmark.svg'
import SubMenu from '@/components/header/main-menu-submenu.vue'
import GtmHelper from '@/helpers/GtmHelper'
import useAuth from '@/compositions/useAuth'
import useHeaderControls from '@/compositions/useHeaderControls'
import useHeaderModel from '@/compositions/useHeaderModel'

const navigationOpenedCssClass = 'is-navigation-opened'
const submenuOpenedCssClass = 'is-submenu-opened'
export default {
  components: {
    IconArrowLeft,
    IconBookmark,
    SubMenu
  },
  props: {
    favorites: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const { isBusinessAuth, isAuth } = useAuth()
    const {
      showHeaderSidebar
    } = useHeaderControls()
    const {
      cmsNavigation,
      connectUserMenu
    } = useHeaderModel()
    const isMobileOpened = ref(false)
    const isClosing = ref(false)
    const isSubmenuOpened = ref(false)

    const navigation = computed(() => {
      const nav = [...cmsNavigation.value.mainNavigation]
      if (connectUserMenu.value) {
        nav.push(connectUserMenu.value)
      }
      return nav
    })

    const hideOpenedSubmenu = () => {
      const openedSubmenu = navigation.value.find((section) => section.isSubmenuOpened)
      if (openedSubmenu) {
        openedSubmenu.isSubmenuOpened = false
      }
    }

    const showMenu = () => {
      isMobileOpened.value = true
      GtmHelper.mainMenuNavigationOpen()
    }

    const hideMenu = () => {
      isClosing.value = true
      isMobileOpened.value = false
      isSubmenuOpened.value = false

      setTimeout(() => {
        isClosing.value = false
        hideOpenedSubmenu()
      }, 300)
      GtmHelper.mainMenuNavigationClose()
    }

    const toggleButton = () => {
      if (isMobileOpened.value) {
        hideMenu()
      } else {
        showMenu()
      }
    }

    const onFavoritesClick = (e) => {
      e.preventDefault()
      hideMenu()
      showHeaderSidebar(false)
    }

    const showFavorites = computed(() => (
      props.favorites
        && props.favorites.url
        && isAuth.value
    ))

    onMounted(() => {
      const showSidebarOnPageLoad = window.location.hash.indexOf('login') !== -1
      if (showSidebarOnPageLoad) {
        showHeaderSidebar()
      }
    })

    return {
      isAuth,
      isBusinessAuth,
      cmsNavigation,
      navigation,
      isMobileOpened,
      isClosing,
      isSubmenuOpened,
      showFavorites,

      showHeaderSidebar,
      hideOpenedSubmenu,
      toggleButton,
      onFavoritesClick
    }
  },
  data() {
    return {
      body: document.body,
      openIndex: -1,
      timer: null,
      isAnimated: false,
      animatedTimer: null,
      isOverlayVisible: false
    }
  },
  watch: {
    isMobileOpened(isOpened) {
      if (isOpened) {
        this.body.classList.add(navigationOpenedCssClass)
        if (this.isMobile()) {
          disableBodyScroll(this.$refs.mainMenu, {
            // eslint-disable-next-line consistent-return
            allowTouchMove: (el) => {
              while (el && el !== document.body) {
                if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                  return true
                }

                // eslint-disable-next-line no-param-reassign
                el = el.parentElement
              }
            }
          })
        }
      } else {
        this.body.classList.remove(navigationOpenedCssClass)
        if (this.isMobile()) {
          enableBodyScroll(this.$refs.mainMenu)
        }
      }
    },
    isSubmenuOpened(isOpened) {
      this.body.classList[isOpened ? 'add' : 'remove'](submenuOpenedCssClass)
    }
  },
  created() {
    document.addEventListener('click', this.documentClick)
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    onMenuItemHover(section, index) {
      if (this.isMobile()) {
        return
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
      }

      if (section.children && section.children.length) {
        if (this.openIndex === -1) {
          this.delayTimer = setTimeout(() => {
            this.isOverlayVisible = true
            this.$refs.mainMenu.parentNode.classList.add('menu-opened')
            this.openIndex = index
          }, 200)
        } else {
          this.isOverlayVisible = true
          this.$refs.mainMenu.parentNode.classList.add('menu-opened')
          if (this.openIndex !== -1) {
            this.isAnimated = true
          }
          this.openIndex = index
        }
      } else {
        this.openIndex = -1
        this.isOverlayVisible = false
        setTimeout(() => {
          this.$refs.mainMenu.parentNode.classList.remove('menu-opened')
        }, 400)
      }
    },
    onMenuItemLeave() {
      if (this.isMobile()) {
        return
      }
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
      }
      this.timer = setTimeout(() => {
        this.isAnimated = false
        this.openIndex = -1
        this.isOverlayVisible = false
        setTimeout(() => {
          this.$refs.mainMenu.parentNode.classList.remove('menu-opened')
        }, 400)
      }, 200)
    },
    isMobile() {
      const isTouch = !document.documentElement.classList.contains('no-touch')
      return (isTouch || (this.$mq !== 'desktop' && !isTouch))
    },
    onMenuItemClick(event, section, title) {
      let trackingEvent = 'topMenuTitleLinkClick'
      if (this.isMobile()) {
        if (section.children.length) {
          event.preventDefault()
          this.hideOpenedSubmenu()
          this.isSubmenuOpened = true
          section.isSubmenuOpened = true
          trackingEvent = 'topMenuTitleMobileDropdownClick'
        } else {
          trackingEvent = 'topMenuTitleMobileLinkClick'
        }
      }
      // Show sidebar if unauthenticated user clicked on 'Mina sidor' link
      if (!this.isBusinessAuth && section.isHighlighted) {
        event.preventDefault()
        this.showHeaderSidebar()
      }
      GtmHelper.mainMenuItemClick(trackingEvent, title)
    },
    backToMainMenu() {
      this.isSubmenuOpened = false
      GtmHelper.mainMenuPreviousClick()
      this.hideOpenedSubmenu()
    },
    documentClick(e) {
      if (this.isSubmenuOpened) {
        const el = this.$refs.nav
        const { target } = e
        if (el !== target && !el.contains(target)) {
          this.isSubmenuOpened = false
          this.hideOpenedSubmenu()
        }
      }
    },
    favoritesClickListener(event, favorites, favoritesText) {
      if (this.isAuth) {
        this.onMenuItemClick(event, favorites, favoritesText)
      } else {
        this.onFavoritesClick(event)
      }
    }
  }
}
</script>
