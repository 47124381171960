<template>
  <div class="related-recipes">
    <strong class="card-slider__heading">{{ heading }}</strong>

    <div class="quick-filters">
      <ul class="tags-list tags-list--dark">
        <li
          v-for="tag in tags"
          :key="tag.slug"
          class="tags-list__item"
        >
          <span
            class="tags-list__link"
            role="button"
            :class="{ active: activeTag === tag }"
            @click="setActiveTag(tag)"
          >
            {{ tag.name }}
          </span>
        </li>
      </ul>
    </div>

    <div
      v-if="recipes.length"
      class="related-recipes__slider"
    >
      <loader
        v-if="loadingRecipes"
        :loading="loadingRecipes"
      />
      <card-slider
        v-else
        class="recipe-slider"
        card-type="recipe"
        :items="recipes"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import api from '@/lib/api'
import CardSlider from '@/components/card-slider.vue'
import Loader from '@/components/loader.vue'

export default {
  components: {
    CardSlider,
    Loader
  },
  props: {
    recipeId: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    },
    tags: {
      type: Array,
      required: true
    }
  },
  setup({ recipeId, quantity, tags }) {
    const activeTag = ref('')

    const loadingRecipes = ref(false)
    const recipes = ref([])
    const getRecipesByTag = async () => {
      try {
        loadingRecipes.value = true
        const response = await api.getRecipes({
          take: quantity,
          activeCategories: [activeTag.value],
          sorting: 'date',
          getTermFacets: false,
          excluded: [recipeId]
        })

        // TODO: Add 'No related recipes for this category' text
        recipes.value = response.data.items
      } finally {
        loadingRecipes.value = false
      }
    }

    const setActiveTag = (tag) => {
      activeTag.value = tag
      getRecipesByTag()
    }

    const [firstTag] = tags
    setActiveTag(firstTag)

    return {
      activeTag,
      setActiveTag,
      recipes,
      loadingRecipes
    }
  }
}
</script>
