import { computed, reactive } from 'vue'
import api from '@/lib/api'

const quickSearchState = reactive({
  term: '',
  searchResults: {}
})

export default {
  quickSearchUse() {
    const state = quickSearchState
    let searchIterator = 0
    let asyncSearchIterator = searchIterator

    const resetQuickSearchResults = () => {
      state.searchResults = {}
    }

    async function fetchData() {
      const response = await api.getAutoSuggestions(state.term)
      asyncSearchIterator += 1

      if (searchIterator === asyncSearchIterator) {
        state.searchResults = response.data.searchResults
      }
    }

    async function search() {
      if (state.term !== '') {
        fetchData()
      }
    }

    const setTerm = (term) => {
      state.term = term
      searchIterator += 1
      search()
    }

    return {
      searchResults: computed(() => state.searchResults),
      term: computed(() => state.term),
      setTerm,
      resetQuickSearchResults,
      search
    }
  }
}
