<template>
  <div class="recipe__ingredients">
    <h4 class="hide--screen">
      {{ title }}
    </h4>
    <template v-if="amountText">
      <div class="recipe__ingredients-amount hide--screen">
        <span>{{ amountText }}: {{ portionsModel }}</span>
      </div>
      <div class="hide--print">
        <label
          v-if="ingredients.amountIsScalable"
          :for="`recipe__ingredients-amount--${media}`"
          class="recipe__ingredients-amount__title"
        >
          {{ amountText }}
        </label>
        <span
          v-else
          class="recipe__ingredients-amount__title"
        >
          {{ amountText }}
        </span>
      </div>
    </template>

    <div
      v-if="ingredients.amountIsScalable"
      class="recipe__ingredients-amount input-number hide--print"
    >
      <input
        :id="`recipe__ingredients-amount--${media}`"
        ref="inputNumber"
        v-model="portionsModel"
        :min="minValue"
        :max="maxValue"
        :step="stepValue"
        type="number"
        data-number
        @change="validateAmount"
      >
      <div class="arrows">
        <button
          class="up"
          @click="performStep('up')"
        />
        <button
          class="down"
          @click="performStep('down')"
        />
      </div>
    </div>

    <div class="recipe__ingredients-groups">
      <div
        v-for="ingredientGroup in scaledIngredientsGroups"
        :key="ingredientGroup.index"
        class="recipe__ingredients-group"
      >
        <h4
          :class="{empty : !ingredientGroup.name}"
          class="recipe__ingredients-group__title"
        >
          {{ ingredientGroup.name }}
        </h4>
        <ul
          class="recipe__ingredients-group__list"
        >
          <li
            v-for="ingredient in ingredientGroup.items"
            :key="ingredient.id"
          >
            <a
              v-if="ingredient.productUrl"
              :href="ingredient.productUrl"
            >
              <span>{{ ingredient.name }}</span>
              <span
                v-if="ingredient.secondaryUnit"
                class="secondary"
              >
                ({{ ingredient.secondaryUnit }})
              </span>
            </a>
            <template v-else>
              <span>{{ ingredient.name }}</span>
              <span
                v-if="ingredient.secondaryUnit"
                class="secondary"
              >
                ({{ ingredient.secondaryUnit }})
              </span>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ingredientScaleHelper from '@/helpers/IngredientScaleHelper'
import EventBus from '@/lib/event-bus'
import GtmHelper from '@/helpers/GtmHelper'

const translations = window.arla.cms.resources.recipes.recipePage

export default {
  props: {
    ingredients: {
      type: Object,
      required: true
    },
    media: {
      type: String,
      default: 'screen'
    }
  },
  data() {
    return {
      minValue: 1,
      maxValue: 10000,
      stepValue: 10,
      portions: this.ingredients.amountUnit ? this.ingredients.amountUnit.amount : null,
      title: translations.ingredientsHeader
    }
  },
  computed: {
    amountText() {
      if (this.ingredients.amountUnit) {
        const portionText = translations.portionsText
        if (this.ingredients.amountIsScalable) {
          let amountUnitText = ''
          if (this.ingredients.amountUnit.amount <= 1) {
            amountUnitText = this.ingredients.amountUnit.singularName
          } else {
            amountUnitText = this.ingredients.amountUnit.pluralName
          }
          if (this.ingredients.amountUnit.singularName !== 'port') {
            return portionText.concat(' (', amountUnitText, ') ')
          }
          return portionText
        }
        let amountText = portionText
        if (this.ingredients.amountUnit.singularName !== 'port') {
          amountText = portionText.concat(' (', ingredientScaleHelper.getIngredientUnitName(this.ingredients.amountUnit), ') ')
        }
        amountText = amountText.concat(':')
        amountText = ingredientScaleHelper.getConcatedString(amountText,
          ingredientScaleHelper.getIngredientAmount(this.ingredients.amountUnit).toLocaleString('sv'))
        return amountText
      }
      return ''
    },
    portionsModel: {
      get() {
        return this.portions
        || (this.ingredients.amountUnit ? this.ingredients.amountUnit.amount : null)
      },
      set(newValue) {
        if (newValue > 0) {
          this.portions = newValue
          EventBus.$emit('changeIngredientsAmount', newValue)
        }
      }
    },
    scaledIngredientsGroups() {
      const scaledIngredientsGroup = []
      for (let i = 0,
        groupLength = this.ingredients.ingredientsGroups.length;
        i < groupLength;
        i += 1) {
        const currentIngredientsGroup = this.ingredients.ingredientsGroups[i]
        const ingredientsGroup = {
          name: currentIngredientsGroup.name,
          index: currentIngredientsGroup.index,
          items: []
        }
        for (let j = 0,
          ingredientsLength = currentIngredientsGroup.items.length;
          j < ingredientsLength;
          j += 1) {
          const currentIngredient = currentIngredientsGroup.items[j]
          const unitAmount = this.ingredients.amountUnit
            ? this.ingredients.amountUnit.amount
            : this.ingredients.amountUnit
          const ingredient = {
            name: ingredientScaleHelper.getFullIngredientName(
              currentIngredient,
              unitAmount,
              this.portions
            ),
            productUrl: currentIngredient.productUrl,
            secondaryUnit: ingredientScaleHelper.getFullSecondaryUnitName(
              currentIngredient.secondaryUnit,
              unitAmount,
              this.portions
            )
          }
          ingredientsGroup.items.push(ingredient)
        }
        scaledIngredientsGroup.push(ingredientsGroup)
      }
      return scaledIngredientsGroup
    }
  },
  mounted() {
    const self = this
    // This is added for print version of recipe details page
    EventBus.$on('changeIngredientsAmount', (value) => {
      if (self.portionsModel !== value) {
        self.portionsModel = value
      }
    })
  },
  methods: {
    changeAmountGtmTracking(direction) {
      return JSON.stringify({
        event: 'recipeScaling',
        changeAmount: direction
      })
    },
    validateAmount(el) {
      GtmHelper.recipeTabsIngredient_changeAmountByInput(el.target.value)
      const val = this.validateAmountValue(el.target.value)

      el.target.value = val
      if (val !== this.portions) {
        this.portions = val
      }
    },
    validateAmountValue(value) {
      if (value < this.minValue) {
        return this.minValue
      }
      if (value > this.maxValue) {
        return this.maxValue
      }
      return value
    },
    performStep(action) {
      if (action === 'up') {
        GtmHelper.pushDataLayer(this.changeAmountGtmTracking('Increase'))
        this.portionsModel = Math
          .floor((this.portionsModel + this.stepValue) / this.stepValue) * this.stepValue
      } else {
        GtmHelper.pushDataLayer(this.changeAmountGtmTracking('Decrease'))
        this.portionsModel = Math
          .ceil((this.portionsModel - this.stepValue) / this.stepValue) * this.stepValue
      }
    }
  }
}
</script>
