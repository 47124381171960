<template>
  <div
    :class="{ 'video-playing': videoIsPlaying }"
    class="generic-video"
  >
    <youtube
      :video-id="videoId"
      :player-width="'100%'"
      :player-height="'100%'"
      :player-vars="playerOptions"
      :mute="mutePlayer"
      :host="'https://www.youtube-nocookie.com'"
      class="generic-video__player"
      @ready="ready"
      @ended="ended"
    />
    <div
      v-if="thumbnail"
      class="generic__video__play-button"
      @click="playVideo"
    />
  </div>
</template>

<script>
export default {
  props: {
    videoId: {
      type: String,
      required: true
    },
    thumbnail: {
      type: String,
      required: false,
      default: null
    },
    ambient: {
      type: Boolean,
      required: false,
      default: false
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      playerOptions: {
        autoplay: this.ambient ? 1 : 0,
        controls: this.ambient ? 0 : 1,
        loop: this.ambient ? 1 : 0,
        iv_load_policy: 3,
        showinfo: 0,
        modestbranding: 0,
        rel: 0
      },
      mutePlayer: this.ambient,
      player: null,
      videoIsPlaying: false
    }
  },
  methods: {
    ready(player) {
      this.player = player.target

      if (this.autoplay) {
        this.playVideo()
      }
    },
    ended() {
      if (this.ambient) {
        this.playVideo()
      } else {
        this.stopVideo()
      }
    },
    playVideo() {
      this.videoIsPlaying = true
      this.mutePlayer = this.ambient
      this.player.playVideo()
    },
    stopVideo() {
      this.videoIsPlaying = false
      this.mutePlayer = true
      this.player.stopVideo()
    }
  }
}
</script>
