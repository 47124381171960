<template>
  <div>
    <component
      :is="state.steps[state.currentStep]"
      :translations="translations"
    />
    <template v-if="state.isDirectLoginType">
      <hr>
      <div class="form--default c-header-sidebar-section">
        <button
          v-if="state.isDirectLoginType"
          class="cta cta--link cta--average"
          @click="onSwitchLoginType(loginText, state.oppositeLoginState)"
        >
          <span v-text="loginText" />
          <IconArrow class="icon ml-xxs" />
        </button>
      </div>
    </template>
    <button
      v-if="showBackButton"
      class="cta-icon-back"
      @click="setCurrentStep(TYPES.intro)"
    >
      <span class="u-hide-on-screenonly">Back</span>
      <IconArrow class="icon" />
    </button>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import LoginIntro from '@/components/login/login-intro.vue'
import LoginPrivate from '@/components/login/login-private.vue'
import useLogin, { TYPES } from '@/compositions/useLogin'
import IconArrow from '@/assets/icons/arrow-right.svg'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    IconArrow,
    LoginIntro,
    LoginPrivate
  },
  props: {
    translations: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const {
      state,
      setCurrentStep
    } = useLogin()

    const {
      loginToBusinessAccountLabel,
      loginToPrivateAccountLabel
    } = props.translations

    const loginText = ref(state.oppositeLoginState === TYPES.intro
      ? loginToBusinessAccountLabel : loginToPrivateAccountLabel)

    const showBackButton = computed(() => state.currentStep !== TYPES.intro)

    const onSwitchLoginType = (buttonText, type) => {
      GtmHelper.logInSwitchType(buttonText, type)
      setCurrentStep(type)
    }

    return {
      state,
      loginText,
      TYPES,
      showBackButton,

      setCurrentStep,
      onSwitchLoginType
    }
  }
}
</script>
