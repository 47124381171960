import { reactive, ref, computed } from 'vue'
import wizardFlowStepNames from '@/compositions/catalogue/wizardFlowStepNames'

const steps = Object.values(wizardFlowStepNames)

const state = reactive({
  steps,
  totalSteps: steps.length
})

const currentStepNumber = ref(1)
const currentStep = computed(() => state.steps[currentStepNumber.value - 1])

export default () => {
  const goToStep = (step) => {
    currentStepNumber.value = step
  }
  const goToNextStep = () => {
    currentStepNumber.value += 1
  }
  const goToPreviousStep = () => {
    currentStepNumber.value -= 1
  }

  return {
    state,
    currentStepNumber,
    currentStep,

    goToStep,
    goToNextStep,
    goToPreviousStep
  }
}
