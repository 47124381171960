<template>
  <picture class="generic-image">
    <source
      v-if="smallImageUrl && smallImageBreakPoint"
      :srcset="smallImageUrl"
      :media="`(max-width: ${ smallImageBreakPoint }px)`"
    >
    <img
      ref="img"
      :src="largeImageUrl"
      :alt="altText"
    >
    <span
      v-if="showOverlay"
      :style="`opacity: ${overlayOpacity/100}`"
      class="generic-image__overlay"
    />
  </picture>
</template>

<script>
export default {
  props: {
    smallImageUrl: {
      type: String,
      default: ''
    },
    largeImageUrl: {
      type: String,
      required: true
    },
    smallImageBreakPoint: {
      type: Number,
      default: 568
    },
    altText: {
      type: String,
      default: 'image'
    },
    overlayOpacity: {
      type: Number,
      default: 0
    }
  },
  computed: {
    showOverlay() {
      return this.overlayOpacity > 0
    }
  }
}
</script>
