export default function (el = '.article-page__section') {
  const intersectedClass = 'intersected'

  const options = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0.6
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const elem = entry.target
        elem.classList.add(intersectedClass)
        observer.unobserve(elem)
      }
    })
  },
  options)

  document.querySelectorAll(el).forEach((item) => {
    observer.observe(item)
  })

  return observer
}
