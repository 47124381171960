<template>
  <form
    class="search-form"
    @submit.prevent="onSubmit"
  >
    <input
      v-model.trim="query"
      :aria-label="placeholder"
      :placeholder="placeholder"
      name="q"
      type="text"
      class="search-form__text-field"
      @blur="onBlur"
    >
    <button
      :class="buttonClass"
      :name="placeholder"
      type="submit"
      class="search-form__submit-button"
    >
      <span class="visuallyhidden">{{ placeholder }}</span>
      <search-icon class="icon" />
    </button>
  </form>
</template>

<script>
import SearchIcon from '@/assets/icons/search.svg'

export default {
  components: {
    SearchIcon
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      query: ''
    }
  },
  methods: {
    onSubmit() {
      const q = this.query.toLowerCase()
      if (q.length > 1) {
        this.$emit('submit', q)
        this.query = ''
      }
    },
    onBlur() {
      this.$emit('blur', this.query)
    }
  }
}
</script>
