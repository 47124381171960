var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{ref:"mainMenu",staticClass:"c-main-menu",class:{
    'is-opened-overlay': _vm.isOverlayVisible,
    'has-no-animation': _vm.isAnimated,
    'is-opened': _vm.isMobileOpened,
    'is-closing': _vm.isClosing
  }},[_c('div',{staticClass:"c-main-menu__overlay",on:{"click":function($event){return _vm.hideOpenedSubmenu()}}}),_c('button',{staticClass:"c-main-menu__button",attrs:{"aria-label":_vm.cmsNavigation.translations ? _vm.cmsNavigation.translations.openNavigation : ''},on:{"click":_vm.toggleButton}},[_vm._m(0)]),(_vm.isSubmenuOpened)?_c('button',{staticClass:"c-main-menu__close-button",on:{"click":_vm.backToMainMenu}},[_c('IconArrowLeft',{staticClass:"c-main-menu__close-button-icon"})],1):_vm._e(),_c('div',{staticClass:"c-main-menu-wrap"},[_c('ul',{ref:"nav",staticClass:"c-main-menu__list u-bare-list"},[(_vm.showFavorites)?_c('li',{staticClass:"c-main-menu__item c-main-menu__favourites-item"},[_c('a',{staticClass:"c-main-menu__item-link",attrs:{"href":_vm.favorites.url},on:{"click":function($event){return _vm.favoritesClickListener($event, _vm.favorites, _vm.favorites.text)}}},[_c('IconBookmark',{staticClass:"icon c-icon-24 c-icon-fill-none mr-10px"}),_vm._v(" "+_vm._s(_vm.favorites.text)+" "),_c('span',{staticClass:"splash-sign splash-sign--counter ml-auto"},[_c('span',{staticClass:"text-icon text-icon--plain"},[_vm._v(" "+_vm._s(_vm.favorites.totalCount)+" ")])])],1)]):_vm._e(),_vm._l((_vm.navigation),function(section,index){return _c('li',{key:section.title,staticClass:"c-main-menu__item",class:{
          'is-active-item': index === _vm.openIndex || section.isSubmenuOpened
        },on:{"mouseenter":function($event){return _vm.onMenuItemHover(section, index)},"mouseleave":function($event){return _vm.onMenuItemLeave(section, index)}}},[_c('a',{staticClass:"c-main-menu__item-link",class:{
            'is-active': section.isActive,
            'is-highlighted': section.isHighlighted
          },attrs:{"href":section.url},on:{"click":function($event){return _vm.onMenuItemClick($event, section, section.title)}}},[_vm._v(" "+_vm._s(section.title)+" "),(section.children.length)?_c('IconArrowLeft',{staticClass:"c-main-menu__item-link-icon"}):_vm._e()],1),_c('SubMenu',{directives:[{name:"show",rawName:"v-show",value:(section.children.length),expression:"section.children.length"}],attrs:{"section":section,"is-active":_vm.isSubmenuOpened && section.isSubmenuOpened}})],1)})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"c-main-menu__button-inner"},[_c('span',{staticClass:"c-main-menu__button-line"}),_c('span',{staticClass:"c-main-menu__button-line"}),_c('span',{staticClass:"c-main-menu__button-line"})])
}]

export { render, staticRenderFns }