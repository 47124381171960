<template>
  <div class="Form__Element FormChoice FormChoice--Checkbox">
    <input
      :id="model.elementGuid"
      type="hidden"
      class="Form__Element FormHidden FormHideInSummarized"
      data-f-type="hidden"
      :name="model.elementName"
      :value="submissionData"
    >
    <label
      for="permission-terms-of-use"
      class="FormChoice__Label"
    >
      <input
        id="permission-terms-of-use"
        v-model="termsAccepted"
        name="termsAccepted"
        class="FormChoice__Input FormChoice__Input--Checkbox"
        type="checkbox"
      >
      <span
        class="FormChoice__Caption"
        v-html="model.permissionBlock.privacyPolicyFormHtml"
      />
      <span class="FormChoice__Indicator" />
    </label>
    <div
      v-if="errorMessage.length"
      class="Form__Element__ValidationError"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const errorMessage = ref('')
    const termsAccepted = ref(false)
    const submissionData = computed(() => JSON.stringify({
      termsAccepted: termsAccepted.value,
      permissionVersion: props.model.permissionBlock.permissionVersion
    }))

    onMounted(() => {
      const forms = window.epi && window.epi.EPiServer && window.epi.EPiServer.Forms
      if (!forms) {
        return
      }

      $.extend(true, forms, {
        Validators: {
          'Arla.CustomerWeb.Cms.Features.Blocks.Components.PermissionBlock.PermissionValidator': (_, __, validator) => {
            const isValid = termsAccepted.value
            errorMessage.value = !isValid ? validator.model.message : ''

            return {
              isValid
            }
          }
        }
      })
    })

    return {
      termsAccepted,
      submissionData,
      errorMessage
    }
  }
}
</script>
