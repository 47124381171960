<template>
  <modal
    :name="modalName"
    :adaptive="true"
    :max-width="500"
    height="auto"
    width="90%"
    reset
    :click-to-close="false"
    class="v--modal"
    @before-open="beforeOpenModal()"
    @before-close="beforeCloseModal()"
  >
    <div class="popup__inner u-text-align--center">
      <div class="popup-header">
        <strong class="popup-header__text h4 mx-auto">
          {{ translations.favoritesModalTitle }}
        </strong>
      </div>
      <div
        class="popup-content"
        v-html="description"
      />
    </div>
    <div class="popup-panel">
      <button
        class="cta cta--transparent"
        @click="onCancel"
      >
        {{ translations.favoritesModalRemoveButton }}
      </button>
      <button
        class="cta"
        @click="onSubmit"
      >
        {{ translations.favoritesModalMigrateButton }}
      </button>
    </div>
  </modal>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import {
  getAllItems,
  migrateToAccount,
  clearStorage, getTotal
} from '@/compositions/favorites/favorites-ls-repository'

import useModal from '@/compositions/useModal'
import useHeaderModel from '@/compositions/useHeaderModel'

export default {
  setup() {
    const modalName = 'favorites-migrate-modal'
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      showPopup,
      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    } = useModal($modal)
    const localFavorites = getAllItems()
    const totalCount = getTotal()
    const loading = ref(false)
    const {
      cmsNavigation
    } = useHeaderModel()
    const { translations } = cmsNavigation.value

    const description = ref(translations?.favoritesModalText?.replace('{count}', totalCount))

    const onSubmit = () => {
      loading.value = true
      migrateToAccount(localFavorites)
        .finally(() => {
          loading.value = false
          hidePopup(modalName)
        })
    }

    const onCancel = () => {
      clearStorage()
      hidePopup(modalName)
    }

    onMounted(() => {
      showPopup(modalName)
    })

    return {
      modalName,
      description,
      translations: cmsNavigation.value.translations,

      beforeOpenModal,
      beforeCloseModal,
      onSubmit,
      onCancel
    }
  }
}
</script>
