<script>
import {
  ref, reactive, computed, getCurrentInstance
} from 'vue'

import ImageModal from '@/components/orderMaterials/order-material-image-modal.vue'
import useOrderMaterial from '@/compositions/orderMaterials/useOrderMaterial'
import Tooltip from '@/components/tooltip.vue'
import useModal from '@/compositions/useModal'
import useImage from '@/compositions/useImage'
import epiTracking from '@/helpers/epi-tracking'
import gtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    ImageModal,
    Tooltip
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    queryTrackId: {
      type: String,
      default: ''
    },
    queryTrackType: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const translations = window.arla.cms.resources.orderMaterials
    const price = computed(() => (props.model.price ? `${props.model.price} kr` : translations.card.freePrice))
    const { getImageUrl } = useImage()
    const smallImageUrl = computed(() => getImageUrl(props.model.image
      || translations.fallbackImage, 300))
    const largeImageUrl = computed(() => getImageUrl(props.model.image
      || translations.fallbackImage, 600))

    const {
      queryTrackType,
      queryTrackId,
      position,
      model: { name, hitId }
    } = props

    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const epiTrackingData = {
      queryTrackType,
      queryTrackId,
      position,
      hitId
    }
    const { showPopup } = useModal($modal)
    const orderMaterialClick = () => {
      showPopup(props.model.id)
      epiTracking.trackCardItemClick(epiTrackingData)
    }

    const { getAmountAddedToCart, addToCart, removeFromCart } = useOrderMaterial()
    const state = reactive({
      ...props.model,
      amount: getAmountAddedToCart(props.model.id)
    })
    let prevAmount = state.amount
    const hasError = ref(false)
    const notEnoughInStock = ref(false)
    const outOfStock = computed(() => !props.model.sellableNumberOfItems)
    const notEnoughInStockText = translations.moreThanInStockTooltip.content.replace('{count}', `<strong>${props.model.sellableNumberOfItems}</strong>`)
    const tooltipModel = computed(() => ({
      outOfStock: {
        title: translations.outOfStockTooltip.title,
        content: translations.outOfStockTooltip.content
      },
      notEnoughInStock: {
        content: notEnoughInStockText
      }
    }))
    const updateCart = () => {
      if (typeof state.amount !== 'number') {
        hasError.value = true
      } else if (state.amount > props.model.sellableNumberOfItems) {
        hasError.value = true
        notEnoughInStock.value = true

        setTimeout(() => {
          notEnoughInStock.value = false
        }, 4000)
      } else {
        const cartItemIsAdded = prevAmount === null
        const cartItemIsDeleted = state.amount === 0
        hasError.value = false
        notEnoughInStock.value = false

        if (cartItemIsDeleted) {
          removeFromCart(state)
          gtmHelper.materialChangeAmount(name, prevAmount)
        } else {
          if (cartItemIsAdded) {
            gtmHelper.materialAddToCart(name, state.amount)
          } else {
            const quantity = state.amount - prevAmount
            gtmHelper.materialChangeAmount(name, Math.abs(quantity))
          }

          addToCart(state)
          prevAmount = state.amount
        }
      }
    }

    const img = ref(null)

    return {
      state,
      price,
      updateCart,
      smallImageUrl,
      largeImageUrl,
      translations,
      hasError,
      orderMaterialClick,
      notEnoughInStock,
      outOfStock,
      tooltipModel,
      img
    }
  }
}
</script>
