<template>
  <div>
    <div class="c-emissions-block">
      <span>
        <strong>{{ model.portion }} Kg</strong>
        {{ model.scaleText }}
      </span>
      <div class="c-rating">
        <IconStarFilled class="icon" />
        <IconStarFilled
          v-if="model.starsCount === 3 || model.starsCount === 2"
          class="icon"
        />
        <IconStarEmpty
          v-else
          class="icon"
        />
        <IconStarFilled
          v-if="model.starsCount === 3"
          class="icon"
        />
        <IconStarEmpty
          v-else
          class="icon"
        />
      </div>
    </div>
    <div class="hide--print">
      <h3 class="base-font--sm">
        {{ model.header }}
      </h3>
      <div
        v-if="model.description"
        class="sub-font"
        v-html="model.description"
      />
      <a
        v-if="model.linkUrl"
        :href="model.linkUrl"
      >
        <span class="u-text-underline mr-5px">{{ model.linkText }}</span>
        <IconArrow
          class="icon"
          width="12px"
          height="8px"
        />
      </a>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/arrow-right.svg'
import IconStarEmpty from '@/assets/icons/star--empty.svg'
import IconStarFilled from '@/assets/icons/star--filled.svg'

export default {
  components: {
    IconArrow,
    IconStarEmpty,
    IconStarFilled
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
