<template>
  <div class="card-list__header">
    <div
      class="card-list__count"
      v-html="totalCountText"
    />
    <div class="card-list__controls">
      <div
        v-if="sortingOptions.length"
        class="filter-dropdown-warp"
      >
        <filter-dropdown
          :filters="sortingOptions"
          :selected="sorting"
          title="Sortera"
          @chooseFilter="setSorting"
        />
      </div>
    </div>
  </div>
</template>
<script>

import { computed } from 'vue'
import FilterDropdown from '@/components/filter-dropdown.vue'

import favoritesQueryState from '@/compositions/favorites/favorites-query'
import favoritesTabsConfig from '@/compositions/favorites/favorites-tabs-configuration'
import favoritesRepository from '@/compositions/favorites/favorites-repository'

export default {
  components: {
    FilterDropdown
  },
  setup() {
    const { getActiveConfig } = favoritesTabsConfig.favoritesTabsConfigurationUse()

    const {
      favoritesQuery,
      setSorting
    } = favoritesQueryState.favoritesQueryUse()

    const { totalCount } = favoritesRepository.useFavoritesResult()
    const sorting = computed(() => {
      if (favoritesQuery.sorting) {
        return favoritesQuery.sorting
      }

      return getActiveConfig().sortingOptions.find((x) => x.default).value
    })
    const totalCountText = computed(() => {
      const { totalCountMessageDesktop } = getActiveConfig().translations
      return totalCountMessageDesktop.replace('{count}', `<strong>${totalCount.value}</strong>`)
    })

    return {
      sorting,

      sortingOptions: computed(() => getActiveConfig().sortingOptions),
      totalCountText,

      setSorting
    }
  }
}
</script>
