import GtmHelper from '@/helpers/GtmHelper'

const videos = document.querySelectorAll('#page-content .article-page__section video')

const trackVideo = (video) => {
  let videoMilestones = [10, 25, 50, 75, 90]
  let duration = 0
  const videoTitle = decodeURIComponent(video.currentSrc.split('/').pop().split('.')[0])

  video.addEventListener('loadedmetadata', () => {
    duration = video.duration
  })
  video.addEventListener('play', () => {
    GtmHelper.videoStart(videoTitle)
  }, { once: true })
  video.addEventListener('timeupdate', () => {
    const videoPercent = Math.round((video.currentTime / duration) * 100)
    if (videoMilestones.includes(videoPercent)) {
      GtmHelper.videoProgress({
        videoTitle,
        videoPercent: videoPercent.toString()
      })
      videoMilestones = videoMilestones.filter((milestone) => milestone !== videoPercent)
    }
  })
  video.addEventListener('ended', () => {
    GtmHelper.videoComplete(videoTitle)
  }, { once: true })
}

export default () => {
  if (!videos?.length) {
    return
  }
  videos.forEach(trackVideo)
}
