<template>
  <div class="media-block media-block--background">
    <div
      v-if="model.youtubeVideoId"
      class="item-text"
    >
      <h2 class="item-title">
        {{ model.videoHeading }}
      </h2>
      <p>{{ model.videoDescription }}</p>
    </div>
    <div class="item-media">
      <template v-if="model.youtubeVideoId">
        <generic-video
          :video-id="model.youtubeVideoId"
          :ambient="false"
          class="item-video"
        />
        <div
          v-if="model.images.length"
          class="item-image"
        >
          <generic-image
            :small-image-url="model.images[0].mobileImageUrl"
            :large-image-url="model.images[0].desktopImageUrl"
            :small-image-break-point="breakPoint"
          />
        </div>
      </template>

      <template v-else>
        <div class="item-image">
          <generic-image :large-image-url="model.images[0].mobileImageUrl" />
        </div>

        <div
          v-if="model.images[1]"
          class="item-image"
        >
          <generic-image
            :small-image-url="model.images[1].mobileImageUrl"
            :large-image-url="model.images[1].desktopImageUrl"
            :small-image-break-point="breakPoint"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      breakPoint: 768
    }
  }
}
</script>
