import { reactive, computed } from 'vue'

const state = reactive({
  initiated: false,
  userType: ''
})
const isConnectAuth = computed(() => state.userType === 'connect')
const isSupAuth = computed(() => state.userType === 'sup')
const isBusinessAuth = computed(() => isConnectAuth.value)
const isAuth = computed(() => state.userType)

export default () => {
  const setAuth = (userType) => {
    state.initiated = true
    state.userType = userType
    if (userType) {
      document.body.classList.add(`${userType}-authenticated`)
    }
  }

  return {
    initiated: computed(() => state.initiated),
    isBusinessAuth,
    isConnectAuth,
    isSupAuth,
    isAuth,

    setAuth
  }
}
