<template>
  <active-filters
    v-if="activeCategories.length"
    :filters="activeCategories"
    :clear-filters-text="clearFilters"
    @removeFilter="removeCategory"
    @clearFilters="removeAllCategories"
  />
</template>

<script>
import ActiveFilters from '@/components/active-filters.vue'
import searchQueryState from '@/compositions/search/search-query'

export default {
  components: {
    ActiveFilters
  },
  setup() {
    const { clearFilters = '' } = window.arla.cms.resources.search
    const {
      activeCategories,

      removeCategory,
      removeAllCategories
    } = searchQueryState.searchQueryUse()

    return {
      clearFilters,
      activeCategories,

      removeCategory,
      removeAllCategories
    }
  }
}
</script>
