<template>
  <div class="search-dropdown">
    <section
      v-for="searchResult in searchResults"
      :key="searchResult.type"
      class="search-dropdown__section"
    >
      <a
        :href="searchPageUrl(searchResult.type)"
        class="search-dropdown__section-header"
        @click="gtmTrackClick(searchResult.typeLabel, searchResult.type)"
      >
        <div>
          <strong>{{ searchResult.typeLabel }}</strong>&nbsp;({{ searchResult.totalCount }})
        </div>
        <ArrowIcon class="icon" />
      </a>

      <ul class="search-dropdown__result-list u-bare-list">
        <li
          v-for="(item, index) in searchResult.items"
          :key="index"
        >
          <a
            :href="item.url"
            class="search-dropdown__header-link"
            @click="itemClick(searchResult, index, item.name)"
          >
            <span v-html="item.name" />
          </a>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import quickSearchRepository from '@/compositions/search/quick-search-repository'
import epiTracking from '@/helpers/epi-tracking'
import ArrowIcon from '@/assets/icons/arrow-right.svg'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    ArrowIcon
  },
  setup() {
    const { searchResults, term } = quickSearchRepository.quickSearchUse()

    const gtmTrackClick = (itemLabel, selectionType) => {
      GtmHelper.mainMenuSearchItemClick({
        searchItemTitle: itemLabel.replace(/(<([^>]+)>)/ig, ''),
        selectionType,
        searchQuery: term.value
      })
    }

    const itemClick = (searchResult, index, itemName) => {
      const item = searchResult.items[index]
      const data = {
        trackId: searchResult.trackId,
        hitId: item.hitId,
        searchType: searchResult.type,
        query: term.value,
        position: index + 1,
        totalCount: searchResult.totalCount
      }
      epiTracking.trackAutoSuggestionClick(data)
      gtmTrackClick(itemName, searchResult.type)
    }

    const searchPageUrl = (type) => `/sok/${type}?query=${term.value}`

    return {
      itemClick,
      gtmTrackClick,

      searchResults,
      searchPageUrl
    }
  }
}
</script>
