<template>
  <div>
    <div
      v-for="{ model, type } in unreadMessages"
      :key="type"
      :class="getMessageCssClass(type)"
      class="site-message"
    >
      <div class="site-message-container">
        <strong class="site-message-title">
          <InfoIcon class="icon" />
          {{ model.subject }}
          <span>{{ model.dateFormatted }}</span>
        </strong>
        <div v-html="getAdjustedBody(model.body)" />
        <span
          role="button"
          class="site-message-close-button js-message-close"
          @click.prevent="onClose(type)"
        >
          Stänga
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import InfoIcon from '@/assets/icons/information-circle--transparent.svg'
import api from '@/lib/api'
import useHeaderModel from '@/compositions/useHeaderModel'

export default {
  components: {
    InfoIcon
  },
  setup() {
    const {
      messages
    } = useHeaderModel()

    const unreadMessages = computed(() => (
      messages.value
        ? Object.values(messages.value).filter((item) => item && !item.isRead)
        : []
    ))

    return {
      messages,
      unreadMessages
    }
  },
  methods: {
    onClose(type) {
      const message = this.messages[type]
      message.isRead = true
      api.markMessageRead(message.id)
    },
    getMessageCssClass(type) {
      return type === 'alertMessage' ? 'site-message--error' : 'site-message--info'
    },
    getAdjustedBody(body) {
      if (body === undefined) return ''

      const buttonLink = body.match(/http\S+$/g)
      if (buttonLink === null) {
        return body
      }

      const formattedBody = body.replace(buttonLink[0], '')
      const buttonCode = document.createElement('a')
      // eslint-disable-next-line prefer-destructuring
      buttonCode.href = buttonLink[0]
      buttonCode.classList.add('site-message__button')
      buttonCode.text = 'Klicka här'
      return `${formattedBody}${buttonCode.outerHTML}`
    }
  }
}
</script>
