import { computed, reactive } from 'vue'

import useStorage from '@/compositions/useStorage'

const STORAGE_NAME = 'order-materials'

const storage = useStorage(STORAGE_NAME)
if (!storage.value) {
  storage.value = []
}

let itemsData = []

const cartSummary = computed(() => {
  let sum = 0
  let totalPrice = 0
  storage.value.forEach(({ amount, price = 0 }) => {
    sum += amount
    totalPrice += price
  })

  return {
    count: storage.value.length,
    sum,
    totalPrice
  }
})

const submitState = reactive({
  loading: false,
  isSubmitted: false,
  hasError: false,
  submittedPrice: null
})

export default () => {
  const getItemIds = () => storage.value.map(({ id }) => id)

  const getItems = () => storage.value

  const getAmountAddedToCart = (id) => {
    const itemIndex = storage.value.find((item) => item.id === id)

    return itemIndex ? itemIndex.amount : null
  }

  const addToCart = ({
    id,
    amount,
    price,
    name
  }) => {
    const itemIndex = storage.value.findIndex((item) => item.id === id)
    if (itemIndex === -1) {
      storage.value.push({
        id,
        amount,
        price,
        name
      })
    } else {
      storage.value[itemIndex].amount = amount
      storage.value[itemIndex].price = price
    }
  }

  const removeFromCart = ({ id }) => {
    const itemIndex = storage.value.findIndex((item) => item.id === id)
    if (itemIndex !== -1) {
      storage.value.splice(itemIndex, 1)
    }
  }

  const clearCart = () => {
    storage.value = []
  }

  const updateItemsData = (data = []) => {
    itemsData = data
  }

  const updateSubmitState = ({
    isSubmitted = submitState.isSubmitted,
    hasError = submitState.hasError,
    loading = submitState.loading,
    submittedPrice = submitState.submittedPrice
  }) => {
    Object.assign(submitState, {
      loading,
      isSubmitted,
      hasError,
      submittedPrice
    })
  }

  return {
    items: storage,
    itemsData,
    cartSummary,
    submitState,
    getItems,
    getItemIds,
    getAmountAddedToCart,
    addToCart,
    removeFromCart,
    updateItemsData,
    updateSubmitState,
    clearCart
  }
}
