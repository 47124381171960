export default {
  getEmptyUnit() {
    return 2124600858
  },

  getFullSecondaryUnitName(unit, basePortions, newPortions) {
    if (!unit) return ''

    let unitText = ''
    let recalculatedUnit = JSON.parse(JSON.stringify(unit))
    if (recalculatedUnit && recalculatedUnit.amount > 0) {
      recalculatedUnit = this.calculateUnitAmount(recalculatedUnit, basePortions, newPortions)
    }

    unitText = this.getConcatedString(unitText,
      this.getIngredientAmount(recalculatedUnit).toLocaleString('sv'))
    unitText = this.getConcatedString(unitText,
      this.getIngredientUnitName(recalculatedUnit))
    unitText = this.getConcatedString(unitText, recalculatedUnit.postfix)

    return unitText
  },

  getFullIngredientName(ingredient, basePortions, newPortions) {
    let ingredientName = ''

    let recalculatedUnit = JSON.parse(JSON.stringify(ingredient.unit))
    if (recalculatedUnit && recalculatedUnit.amount > 0) {
      recalculatedUnit = this.calculateUnitAmount(recalculatedUnit, basePortions, newPortions)
    }

    ingredientName = this.getConcatedString(ingredientName, recalculatedUnit.prefix)
    ingredientName = this.getConcatedString(ingredientName,
      this.getIngredientAmount(recalculatedUnit).toLocaleString('sv'))
    ingredientName = this.getConcatedString(ingredientName,
      this.getIngredientUnitName(recalculatedUnit))

    ingredientName = this.getConcatedString(ingredientName, ingredient.prefix)
    ingredientName = this.getConcatedString(ingredientName,
      this.getIngredientName(ingredient, recalculatedUnit))

    ingredientName = this.getConcatedString(ingredientName, ingredient.postfix)

    return ingredientName
  },

  calculateUnitAmount(unit, basePortions, newPortions) {
    const hasBasePortions = basePortions !== null
    let calcAmount = Number(unit.amount)
    let recalculate = false
    if (hasBasePortions) {
      calcAmount = (unit.amount * newPortions) / basePortions

      if (unit.singularName === 'g' && calcAmount >= 1000) {
        unit.amount /= 1000
        unit.singularName = 'kg'
        unit.pluralName = 'kg'
        recalculate = true
      } else if (unit.singularName === 'kg' && calcAmount < 1) {
        unit.amount *= 1000
        unit.singularName = 'g'
        unit.pluralName = 'g'
        recalculate = true
      } else if (unit.singularName === 'dl' && calcAmount >= 10) {
        unit.amount /= 10
        unit.singularName = 'l'
        unit.pluralName = 'l'
        recalculate = true
      } else if (unit.singularName === 'l' && calcAmount < 1) {
        unit.amount *= 10
        unit.singularName = 'dl'
        unit.pluralName = 'dl'
        recalculate = true
      }
    }

    if (recalculate) {
      unit.amount = hasBasePortions ? (unit.amount * newPortions) / basePortions : unit.amount
    } else {
      unit.amount = calcAmount
    }

    return unit
  },

  getIngredientAmount(unit) {
    if (unit.id === this.getEmptyUnit()) {
      return ''
    }
    if (unit.amount === 0) {
      return ''
    }
    return unit.amount
  },

  getIngredientUnitName(unit) {
    if (unit.id === this.getEmptyUnit()) {
      return ''
    }

    if (unit.unitType === 'Number') {
      return ''
    }

    if (unit.amount <= 1 || unit.pluralName === null || unit.pluralName === '') {
      return unit.singularName
    }

    return unit.pluralName
  },

  getIngredientName(ingredient, unit) {
    if (ingredient.singularName === null || ingredient.singularName === '') {
      return ingredient.pluralName
    }

    if (ingredient.pluralName === null || ingredient.pluralName === '') {
      return ingredient.singularName
    }

    if (ingredient.unit.id === this.getEmptyUnit()) {
      return ingredient.pluralName
    }

    const simpleTypes = ['Volume', 'Weight', 'Piece', 'Other']
    if (simpleTypes.indexOf(ingredient.unit.unitType) !== -1) {
      return ingredient.massNoun ? ingredient.singularName : ingredient.pluralName
    }

    if (unit.unitType === 'Number') {
      return this.amountIsNonFractionPlural(unit.amount)
        ? ingredient.pluralName : ingredient.singularName
    }

    return ingredient.singularName
  },

  getConcatedString(str1, str2) {
    if (str1 === null || str1 === '') {
      return str2
    }
    if (str2 === null || str2 === '') {
      return str1
    }
    if (str2.startsWith(',')) {
      return str1.concat(str2)
    }
    return str1.concat(' ', str2)
  },

  amountIsSingular(amount) {
    return (amount !== null && amount <= 1)
  },

  amountIsFraction(amount) {
    return (amount !== null && amount % 1 !== 0)
  },

  amountIsNonFractionPlural(amount) {
    const floatAmount = parseFloat(amount)
    return (!this.amountIsSingular(floatAmount) && !this.amountIsFraction(floatAmount))
  }
}
