/* eslint-disable import/no-duplicates */

import { reactive } from 'vue'
import api from '@/lib/api'

const state = reactive({
  stores: [],
  userMenu: [],
  selectedStore: null,
  loading: false,
  userName: ''
})

export default () => {
  const setUserName = (name) => {
    state.userName = name
  }

  const setStores = (stores) => {
    state.stores = stores
    state.selectedStore = stores.find((item) => item.isSelected)
  }

  const setUserMenu = (userMenu) => {
    state.userMenu = userMenu
  }

  const selectStore = (id) => api.selectConnectStore(id)
    .then(() => {
      window.location.reload()
    })
    .catch((error) => {
      console.error(error)
    })
    .then(() => {
      state.loading = false
    })

  return {
    state,

    setStores,
    selectStore,
    setUserMenu,
    setUserName
  }
}
