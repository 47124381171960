<template>
  <tr
    ref="root"
    class="order-material-table-item product-table-item"
  >
    <td
      class="cell--name"
    >
      <div class="product-table-item__info">
        <span
          :class="{ 'img-placeholder': !model.image}"
          class="product-image"
          v-on="model.image ? { click: orderMaterialClick } : {}"
        >
          <img
            ref="img"
            :src="smallImageUrl"
            :alt="model.name"
            class="product-image__thumbnail"
          >
        </span>
        <span class="product-table-item__info__text">
          <span class="product-table-item__title">{{ model.name }}</span>
          <span
            v-if="model.description"
            class="product-table-item__subtitle"
          >
            {{ model.description }}
          </span>
        </span>
      </div>

      <image-modal
        v-if="model.image"
        :name="model.name"
        :image="largeImageUrl"
        :modal-name="model.id"
      />

      <div
        v-if="isMobile"
        class="item-card__controls item-card__controls--mobile"
      >
        <input
          v-model.number="state.amount"
          v-tooltip="{
            content: tooltipModel.notEnoughInStock.content,
            show: notEnoughInStock,
            autoHide: false,
            placement: 'top-start',
            trigger: 'manual',
            classes : 'error'
          }"
          :class="{ invalid: hasError }"
          :disabled="outOfStock"
          min="0"
          type="number"
          class="item-card__amount-field"
          aria-label="Amount of product"
          @focus="hasError = false"
          @keyup.enter="updateCart"
        >
        <span class="item-card__units">SP</span>
        <span>{{ price }}</span>
        <Tooltip
          v-if="outOfStock"
          :model="tooltipModel.outOfStock"
        />
        <button
          :disabled="outOfStock"
          class="item-card__cta"
          @click.prevent="updateCart"
        >
          {{ translations.card.addToBasket }}
        </button>
      </div>
    </td>

    <td v-if="!isMobile">
      <div class="u-flex">
        <span class="product-table-item__subtitle">{{ price }}</span>
        <Tooltip
          v-if="outOfStock"
          :model="tooltipModel.outOfStock"
        />
      </div>
    </td>

    <td
      v-if="!isMobile"
      class="cell--controls"
    >
      <div class="item-card__controls">
        <input
          v-model.number="state.amount"
          v-tooltip="{
            content: tooltipModel.notEnoughInStock.content,
            show: notEnoughInStock,
            autoHide: false,
            placement: 'top-start',
            trigger: 'manual',
            classes : 'error'
          }"
          :class="{ invalid: hasError }"
          :disabled="outOfStock"
          min="0"
          type="number"
          class="item-card__amount-field"
          aria-label="Amount of product"
          @focus="hasError = false"
          @keyup.enter="updateCart"
        >
        <span class="item-card__units">SP</span>
        <button
          :disabled="outOfStock"
          class="item-card__cta"
          @click.prevent="updateCart"
        >
          {{ translations.card.addToBasket }}
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import BaseItem from '@/components/orderMaterials/order-material-item.vue'

const mobileBreakpoint = 768
export default {
  extends: BaseItem,
  data() {
    return {
      isMobile: window.innerWidth < mobileBreakpoint
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('orientationchange', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('orientationchange', this.onResize)
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < mobileBreakpoint
    }
  }
}
</script>
