<template>
  <div
    v-if="isScreenLockSupported"
    class="c-screen-lock"
    :class="{
      'is-locked': isLocked
    }"
  >
    <span>
      {{ model.title }}
    </span>
    <div class="u-flex u-flex-align-center">
      <button
        :aria-label="model.text"
        class="cta--clear c-toggler c-toggler--gray"
        @click="toggle"
      />
      <span>
        {{ model.text }}
      </span>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { isScreenLockSupported, lockScreen, unlockScreen } from '@/helpers/screen-lock'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    trackingTitle: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const isLocked = ref(false)

    const toggle = async () => {
      if (!isLocked.value) {
        await lockScreen(() => {
          isLocked.value = false
        })
        isLocked.value = true
        GtmHelper.cookToggle('enable', props.trackingTitle)
      } else {
        await unlockScreen()
        isLocked.value = false
        GtmHelper.cookToggle('disable', props.trackingTitle)
      }
    }

    return {
      isLocked,
      isScreenLockSupported,

      toggle
    }
  }
}
</script>
