import { hem } from '@/helpers/hem'
import GtmHelper from '@/helpers/GtmHelper'

window.newsletterSignupGeneralTracking = () => {
  GtmHelper.newsletterSignupGeneral(hem.value, 'download report')
}

export default function () {
  this.initialize = function () {
    this.rangeInputChange()
    this.fileUploadEvent()

    if (typeof $$epiforms !== 'undefined') {
      this.adjustDateTimePicker()
      $$epiforms('.EPiServerForms')
        .on('formsSubmitted formsSubmittedError', (e) => {
          const formElement = e.currentTarget
          const formOffsetTop = window.pageYOffset + formElement.getBoundingClientRect().top - 150
          window.scrollTo(0, formOffsetTop)
        })
        .on('formsSubmitted', (e) => {
          const formElement = e.currentTarget
          const formName = formElement.dataset?.fMetadata
          if (formName) {
            GtmHelper.pushDataLayer({
              event: 'formSubmit',
              formName,
              sfmcHem: hem.value
            })
          }
        })
        .filter('[data-f-metadata="signup"]')
        .on('formsSubmitted', () => {
          GtmHelper.pushDataLayer({
            event: 'newsletterSignupBecomeCustomer',
            sfmcHem: hem.value
          })
        })
        .on('formsSubmittedError', () => {
          GtmHelper.pushDataLayer({
            event: 'becomeCustomerFailed',
            sfmcHem: hem.value
          })
        })
    }
  }

  this.adjustDateTimePicker = () => {
    if ($$epiforms.datetimepicker) {
      $$epiforms.datetimepicker._defaults.onSelect = () => {
        $$epiforms.datetimepicker._hideDatepicker()
      }
    }
  }

  this.rangeInputChange = function () {
    const self = this
    const $input = $('.FormRange__Input')
    const $output = $('.FormRange__Output')

    if ($output.length > 0) {
      $output.addClass('is-visible')
    }

    self.moveRangeNumber($input, $output)

    $input.on('input', function () {
      const $currentInput = $(this)
      const $currentOutput = $currentInput.next('.FormRange__Output')
      self.moveRangeNumber($currentInput, $currentOutput)
    }).trigger('change')
  }

  this.moveRangeNumber = function ($input, $output) {
    if ($input.length > 0 && $output.length > 0) {
      const handleSize = 24
      let newPlace

      // Measure width of range input
      const width = $input.width() - handleSize

      // Figure out placement percentage between left and right of input
      const newPoint = ($input.val() - $input.attr('min')) / ($input.attr('max') - $input.attr('min'))

      // Prevent bubble from going beyond left or right (unsupported browsers)
      if (newPoint < 0) {
        newPlace = 0
      } else if (newPoint > 1) {
        newPlace = width
      } else {
        newPlace = width * newPoint
      }

      // Move bubble
      if ($output.length > 0) {
        $output
          .css({
            left: newPlace
          })
          .text($input.val())
      }
    }
  }

  this.fileUploadEvent = function () {
    $('.FormFileUpload').on('change', '.FormFileUpload__Input', (e) => {
      const fileContainer = e.delegateTarget
      const fileName = e.currentTarget.value.split('\\').pop()
      const postedFile = fileContainer.querySelector('.FormFileUpload__PostedFile')
      if (postedFile) {
        postedFile.innerHTML = `<span>Du har valt att ladda upp:</span> ${fileName}`
        fileContainer.classList.add('has-file')
      }
    })
  }
}
