<template>
  <div class="card-list__controls--mobile u-hidden--desktop">
    <button
      class="cta cta--dark"
      @click="openFilterMenu"
      v-text="filterText"
    />
    <slot />
  </div>
</template>
<script>
import { computed } from 'vue'

import searchQueryState from '@/compositions/search/search-query'
import useFilters from '@/compositions/useFilters'

export default {
  setup() {
    const { filter: filterText } = window.arla.cms.resources.search
    const {
      activeCategories
    } = searchQueryState.searchQueryUse()
    const { openFilterMenu } = useFilters()

    return {
      filterText,
      filtersExist: computed(() => activeCategories.value.length > 0),

      openFilterMenu
    }
  }
}
</script>
