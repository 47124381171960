import debounce from '@/helpers/debounce'
import GtmHelper from '@/helpers/GtmHelper'

window.gtmTrackScrollBlock = debounce((element, data = '') => {
  if (!data) { return }
  if (typeof element.oldScrollLeft === 'undefined') { element.oldScrollLeft = 0 }

  const direction = element.scrollLeft > element.oldScrollLeft ? 'forward' : 'backward'
  element.oldScrollLeft = element.scrollLeft

  GtmHelper.pushDataLayer({
    ...data,
    direction
  })
}, 300)

export default function (container = document) {
  const triggerLabel = 'gtm-trigger'
  const triggers = container.querySelectorAll(`[data-${triggerLabel}]`)

  if (!triggers.length) { return }

  const handleTracking = (element) => {
    const data = element.dataset

    if (!data.gtmTracking) {
      return
    }

    GtmHelper.pushDataLayer(JSON.parse(data.gtmTracking))
  }

  const handleChangeEvent = (trigger) => {
    if (trigger.matches(':radio') || trigger.matches(':checkbox')) {
      if (trigger.matches(':checked')) {
        handleTracking(trigger)
      }
    } else {
      handleTracking(trigger)
    }
  }

  const handleSearchEvent = (trigger) => {
    const data = trigger.dataset
    const gtmTracking = JSON.parse(data.gtmTracking)
    const { searchFieldName } = data
    const searchField = trigger.querySelector(`[name=${searchFieldName}]`)

    if (!searchField.length) {
      return
    }

    trigger.dataset.gtmTracking = JSON.stringify({
      ...gtmTracking,
      searchTerm: searchField.value
    })
    handleTracking(trigger)
  }

  triggers.forEach((trigger) => {
    let eventHandlerFunction
    const triggerEvent = trigger.getAttribute(`data-${triggerLabel}`)
    switch (triggerEvent) {
      case 'change':
        eventHandlerFunction = handleChangeEvent
        break
      case 'search':
        eventHandlerFunction = handleSearchEvent
        break
      default:
        eventHandlerFunction = handleTracking
    }

    trigger.addEventListener(triggerEvent, () => {
      eventHandlerFunction(trigger)
    }, false)
  })
}
