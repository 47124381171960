import { reactive, toRefs } from 'vue'
import api from '@/lib/api'
import useMiniCart from '@/compositions/useMiniCart'
import useConnect from '@/compositions/useConnect'

const state = reactive({
  cmsNavigation: {},
  notifications: null,
  connectUserMenu: {
    title: 'Mina sidor',
    url: '',
    children: [],
    isActive: false,
    isHighlighted: true,
    slug: 'mina-sidor'
  },
  messages: {}
})

export default () => {
  const {
    setStores,
    setUserMenu,
    setUserName
  } = useConnect()

  const fetchCmsNavigation = () => {
    if (window.cmsNavigation) {
      state.cmsNavigation = { ...window.cmsNavigation }
      return Promise.resolve()
    }

    return api.getCmsNavigation()
      .then(({ data }) => {
        state.cmsNavigation = data
      })
  }

  const setUserNavigation = (data) => {
    if (data.miniCart) {
      const {
        updateMiniCartState
      } = useMiniCart()

      updateMiniCartState(data.miniCart)
    }
    if (data.userMenu) {
      setUserMenu(data.userMenu)
    }
    if (data.menuItem) {
      data.menuItem.isHighlighted = true
      state.connectUserMenu = data.menuItem
    }
    if (data.stores) {
      setStores(data.stores)
    }
    if (data.userName) {
      setUserName(data.userName)
    }
    if (data.messages) {
      state.messages = data.messages
    }
    if (data.notifications) {
      state.notifications = data.notifications
    }
  }

  return {
    ...toRefs(state),

    setUserNavigation,
    fetchCmsNavigation
  }
}
