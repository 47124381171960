import { computed, reactive } from 'vue'
import gtmHelper from '@/helpers/GtmHelper'

const searchQueryState = reactive({
  take: 18,
  page: 1,
  terms: [],
  searchType: null,
  searchTypes: [],
  sorting: null,
  view: null,
  activeCategories: [],
  catalogueId: null,

  updated: Date.now(),
  initialized: false
})

let trackingPrefix = 'search'
const setTrackingPrefix = (newPrefix) => {
  trackingPrefix = newPrefix
}

export default {
  searchQueryUse(routeData) {
    const state = searchQueryState

    const activeCategoriesNames = computed(() => state.activeCategories.map(({ name }) => name))

    const activeTermsAndFilters = computed(() => {
      const filters = activeCategoriesNames.value.map((name) => {
        const type = 'filter'
        return {
          name,
          type
        }
      })
      const terms = state.terms.map((name) => {
        const type = 'term'
        return {
          name,
          type
        }
      })
      return [...filters, ...terms]
    })

    const resetLastUpdated = () => {
      state.updated = Date.now()
    }

    if (!state.initialized && routeData) {
      state.activeCategories = routeData.activeCategories || []
      state.searchType = routeData.searchType
      state.terms = routeData.terms || []
      state.sorting = routeData.sorting
      state.view = routeData.view
      state.catalogueId = routeData.catalogueId
      state.initialized = true
      resetLastUpdated()
    }

    const setAllSearchTypes = (searchTypes) => {
      state.searchTypes = searchTypes
    }

    const setSearchType = (searchType) => {
      if (state.searchType === searchType) {
        return
      }

      state.searchType = searchType

      state.page = 1
      state.sorting = null
      state.view = null
      state.activeCategories = []

      resetLastUpdated()
    }

    const setSorting = (sorting) => {
      if (state.sorting === sorting) {
        return
      }

      state.sorting = sorting
      gtmHelper.changeSorting(sorting, trackingPrefix)
      resetLastUpdated()
    }

    const setView = (view) => {
      if (state.view === view) {
        return
      }

      gtmHelper.changeView(view, trackingPrefix)
      state.view = view
    }

    const setActiveCategories = (activeCategories) => {
      state.activeCategories = activeCategories || []
      resetLastUpdated()
    }

    const addCategory = (category) => {
      const index = state.activeCategories.findIndex(({ slug }) => slug === category.slug)

      if (index === -1) {
        const tempArray = state.activeCategories
        tempArray.push(category)
        setActiveCategories(tempArray)
        setTimeout(() => {
          gtmHelper.addFilter(activeCategoriesNames.value, category.name, trackingPrefix)
        })
      }
    }

    const removeCategory = (category) => {
      const index = state.activeCategories.findIndex(({ slug }) => slug === category.slug)

      if (index > -1) {
        const tempArray = state.activeCategories
        gtmHelper.removeFilter(activeCategoriesNames.value, category.name, trackingPrefix)
        tempArray.splice(index, 1)
        setActiveCategories(tempArray)
      }
    }

    const removeAllCategories = () => {
      gtmHelper.removeAllFilter(activeCategoriesNames.value, trackingPrefix)
      setActiveCategories(null)
    }

    const setTerms = (terms = []) => {
      state.terms = terms
      resetLastUpdated()
    }

    const addTerm = (term) => {
      if (state.terms.indexOf(term) !== -1) {
        return
      }

      state.terms.push(term)
      gtmHelper.addSearchTerm(state.terms, term, trackingPrefix)
      resetLastUpdated()
    }

    const removeTerm = (term) => {
      const index = state.terms.indexOf(term)
      if (index > -1) {
        const tempArray = state.terms
        tempArray.splice(index, 1)
        gtmHelper.removeSearchTerm(tempArray, term, trackingPrefix)
        setTerms(tempArray)
      }
    }

    const clearTermsAndCategories = () => {
      gtmHelper.removeTermsAndFilters(state.terms, activeCategoriesNames.value, trackingPrefix)
      setActiveCategories(null)
      setTerms()
    }

    const removeTermOrFilter = (term) => {
      if (term.type === 'filter') {
        const category = state.activeCategories.find(({ name }) => name === term.name)
        removeCategory(category)
      }

      if (term.type === 'term') {
        removeTerm(term.name)
      }
    }

    const incrementPage = () => {
      state.page += 1
    }

    return {
      searchQuery: state,
      activeCategories: computed(() => state.activeCategories),
      activeCategoriesNames,
      activeTerms: computed(() => state.terms),
      activeTermsAndFilters,
      trackingPrefix,

      addCategory,
      removeCategory,
      removeAllCategories,
      setTerms,
      addTerm,
      removeTerm,
      clearTermsAndCategories,
      removeTermOrFilter,
      setActiveCategories,
      setAllSearchTypes,
      setSearchType,
      setSorting,
      setView,
      incrementPage,
      setTrackingPrefix
    }
  }
}
