import { reactive } from 'vue'

const state = reactive({
  sitekey: '',
  recaptchaComponents: []
})

window.renderCaptcha = function () {
  if (window.grecaptcha) {
    state.recaptchaComponents.forEach((component) => {
      window.grecaptcha.render(component.recaptchaElementId, {
        sitekey: state.sitekey,
        callback: component.recaptchaSubmitCallback
      })
    })
  }
}

const verify = (fieldName) => {
  if (window.grecaptcha) {
    const componentIndex = state.recaptchaComponents
      .findIndex((x) => x.inputElementName === fieldName)
    const { recaptchaSubmitCallback } = state.recaptchaComponents[componentIndex]
    const response = window.grecaptcha.getResponse(componentIndex)

    if (response.length === 0) {
      window.grecaptcha.execute(componentIndex)
    } else {
      recaptchaSubmitCallback()
    }
  }
}

const initialize = () => {
  const forms = window.epi && window.epi.EPiServer && window.epi.EPiServer.Forms
  if (!forms) {
    return
  }

  const recaptchaScript = document.createElement('script')
  recaptchaScript.setAttribute('src', 'https://www.recaptcha.net/recaptcha/api.js?onload=renderCaptcha&render=explicit')
  document.head.appendChild(recaptchaScript)

  $.extend(true, forms, {
    Validators: {
      'Arla.CustomerWeb.Cms.Features.Blocks.Components.InvisibleRecaptchaValidatableElementBlock.InvisibleRecaptchaValidator': (fieldName, fieldValue) => {
        const isValid = fieldValue !== ''
        if (!isValid) {
          verify(fieldName)
        }
        return { isValid }
      }
    }
  })
}

const setSiteKey = (value) => {
  if (state.sitekey) {
    return
  }
  state.sitekey = value
}

initialize()

export default () => {
  const addComponent = ({
    recaptchaElementId,
    inputElementName,
    inputElementId,
    recaptchaSubmitCallback
  }) => {
    state.recaptchaComponents.push({
      recaptchaElementId,
      inputElementName,
      inputElementId,
      recaptchaSubmitCallback
    })
  }

  return {
    initialize,
    setSiteKey,
    addComponent
  }
}
