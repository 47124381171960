<template>
  <div class="block-slider-wrap">
    <div
      ref="slider"
      class="block-slider"
      :class="cssClass"
    >
      <div
        v-if="showBackground"
        :class="`u-bg-color--${backgroundColor}`"
        class="block-slider__background"
      />

      <h2
        v-if="heading"
        class="block-slider__title"
        v-text="heading"
      />

      <block-slider-item
        v-if="isSingle"
        :fallback-image="fallbackImage"
        :model="items[0]"
        :heading-tag="slidesHeadingTag"
      />

      <div
        v-else
        class="block-slider__carousel-container"
      >
        <div
          ref="swiperContainer"
          class="swiper block-slider__carousel"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="swiper-slide card-slider__item"
            >
              <block-slider-item
                :fallback-image="fallbackImage"
                :model="item"
                :heading-tag="slidesHeadingTag"
              />
            </div>
          </div>

          <div
            v-show="isSlider"
            class="swiper-control-panel"
          >
            <div class="swiper-controls">
              <div class="swiper-pagination slider__carousel__pagination" />

              <div
                class="swiper-navigation"
              >
                <!--  TODO: consider adding translations  -->
                <button class="swiper-button-prev">
                  Prev
                </button>
                <button class="swiper-button-next">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, onMounted, computed
} from 'vue'
import Swiper from 'swiper'
import 'swiper/swiper.css'
import BlockSliderItem from '@/components/block-slider-item.vue'
import GtmHelper from '@/helpers/GtmHelper'
import useSlider from '@/compositions/useSlider'

export default {
  components: {
    BlockSliderItem
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    fallbackImage: {
      type: Object,
      default() {
        return {
          fallbackMobileImageUrl: null,
          fallbackLargeImageUrl: null
        }
      }
    },
    showBackground: {
      type: Boolean,
      required: true
    },
    slidesHeadingTag: {
      type: String,
      default: 'h2'
    },
    backgroundColor: {
      type: String,
      default: 'gray'
    },
    imageLeftAligned: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    let swiper = null
    const swiperContainer = ref(null)
    const currentSlide = ref(0)
    const slideLength = props.items.length
    const { imagePlaceholderText } = window.arla.cms.resources.recipes
    const { setOptions } = useSlider()

    const swiperOptions = setOptions({
      slidesPerView: 1.1,
      spaceBetween: 30,
      breakpoints: {
        1200: {
          slidesPerView: 1,
          spaceBetween: 60
        }
      }
    })

    const isSingle = computed(() => slideLength === 1)
    const isSlider = computed(() => slideLength > 1)
    const cssClass = computed(() => ([
      { 'block-slider--single': isSingle.value },
      { 'block-slider--reversed': props.imageLeftAligned }
    ]))

    const onPageChange = ({ activeIndex }) => {
      const direction = currentSlide.value < activeIndex ? 'forward' : 'backward'
      GtmHelper.changeSlide(direction, props.cardType, props.listName)
      currentSlide.value = activeIndex
    }

    onMounted(() => {
      // eslint-disable-next-line no-unused-vars
      swiper = new Swiper(swiperContainer.value, swiperOptions)
      swiper.on('slideChange', onPageChange)
    })

    return {
      swiperContainer,
      isSingle,
      isSlider,
      cssClass,
      imagePlaceholderText,
      currentSlide
    }
  }
}
</script>
