<template>
  <UserCockpit :model="model" />
</template>

<script>
export default {
  components: {
    UserCockpit: () => import('@/components/userCockpit/user-cockpit.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
