<template>
  <modal
    :adaptive="true"
    :name="modalName"
    :max-width="730"
    height="auto"
    width="100%"
    reset
    class="v--modal--slideup-on-mobile"
    @opened="onOpen"
    @before-open="beforeOpenModal(modalName)"
    @before-close="beforeCloseModal(modalName)"
  >
    <div class="c-sustainable-modal popup__inner">
      <div class="c-sustainable-modal__sidebar">
        <div class="c-sustainable-list">
          <span
            v-for="(thumbnail, index) in items"
            :key="`thumbnail-${index}`"
            role="button"
            class="c-sustainable-list__item"
            :class="{ active: currentSlide === index }"
            @click="onThumbsClick(index)"
          >
            <span class="icon">
              <img
                :src="thumbnail.primaryIcon"
                :alt="thumbnail.title"
                width="20"
                height="20"
              >
              <img
                :src="thumbnail.sectionIcon"
                :alt="thumbnail.title"
                width="20"
                height="20"
              >
            </span>
            {{ thumbnail.title }}
          </span>
        </div>
      </div>
      <div class="c-sustainable-modal__content">
        <div
          ref="swiperRef"
          class="swiper"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in items"
              :key="`slide-${index}`"
              class="swiper-slide"
            >
              <div class="swiper-slide-content">
                <img
                  v-if="item.image"
                  :src="item.image"
                  :alt="item.title"
                  class="icon"
                  width="136"
                  height="122"
                >
                <strong
                  v-else
                  class="h3"
                  v-text="item.title"
                />
                <div
                  v-html="item.description"
                />
              </div>
            </div>
          </div>
          <div class="swiper-control-panel">
            <div class="swiper-controls">
              <div class="swiper-pagination slider__carousel__pagination" />

              <div class="swiper-navigation">
                <!--  TODO: consider adding translations  -->
                <button
                  class="swiper-button-prev"
                  @click="onNavigationClick"
                >
                  Prev
                </button>
                <button
                  class="swiper-button-next"
                  @click="onNavigationClick"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        role="button"
        :aria-label="closeLabel"
        class="popup-content__button--close"
        @click="hidePopup(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import {
  getCurrentInstance, onBeforeUnmount, ref
} from 'vue'
import Swiper from 'swiper'
import 'swiper/swiper.css'
import 'swiper/modules/effect-fade.css'
import { Navigation, Pagination, EffectFade } from 'swiper/modules'
import useModal from '@/compositions/useModal'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  props: {
    modalName: {
      type: String,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    recipeName: {
      type: String,
      required: true
    }
  },
  setup({ model, recipeName }) {
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    } = useModal($modal)
    const items = [
      model.generalItem,
      ...model.items
    ]

    const leadingZeroFilter = (value) => ((value && value.toString().length === 1) ? `0${value}` : value)
    const swiperPagination = (swiper, current, total) => `
        <div class="current">${leadingZeroFilter(current)}</div>
        <div class="last">${leadingZeroFilter(total)}</div>
      `

    const { closeLabel } = window.arla.cms.resources.general
    const swiperOptions = {
      effect: 'fade',
      modules: [Navigation, Pagination, EffectFade],
      autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'custom',
        renderCustom: swiperPagination
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }

    let swiper = null
    const swiperRef = ref(null)
    const currentSlide = ref(0)

    const onPageChange = ({ activeIndex }) => {
      const direction = currentSlide.value < activeIndex ? 'forward' : 'backward'
      GtmHelper.changeSlide(direction)
      currentSlide.value = activeIndex
    }

    const onThumbsClick = (index) => {
      swiper.slideTo(index)
    }

    const onOpen = () => {
      swiper = new Swiper(swiperRef.value, swiperOptions)
      swiper.on('slideChange', onPageChange)
    }

    const onNavigationClick = () => {
      GtmHelper.sustainableModalArrowClick(recipeName)
    }

    onBeforeUnmount(() => {
      swiper.destroy()
    })

    return {
      items,
      swiperRef,
      closeLabel,
      currentSlide,

      onThumbsClick,
      onNavigationClick,
      hidePopup,
      onOpen,
      beforeOpenModal,
      beforeCloseModal
    }
  }
}
</script>
