<template>
  <modal
    :name="modalName"
    :adaptive="true"
    :max-width="600"
    height="auto"
    width="90%"
    scrollable
    reset
    class="v--modal--no-padding"
    @before-open="beforeOpenModal(null, ['show-v-modal', 'show-v-modal--in-header'])"
    @before-close="beforeCloseModal(null, ['show-v-modal', 'show-v-modal--in-header'])"
    @closed="hideMiniCart"
  >
    <mini-cart />
    <span
      role="button"
      :aria-label="closeLabel"
      class="popup-content__button--close"
      @click="hideMiniCart"
    />
  </modal>
</template>

<script>
import { getCurrentInstance, watch } from 'vue'
import useModal from '@/compositions/useModal'
import useMiniCart from '@/compositions/useMiniCart'
import MiniCart from '@/components/header/mini-cart.vue'

// TODO: add proper translations and prices
export default {
  components: {
    MiniCart
  },
  props: {
    modalName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      showPopup,
      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    } = useModal($modal)
    const {
      hideMiniCart,
      removeHideTimeout,
      state: miniCart
    } = useMiniCart()
    const { closeLabel } = window.arla.cms.resources.general

    watch(
      () => miniCart.isVisible,
      (newVal) => {
        if (newVal) {
          showPopup(props.modalName)
          removeHideTimeout()
        } else {
          hidePopup(props.modalName)
        }
      }
    )

    return {
      closeLabel,

      hideMiniCart,
      beforeOpenModal,
      beforeCloseModal
    }
  }
}
</script>
