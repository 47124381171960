var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.state.name)?_c('tr',{staticClass:"order-material-table-item product-table-item"},[(!_vm.isMobile)?_c('td',[_c('div',{staticClass:"item-card__controls"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.itemAmount),expression:"itemAmount",modifiers:{"number":true}},{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.tooltipModel.content,
          show: _vm.notEnoughInStock,
          autoHide: false,
          placement: 'top-start',
          trigger: 'manual',
          classes : 'error'
        }),expression:"{\n          content: tooltipModel.content,\n          show: notEnoughInStock,\n          autoHide: false,\n          placement: 'top-start',\n          trigger: 'manual',\n          classes : 'error'\n        }"}],staticClass:"item-card__amount-field",class:{ invalid: _vm.hasError },attrs:{"min":"1","type":"number","aria-label":"Amount of product"},domProps:{"value":(_vm.itemAmount)},on:{"change":_vm.updateCart,"input":function($event){if($event.target.composing)return;_vm.itemAmount=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})])]):_vm._e(),_c('td',{staticClass:"cell--name"},[_c('div',{staticClass:"product-table-item__info"},[_c('span',_vm._g({staticClass:"product-image",class:{ 'img-placeholder': !_vm.state.image}},_vm.state.image ? { click: _vm.orderMaterialClick } : {}),[_c('img',{staticClass:"product-image__thumbnail",attrs:{"src":_vm.smallImageUrl,"alt":_vm.state.name}})]),_c('span',{staticClass:"product-table-item__info__text"},[_c('span',{staticClass:"product-table-item__title"},[_vm._v(_vm._s(_vm.state.name))]),(_vm.state.description)?_c('span',{staticClass:"product-table-item__subtitle"},[_vm._v(" "+_vm._s(_vm.state.description)+" ")]):_vm._e()])]),(_vm.state.image)?_c('image-modal',{attrs:{"name":_vm.state.name,"image":_vm.largeImageUrl,"modal-name":_vm.model.id}}):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"item-card__controls item-card__controls--mobile"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.state.amount),expression:"state.amount",modifiers:{"number":true}},{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.tooltipModel.content,
          autoHide: false,
          show: _vm.notEnoughInStock,
          placement: 'top-start',
          trigger: 'manual',
          classes : 'error'
        }),expression:"{\n          content: tooltipModel.content,\n          autoHide: false,\n          show: notEnoughInStock,\n          placement: 'top-start',\n          trigger: 'manual',\n          classes : 'error'\n        }"}],staticClass:"item-card__amount-field",class:{ invalid: _vm.hasError },attrs:{"min":"1","type":"number","aria-label":"Amount of product"},domProps:{"value":(_vm.state.amount)},on:{"change":_vm.updateCart,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.state, "amount", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"item-card__units"},[_vm._v(_vm._s('SP'))]),_c('span',[_vm._v(_vm._s(_vm.price))]),_c('button',{staticClass:"cta--remove",on:{"click":function($event){$event.preventDefault();return _vm.onRemoveFromCart.apply(null, arguments)}}},[_c('BinIcon',{staticClass:"icon"}),_c('span',{staticClass:"u-hide-on-screenonly"},[_vm._v("Ta bort sak")])],1)]):_vm._e()],1),(!_vm.isMobile)?_c('td',[_c('span',[_vm._v(_vm._s(_vm.price))])]):_vm._e(),(!_vm.isMobile)?_c('td',[_c('button',{staticClass:"cta--remove",on:{"click":function($event){$event.preventDefault();return _vm.onRemoveFromCart.apply(null, arguments)}}},[_c('BinIcon',{staticClass:"icon"}),_c('span',{staticClass:"u-hide-on-screenonly"},[_vm._v("Ta bort sak")])],1)]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }