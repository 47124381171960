<template>
  <div
    v-if="showBottomBanner"
    class="bottom-banner catalogue-summary"
  >
    <div class="bottom-banner__inner">
      <div class="bottom-banner__inner-container">
        <div class="u-flex">
          <div class="controls">
            <span
              role="button"
              class="u-link--underline"
              @click="onAddAll"
            >
              {{ catalogueTranslations.addAllFromListLabel }}
            </span>
            <span
              role="button"
              class="u-link--underline"
              @click="clearCatalogue"
            >
              {{ catalogueTranslations.clearCatalogLabel }}
            </span>
          </div>
          <strong>
            <span class="u-visible--tablet-desktop">
              {{ catalogueTranslations.addedToCatalogueDesktopText }}
            </span>
            <span class="u-hidden--tablet-desktop">
              {{ catalogueTranslations.addedToCatalogueMobileText }}
            </span>
            {{ cartSum }}
          </strong>
        </div>
        <button
          :class="{ 'disabled': cartSum === 0 }"
          class="cta"
          tabindex="0"
          @click="onContinueClick"
        >
          <span>{{ catalogueTranslations.continueButtonText }}</span>
          <IconArrow class="icon" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'
import IconArrow from '@/assets/icons/arrow-right.svg'
import useCatalogue from '@/compositions/useCatalogue'
import useModal from '@/compositions/useModal'
import GtmHelper from '@/helpers/GtmHelper'
import searchRepository from '@/compositions/search/search-repository'
import searchQueryState from '@/compositions/search/search-query'

export default {
  components: {
    IconArrow
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const { items } = searchRepository.useSearchResult()
    const { searchQuery } = searchQueryState.searchQueryUse()

    const {
      cartSum,
      modalName,

      addAllToCatalogue,
      clearCatalogue,
      catalogueTranslations
    } = useCatalogue()

    const { $modal } = proxy
    const { showPopup } = useModal($modal)

    const onContinueClick = () => {
      showPopup(modalName)
      GtmHelper.catalogueContinueClick()
    }

    const onAddAll = () => {
      addAllToCatalogue({
        items: items.value,
        type: searchQuery.searchType
      })
    }

    return {
      cartSum,
      showBottomBanner: true,
      catalogueTranslations,

      onContinueClick,
      onAddAll,
      clearCatalogue
    }
  }
}
</script>
