<template>
  <div class="catalogue-overview-controls u-hidden--desktop">
    <button
      class="cta cta--dark"
      @click="openFilterMenu"
      v-text="filter"
    />
    <a
      v-if="filtersExist"
      class="active-filters__remove-link"
      role="button"
      tabindex="0"
      @click="removeAllCategories"
      @keyup.enter="removeAllCategories"
    >
      {{ clearFilters }}
    </a>
  </div>
</template>
<script>
import { computed } from 'vue'

import searchQueryState from '@/compositions/search/search-query'
import useFilters from '@/compositions/useFilters'

export default {
  setup() {
    const { clearFilters, filter } = window.arla.cms.resources.search

    const {
      removeAllCategories,
      activeCategories
    } = searchQueryState.searchQueryUse()
    const { openFilterMenu } = useFilters()

    return {
      filtersExist: computed(() => activeCategories.value.length > 0),
      clearFilters,
      filter,

      openFilterMenu,
      removeAllCategories
    }
  }
}
</script>
