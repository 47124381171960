<template>
  <modal
    :adaptive="true"
    :name="modalName"
    :max-width="600"
    height="auto"
    width="100%"
    scrollable
    reset
    class="image-popup u-text-align--center"
    @before-open="beforeOpenModal(name)"
    @before-close="beforeCloseModal(name)"
  >
    <div class="popup__inner">
      <generic-image
        :large-image-url="image"
        :alt-text="name"
      />

      <span
        role="button"
        :aria-label="closeLabel"
        class="popup-content__button--close"
        @click="hidePopup(modalName)"
      />

      <div class="u-text-align--center">
        <span
          role="button"
          class="popup-content__cta--close"
          @click="hidePopup(modalName)"
        >
          {{ closeLabel }}
        </span>
      </div>
    </div>
  </modal>
</template>

<script>
import useModal from '@/compositions/useModal'
import { getCurrentInstance } from 'vue'

// TODO: add proper translations and prices
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    modalName: {
      type: String,
      required: true
    }
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    } = useModal($modal)
    const { closeLabel } = window.arla.cms.resources.general

    return {
      closeLabel,

      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    }
  }
}
</script>
