<template>
  <div>
    <div class="form-row">
      <h4>
        <em>{{ currentStepNumber }}/{{ totalSteps }}</em>
        <br>
        {{ catalogueTranslations.modalCatalogueAdditionalInformationHeading }}
      </h4>
      <p>
        {{ catalogueTranslations.modalCatalogueAdditionalInformationText }}
      </p>
    </div>
    <div class="form-row form-row--choice form-row--checkbox">
      <label class="form-row--choice__item">
        <input
          v-model="value.additionalInfoValue"
          type="checkbox"
          name="additionalInformation"
          class="form-checkbox"
        >
        <strong
          class="form-choice__label"
          v-text="catalogueTranslations.modalCatalogueAdditionalInformationLabel"
        />
        <span class="form-choice__indicator--arrow" />
      </label>
    </div>
    <template v-if="value.additionalInfoValue">
      <div class="form-row">
        <label
          for="catalogue-contact-name"
          class="form-label"
        >
          {{ catalogueTranslations.modalCatalogueContactNameHeading }}
        </label>
        <input
          id="catalogue-contact-name"
          v-model="value.contactInfoName"
          :placeholder="catalogueTranslations.modalCatalogueContactNamePlaceholder"
          type="text"
        >
      </div>
      <div class="form-row">
        <label
          for="catalogue-contact-email"
          class="form-label"
        >
          {{ catalogueTranslations.modalCatalogueContactEmailHeading }}
        </label>
        <input
          id="catalogue-contact-email"
          v-model="value.contactInfoEmail"
          :placeholder="catalogueTranslations.modalCatalogueContactEmailPlaceholder"
          type="email"
        >
      </div>
      <div class="form-row">
        <label
          for="catalogue-contact-phone"
          class="form-label"
        >
          {{ catalogueTranslations.modalCatalogueContactPhoneHeading }}
        </label>
        <input
          id="catalogue-contact-phone"
          v-model="value.contactInfoPhone"
          :placeholder="catalogueTranslations.modalCatalogueContactPhonePlaceholder"
          pattern="^[0-9]{9,12}$"
          type="tel"
        >
      </div>
    </template>
    <hr class="mt-xs mb-xs">
    <div class="form-row form-row--choice form-row--checkbox">
      <label class="form-row--choice__item">
        <input
          v-model="value.relatedContentValue"
          type="checkbox"
          name="relatedContent"
          class="form-checkbox"
        >
        <strong
          class="form-choice__label"
          v-text="catalogueTranslations.modalCatalogueRelatedContentLabel"
        />
        <span class="form-choice__indicator--arrow" />
      </label>
    </div>
    <template v-if="value.relatedContentValue">
      <div class="form-row">
        <label
          for="catalogue-related-title"
          class="form-label"
        >
          {{ catalogueTranslations.modalCatalogueRelatedContentTitleHeading }}
        </label>
        <input
          id="catalogue-related-title"
          v-model="value.relatedContentTitle"
          :placeholder="catalogueTranslations.modalCatalogueRelatedContentTitlePlaceholder"
          type="text"
        >
      </div>
      <div class="form-row">
        <div class="tooltip-holder">
          <label
            for="catalogue-content-link"
            class="form-label"
          >
            {{ catalogueTranslations.modalCatalogueRelatedContentLinkHeading }}
          </label>
          <Tooltip
            v-if="tooltipModel.title || tooltipModel.content"
            :model="tooltipModel"
            placement="top"
          />
        </div>
        <input
          id="catalogue-content-link"
          v-model="value.relatedContentLink"
          type="url"
          :placeholder="catalogueTranslations.modalCatalogueRelatedContentLinkPlaceholder"
          :pattern="urlPattern"
          @input.once="onStartTyping('link')"
        >
      </div>
      <div class="form-row">
        <label
          for="catalogue-related-description"
          class="form-label"
        >
          {{ catalogueTranslations.modalCatalogueRelatedContentDescriptionHeading }}
        </label>
        <textarea
          id="catalogue-related-description"
          v-model="value.relatedContentDescription"
          cols="5"
          rows="5"
          :placeholder="catalogueTranslations.modalCatalogueRelatedContentDescriptionPlaceholder"
          @input.once="onStartTyping('relatedContentDescription')"
        />
      </div>
    </template>
    <div class="mt-lg form-row form-row--choice form-row--checkbox">
      <label class="form-row--choice__item">
        <input
          v-model="value.termsAccepted"
          type="checkbox"
          name="termsAccepted"
          class="form-checkbox"
          required
          @change="onFieldChange($event.target.name, $event.target.value)"
        >
        <span
          class="form-choice__label"
          v-html="catalogueTranslations.modalCatalogueVisualDetailsTermsAndConditionsText"
        />
        <span class="form-choice__indicator" />
      </label>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import gtmHelper from '@/helpers/GtmHelper'
import Tooltip from '@/components/tooltip.vue'
import useCatalogue from '@/compositions/useCatalogue'
import useWizardFlow from '@/compositions/catalogue/useWizardFlow'

export default {
  components: {
    Tooltip
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  setup() {
    const {
      state: {
        totalSteps
      },
      currentStepNumber,
      currentStep
    } = useWizardFlow()
    const { catalogueTranslations } = useCatalogue()

    const {
      modalCatalogueRelatedContentLinkAvailableDomain,
      modalCatalogueRelatedContentLinkTooltipTitle,
      modalCatalogueRelatedContentLinkTooltipContent
    } = catalogueTranslations.value
    const availableDomains = modalCatalogueRelatedContentLinkAvailableDomain
      ? modalCatalogueRelatedContentLinkAvailableDomain.split(',')
        .map((domain) => `${domain.replace(/\\./g, '\\.')}`)
        .join('|')
      : '(?:\\w+\\.\\w{2,3})'
    const urlPattern = `^https?:\\/\\/(?:www\\.)?(?:\\w+\\.)?(?:${availableDomains})(?:\\/.*)?$`
    const tooltipModel = computed(() => ({
      title: modalCatalogueRelatedContentLinkTooltipTitle,
      content: modalCatalogueRelatedContentLinkTooltipContent
    }))

    const onStartTyping = (fieldName) => {
      gtmHelper.catalogueFieldInput(fieldName)
    }

    const onFieldChange = (fieldName, value) => {
      gtmHelper.catalogueFieldInput(fieldName, value)
    }

    return {
      catalogueTranslations,
      currentStepNumber,
      currentStep,
      totalSteps,
      urlPattern,
      tooltipModel,

      onStartTyping,
      onFieldChange
    }
  }
}
</script>
