<template>
  <nav
    ref="nav"
    class="tab-links"
  >
    <a
      v-for="(config, index) in tabsConfiguration"
      :key="config.type"
      :class="getTabClass(config.type)"
      :tabindex="index"
      class="tab-link"
      @click.prevent="onTabClick(config.type)"
      @keyup.enter="onTabClick(config.type)"
    >
      {{ getTabName(config) }}
    </a>
  </nav>
</template>

<script>
import { ref, onMounted } from 'vue'
import useFavorites from '@/compositions/favorites/favorites-id-repository'
import favoritesQueryState from '@/compositions/favorites/favorites-query'
import favoritesTabsConfig from '@/compositions/favorites/favorites-tabs-configuration'
import { handleNavHorizontalScroll } from '@/helpers/CheckPositionInViewport'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  setup() {
    const { favoritesQuery, setFavoritesType } = favoritesQueryState.favoritesQueryUse()
    const { tabsConfiguration } = favoritesTabsConfig.favoritesTabsConfigurationUse()
    const { items, totalCount } = useFavorites()
    const nav = ref(null)

    const getTabClass = (tabType) => {
      let cssCombinedClass = tabType
      if (favoritesQuery.favoritesType === tabType) {
        cssCombinedClass += ' active'
      }
      return cssCombinedClass
    }

    const getTabName = (tabConfig) => {
      if (totalCount) {
        const itemsCount = items.value[tabConfig.type].length
        return tabConfig.translations.tabName.concat(' (', itemsCount, ')')
      }
      return tabConfig.translations.tabName
    }

    const onTabClick = (type) => {
      setFavoritesType(type)
      GtmHelper.overviewContentTabClick(type, 'favorites')
    }

    onMounted(() => {
      handleNavHorizontalScroll(nav.value)
    })

    return {
      nav,
      tabsConfiguration,

      getTabClass,
      getTabName,
      onTabClick
    }
  }
}
</script>
