<template>
  <active-filters
    v-if="activeTermsAndFilters.length"
    :filters="activeTermsAndFilters"
    :clear-filters-text="clearFilters"
    @removeFilter="removeTermOrFilter"
    @clearFilters="clearTermsAndCategories"
  />
</template>

<script>
import ActiveFilters from '@/components/active-filters.vue'
import searchQueryState from '@/compositions/search/search-query'

export default {
  components: {
    ActiveFilters
  },
  setup() {
    const { clearFilters = '' } = window.arla.cms.resources.search
    const {
      activeTermsAndFilters,

      removeTermOrFilter,
      clearTermsAndCategories
    } = searchQueryState.searchQueryUse()

    return {
      clearFilters,
      activeTermsAndFilters,

      removeTermOrFilter,
      clearTermsAndCategories
    }
  }
}
</script>
