<template>
  <div
    class="tooltip-hint"
  >
    <strong
      v-if="model.title"
      :class="titleColorCssClass"
      class="tooltip-opener-title tag-font"
    >
      {{ model.title }}
    </strong>

    <button
      v-tooltip="{
        content: content,
        placement: placement,
        autoHide: false,
        trigger: 'hover focus click'
      }"
      :class="titleColorCssClass"
      class="tooltip-opener has-icon"
      aria-label="Open tooltip"
    >
      <IconInfo class="icon" />
    </button>
  </div>
</template>

<script>
import IconInfo from '@/assets/icons/information-circle--transparent.svg'

export default {
  components: {
    IconInfo
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    direction: {
      type: String,
      default: 'top'
    },
    titleColorCssClass: {
      type: String,
      default: 'error'
    },
    placement: {
      type: String,
      default: 'top-start'
    }
  },
  computed: {
    content() {
      return this.model.content.replace(/{quotes}/g, '"')
    }
  }
}
</script>
