let userScreen
const isScreenLockSupported = 'wakeLock' in window.navigator
const lockScreen = async (callbackFunc) => {
  try {
    userScreen = await navigator.wakeLock.request('screen')
    userScreen.addEventListener('release', callbackFunc)
  } catch (err) {
    console.log(err.name, err.message)
  }
}

const unlockScreen = async () => {
  await userScreen.release()
}

export {
  isScreenLockSupported,

  lockScreen,
  unlockScreen
}
