const checkIfElementOutOfViewport = (el) => {
  const bounding = el.getBoundingClientRect()

  const out = {}
  out.top = bounding.top < 0
  out.left = bounding.left < 0
  out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)
  out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth)
  out.any = out.top || out.left || out.bottom || out.right
  out.all = out.top && out.left && out.bottom && out.right

  return out
}

const handleNavHorizontalScroll = (container = document) => {
  const activeLink = container.querySelector('.active')
  if (checkIfElementOutOfViewport(activeLink).right) {
    container.scrollLeft = activeLink.offsetLeft + activeLink.offsetWidth
  }
}

// eslint-disable-next-line import/prefer-default-export
export { checkIfElementOutOfViewport, handleNavHorizontalScroll }
