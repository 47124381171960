const scrollToAnchor = (clickedEl, additionalOffset = 0) => {
  const navHeight = 60
  const section = document.getElementById(clickedEl.attributes.href.value.substring(1))
  const top = window.scrollY + section.getBoundingClientRect().top - (additionalOffset + navHeight)

  window.scrollTo({
    left: 0,
    top,
    behavior: 'smooth'
  })
}

export default scrollToAnchor
