<template>
  <div
    class="new-cart"
  >
    <h4 class="new-cart__title">
      {{ cartTitle }}
    </h4>
    <table class="product-table table">
      <thead>
        <tr>
          <th v-text="translations.quantityHeader" />
          <th v-text="translations.materialHeader" />
          <th v-text="translations.priceHeader" />
          <th
            class="u-text-nowrap"
            v-text="translations.removeHeader"
          />
        </tr>
      </thead>
      <tbody v-if="!state.loading">
        <order-material-cart-item
          v-for="(item, index) in state.items"
          :key="item.id"
          :model="item"
          :position="index + 1"
        />
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="4">
            <loader :loading="state.loading" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { reactive } from 'vue'

import Loader from '@/components/loader.vue'
import OrderMaterialCartItem from '@/components/orderMaterials/order-material-cart-item.vue'
import useOrderMaterials from '@/compositions/orderMaterials/useOrderMaterial'
import api from '@/lib/api'

export default {
  components: {
    Loader,
    OrderMaterialCartItem
  },
  props: {
    cartTitle: {
      type: String,
      default: ''
    }
  },
  setup() {
    const translations = window.arla.cms.resources.orderMaterials.cart
    const { items, getItemIds, updateItemsData } = useOrderMaterials()

    const state = reactive({
      items,
      loading: true
    })

    const fetchData = async () => {
      const ids = getItemIds()

      if (!ids.length) {
        return
      }

      const response = await api.getOrderMaterialsByIds({ ids, skip: 0, take: 50 })
      updateItemsData(response.data.items)
    }

    fetchData().then(() => {
      state.loading = false
    })

    return {
      state,
      translations
    }
  }
}
</script>
