import { reactive } from 'vue'

const TYPES = {
  intro: 'intro',
  private: 'private',
  business: 'business'
}

let loginState = localStorage.getItem('loginState') || TYPES.intro
if (loginState === TYPES.business) {
  loginState = TYPES.intro
  localStorage.setItem('loginState', TYPES.intro)
}
const state = reactive({
  steps: {
    intro: 'LoginIntro',
    private: 'LoginPrivate'
  },
  currentStep: loginState,
  isDirectLoginType: loginState !== TYPES.intro,
  oppositeLoginState: loginState === TYPES.private ? TYPES.intro : TYPES.private
})

export default () => {
  const setCurrentStep = (step) => {
    state.currentStep = step
    state.isDirectLoginType = false
    localStorage.setItem('loginState', step)
  }

  return {
    state,

    setCurrentStep
  }
}

export { TYPES }
